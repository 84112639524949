import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useQuestionnaires = () => {
	return useQuery(['questionnaires'],
		async () => {
			const query = new Parse.Query("Questionnaires");
			let data = await query.find();

			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useCreateQuestionnaire = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let result = await Parse.Cloud.run('create-questionnaire', {
			data
		});
		queryClient.refetchQueries(['questionnaires']);

		return result;	
	}, {
		...options,
	})
}

export const useQuestionnaireByID = (id) => {
	return useQuery(['questionnaires', id],
		async () => {
			const query = new Parse.Query("Questionnaires");
			return await query.get(id);
		},
		{}
	)
}

export const useEditQuestionnaire = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let result = await Parse.Cloud.run('edit-questionnaire', {
			data
		});
		queryClient.refetchQueries(['questionnaires', data.id]);

		return result;	
	}, {
		...options,
	})
}

export const useEditQuestionnaireOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		queryClient.setQueryData(['questionnaires'], data);

		await Promise.all(data.map(async (ques, index) => {
			ques.set('order', index);
			await ques.save();
		}));

	}, {
		...options,
	})
}

export const useDeleteQuestionnaire = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("Questionnaires");
		let old_quest  = await query.get(data.id)

		if (old_quest) {
			await old_quest.destroy();
			queryClient.refetchQueries(['questionnaires']);

			const query_units = new Parse.Query("QuestionnaireUnits");
			query_units.equalTo('questionnaire', old_quest);
			let unit_data = await query_units.find();
			await Promise.all(unit_data.map(async unit => {
				await unit.destroy();
			}));

			const query_sections = new Parse.Query("QuestionnaireSections");
			query_sections.equalTo('questionnaire', old_quest);
			let section_data = await query_sections.find();
			await Promise.all(section_data.map(async section => {
				await section.destroy();
			}));

			const query_questions = new Parse.Query("QuestionnaireQuestions");
			query_questions.equalTo('questionnaire', old_quest);
			let questions_data = await query_questions.find();
			await Promise.all(questions_data.map(async data => {
				await data.destroy();
			}));

			const query_lessons = new Parse.Query("QuestionnaireLessons");
			query_lessons.equalTo('questionnaire', old_quest);
			let lessons_data = await query_lessons.find();
			await Promise.all(lessons_data.map(async data => {
				await data.destroy();
			}));
		}
	}, {
		...options,
	})
}






