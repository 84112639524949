import { useState } from "react";
import { Flex, Text, SimpleGrid, Skeleton, Button, Box, Table, Thead, Tbody, Tr, Th, Td, Image } from '@chakra-ui/react';
import { Header, SectionBox, CreateQuestionnaireModal } from "../includes";
import { useQuestionnaires, useEditQuestionnaireOrder } from "../hooks";
import { DragDropContext, Droppable , Draggable} from 'react-beautiful-dnd'
import { Link } from "react-router-dom";

export default function Questionnaires() {
	const { data, isLoading } = useQuestionnaires();
	const [modal, setModal] = useState(false);
	const { mutateAsync: updateOrder } = useEditQuestionnaireOrder();

	async function onDragEnd({ source, destination, type }){
	    let _data = [...data];
	    if (source && destination) {
	        const [removed] = _data.splice(source.index, 1)
	        _data.splice(destination.index, 0, removed)
	        updateOrder(_data);
	    }
	}

	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px" w="100%" alignSelf="center">
				<Flex align="center" justify="center" mb="40px">
					<Flex flex="1" direction="column">
						<Text variant="title">Questionnaires</Text>
						<Text variant="description">Each questionnaire has it's own sections, units, lessons and questions.</Text>
					</Flex>
					<Button onClick={() => setModal(true)} variant="primary" fontSize="14px" p="12px 15px">Create new</Button>
				</Flex>
				{
					isLoading ?
					<SimpleGrid columns={4} spacing={7}>
						<Skeleton height="270px" w="100%" opacity="0.5" borderRadius="10px" />
						<Skeleton height="270px" w="100%" opacity="0.5" borderRadius="10px" />
						<Skeleton height="270px" w="100%" opacity="0.5" borderRadius="10px" />
						<Skeleton height="270px" w="100%" opacity="0.5" borderRadius="10px" />
					</SimpleGrid>
					: data ?

					<Flex w="100%" border="1px solid" borderColor="light" borderRadius="10px" overflow="hidden">
						<Table variant='simple'>
							<Thead>
								<Tr>
									<Th w="40px" pl="0" pr="0"></Th>
									<Th w="100px"><Text variant="subtitle" textTransform="capitalize"></Text></Th>
									<Th w="350px"><Text variant="subtitle" textTransform="capitalize">Title</Text></Th>
									<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Type</Text></Th>
									<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Status</Text></Th>
									<Th isNumeric></Th>
								</Tr>
							</Thead>

							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="questionnaire-wrapper">
									{(provided) => (
										<Tbody flexGrow="1" ref={provided.innerRef} {...provided.droppableProps} w="100%">
											{
												data.map((quest, index) => (
													<TableRow key={quest.id} data={quest} index={index} />
												))
											}
										</Tbody>
									)}
								</Droppable>
							</DragDropContext>
						</Table>
					</Flex>
					:
					null
				}
			</Flex>
			{
				modal && <CreateQuestionnaireModal onClose={() => setModal(false)} />
			}
		</Flex>
	)
}

	
function TableRow({ data, index }){
	let title = data.get('fullTitle') ? data.get('fullTitle')['english'] : data.get('title')
	let description = data.get('fullDescription') ? data.get('fullDescription')['english'] : data.get('description')
	
	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Tr {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} w="100%" bg="white" transition="0.3s all">
					<Td pt="10px" pb="10px" pl="0" pr="0" fontSize="13px" w="40px" color="lightText" fontWeight="500">
						<Flex cursor="drag" w="40px" h="30px" align="center" justify="center" borderRadius="30px">
							<div><i className="fa-solid fa-grip-vertical"></i></div>
						</Flex>
					</Td>
					<Td pt="10px" pb="10px" pr="0" pl="0" fontSize="15px" fontWeight="500">
						<Flex align="center" h="100%">
							{
								data.get('image') ?
								<Image 
									w="100%"
									h="50px"
									borderRadius="5px"
									objectFit='cover'
									objectPosition="center"
									alt="Section Box"
									src={data.get('image')} />
								:
								<Flex flex='1' bg="light" color="lightText" align="center" justify="center" fontSize="15px" h="35px" borderRadius="5px">
									<div><i className="fa-solid fa-images"></i></div>
								</Flex>
							}
						</Flex>
					</Td>
					<Td pt="10px" pb="10px">
						<Text fontWeight="600" mb="2px" fontSize="15px">{title}</Text>
						<Text fontSize="13px" maxW="350px" fontWeight="500">{description}</Text>
					</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="600" textTransform="capitalize" isNumeric>{data.get('live') == true ? "True" : "False"}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="600" textTransform="capitalize" isNumeric>{data.get('live') == true ? "True" : "False"}</Td>
					<Td pt="10px" pb="10px" isNumeric pr="10px">
						<Flex direction="row" align="center" justify="flex-end">
							<Button as={Link} to={`/questionnaires/${data.id}`} variant="white">Edit</Button>
						</Flex>
					</Td>
				</Tr>
			)}
		</Draggable>
	)
}







