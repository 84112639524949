import { useRef } from "react";
import { Flex, Input, Text, Switch, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, useDisclosure } from "@chakra-ui/react";

export function RowMorePopover({ question, row, language, updateOption }) {
	const { isOpen, onToggle, onClose } = useDisclosure()
	const initRef = useRef()

	let endText = row['endingAudioText'] ? row['endingAudioText'][language] : ""
	let canTranslate = row['canTranslate'] ? row['canTranslate'][language] : true;

	function updateEndText(value){
		let endingAudioText = row['endingAudioText'];
		if (!endingAudioText) {
			endingAudioText = {
				"english":"",
				"arabic":"",
				"persian":""
			}
		}
		endingAudioText[language] = value;
		updateOption(question.id, row.id, { endingAudioText });
	}

	function updateCanTranslate(value){
		let canTranslate = row['canTranslate'];
		if (!canTranslate) {
			canTranslate = {
				"english":true,
				"arabic":true,
				"persian":true
			}
		}
		canTranslate[language] = value;
		updateOption(question.id, row.id, { canTranslate });
	}

	return (
		<Popover isLazy returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} initialFocusRef={initRef}>
			<PopoverTrigger>
				<Flex onClick={onToggle} w="30px" h="30px" fontSize="15px" align="center" justify="center" cursor="pointer" color="lightText">
					<i className="fa-solid fa-gear"></i>
				</Flex>
			</PopoverTrigger>
			<PopoverContent w="250px" borderRadius="10px">
				<PopoverArrow />
				<PopoverBody p="10px">
					<Flex direction="column" mb="15px">
						<Text variant="subtitle" textTransform="capitalize">{language} Audio</Text>
						<Input
							type="text"
							placeholder="End Text"
							w="100%"
							h="40px"
							borderRadius="5px"
							p="5px"
							fontSize="13px"
							mt="5px"
							fontWeight="500"
							onChange={(e) => updateEndText(e.target.value)}
							value={endText}
							_focus={{
								borderColor:"primary",
								borderWidth:2
							}}
						/>
					</Flex>
					<Flex direction="row" mb="5px" align="center" justify="center">
						<Text flex="1" variant="subtitle" textTransform="capitalize">Translate in {language}</Text>
						<Switch onChange={(e) => updateCanTranslate(e.target.checked)} isChecked={canTranslate} size='md' />
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}




