import { useState, useRef } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	Input,
	useToast,
	Button,
	Select,
	Image,
	Switch
} from "@chakra-ui/react";
import { isEmpty, toBase64 } from "../../utils";
import { useAddQuranPerson } from "../../hooks";

export function AddPersonModal({ onClose, total }) {
	const toast = useToast();
	const fileRef = useRef();

	const { mutateAsync: addPerson } = useAddQuranPerson();
	const [loading, setLoading] = useState(false);
	const [language, setLanguage] = useState("english");

	const [image, setImage] = useState();
	const [status, setStatus] = useState('live');
	const [type, setType] = useState('juz');
	const [totalKhatmat, setTotalKhatmat] = useState(1);
	const [featured, setFeatured] = useState(false);
	const [name, setName] = useState({});
	const languges = ['English', 'Arabic', 'Persian'];

	async function handleFile(e) {
		let files = e.target.files;
		let file = files[0];

		setImage({
			"base64": await toBase64(file),
			"type": file.type,
			"size": file.size,
			"name": file.name
		})
	}

	function handleName(value) {
		let _name = {...name};
		_name[language] = value;
		setName(_name)
	}

	async function handleSubmit(){
		setLoading(true);
		let errors = validate({ image, status, name });
		if (errors.length > 0) {
			toast({
				status:"error",
				title: "Some fields are missing",
				description: errors.join(', '),
				position: 'top',
			})	
			setLoading(false);
			return
		}
		try {
			await addPerson({
				name,
				image,
				type,
				status,
				sort: total,
				featured,
				totalKhatmat: parseInt(totalKhatmat)
			})
			onClose();
			toast({
				status:"success",
				title: "Person was created successfully!",
				position: 'top',
			})	
		}
		catch(error) {
			toast({
				status:"error",
				title: error.message,
				position: 'top',
			})				
		}

		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px">Add person</Text>
					<Text variant="subtitle" fontSize="15px" mb="10px">Upload their image, add their name and the current status</Text>
					<Flex onClick={() => fileRef.current.click()} cursor="pointer" mb="20px" w="100%" align="center" direction="column" justify="center" border="1px solid #f3f3f3" h={image ? "230px" : "170px"} mt="10px" borderRadius="10px">
						{
							image ?
							<Image
								src={image.base64}
								w="100%"
								h="100%"
								objectFit="contain"
								alt="Image"
							/>
							:
							<>
								<Flex w="40px" h="40px" borderRadius="30px" color="lightText" bg="light" align="center" justify="center">
									<div><i className="fa-solid fa-image"></i></div>
								</Flex>
								<Text color="lightText" variant="subtitle" mt="5px" opacity="1">Upload your image</Text>
							</>
						}
					</Flex>
					
					<Flex mb="10px">
						{
							languges.map(lang => (
								<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
									<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="11px">{lang}</Text>
								</Flex>
							))
						}
					</Flex>

					<Flex direction="column" flex="1">
						<Text variant="subtitle">Name</Text>
						<Input
							type="text"
							placeholder="Full Name"
							mt="5px"
							value={name[language] || ""}
							onChange={(e) => handleName(e.target.value)}
							fontWeight="500"
							h="50px"
							p="10px"
							autoFocus={true}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>
					</Flex>
					<Flex direction="row" flex="1" mt="15px" align="center" justify="center">
						<Text variant="subtitle" flex="1">Status</Text>
						<Select value={status} onChange={(e) => setStatus(e.target.value)} flex="1" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select status'>
							<option value="live">Live</option>
							<option value="not-live">Not Live</option>
							<option value="pending">Pending</option>
							<option value="finished">Finished</option>
							<option value="rejected">Rejected</option>
						</Select>						
					</Flex>
					<Flex direction="row" flex="1" mt="15px" align="center" justify="center">
						<Text variant="subtitle" flex="1">Type</Text>
						<Select value={type} onChange={(e) => setType(e.target.value)} flex="1" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select type'>
							<option value="juz">Juz</option>
							<option value="surah">Surah</option>
						</Select>						
					</Flex>
					<Flex direction="row" flex="1" mt="15px" align="center" justify="center">
						<Text variant="subtitle" flex="1">Total Khatmat</Text>
						<Select value={totalKhatmat} onChange={(e) => setTotalKhatmat(e.target.value)} flex="1" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select total'>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
						</Select>						
					</Flex>


					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Featured</Text>
						<Switch isChecked={featured} onChange={(e) => setFeatured(e.target.checked)} colorScheme="green" size='md' />
					</Flex>					

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="60px">
						<Flex as={Button} variant="primary" isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Save person</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
			<input type="file" hidden style={{ display: "none" }} accept=".jpg, .png, .jpeg" ref={fileRef} onChange={handleFile} />
		</Modal>
	)
}


function validate({ image, name, status }){
	let errors = [];

	if (isEmpty(name['english']) == true) {
		errors.push('Name: English')
	}
	if (isEmpty(name['arabic']) == true) {
		errors.push('Name: Arabic')
	}
	if (isEmpty(name['persian']) == true) {
		errors.push('Name: Persian')
	}
	if (!image) {
		errors.push('Image')
	}
	if (isEmpty(status) == true) {
		errors.push('Status')
	}

	return errors;
}












