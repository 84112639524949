import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Select
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useQuestionnaires, useMoveQuestionnaireSection } from "../../hooks";

export function MoveQuestionnaireModal({ data, onClose }) {
	const type = data.className == "QuestionnaireSections" ? "section" : ""
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("");
	const [selectedSection, setSelectedSection] = useState("");
	const [selectedUnit, setSelectedUnit] = useState("");

	const { data: questionnaires } = useQuestionnaires();
	const { mutateAsync: onMoveSection } = useMoveQuestionnaireSection();

	async function handleSubmit(){
		setLoading(true);
		if (type == "section") {
			try {
				await onMoveSection({
					sectionId: data.id,
					old_questionnaire_id: data.get('questionnaire').id,
					new_questionnaire_id: selectedQuestionnaire
				})
		        toast({
					title: type + " moved!",
					position:"top",
					status: 'success',
					duration: 4000,
					isClosable: true,
		        })
		        onClose();
			}
			catch(error) {
		        toast({
					title: error.message,
					position:"top",
					status: 'error',
					duration: 9000,
					isClosable: true,
		        })
			}
		}

		setLoading(false);
	}

	let title = data.get('fullTitle') ? data.get('fullTitle')['english'] : data.get('title');
	let description = data.get('fullDescription') ? data.get('fullDescription')['english'] : data.get('description');
		
	if (!questionnaires) {
		return
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Move {type}</Text>

					<Text variant="subtitle">Current {type}</Text>
					<Flex mt="8px" mb="40px" direction="row" borderRadius="10px" w="100%" border="1px solid" borderColor="light" align="center" p="20px 10px">
						<Flex color="lightText" w="40px" h="40px" bg="light" borderRadius="30px" align="center" justify="center" fontSize="19px">
							<i className="fa-solid fa-puzzle-piece"></i>
						</Flex>
						<Flex pl="15px" flex="1" direction="column">
							<Text fontWeight="600" fontSize="16px">{title}</Text>
							<Text variant="subtitle" fontSize="13px">{description}</Text>
						</Flex>
					</Flex>

					<Text variant="subtitle">Select Questionnaire</Text>
					<Select onChange={(e) => setSelectedQuestionnaire(e.target.value)} value={selectedQuestionnaire} h="50px" mt="10px" fontWeight="500" fontSize="15px">
						<option value="" disabled>Select</option>
						{
							questionnaires.filter(e => e.id != data.get('questionnaire').id).map(quest => (
								<option value={quest.id}>{quest.get('fullTitle') ? quest.get('fullTitle')['english'] : quest.get('title')}</option>
							))
						}
					</Select>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isDisabled={!selectedQuestionnaire}  isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Move</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}








