import { Flex, Text, Image, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Draggable } from 'react-beautiful-dnd'

export function UnitBox({ data, index, isLive, onMove }) {

	function handleMove(e){
		e.preventDefault();
		e.stopPropagation();
		onMove();
	}
	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Flex as={Link} to={`sections/${data.id}`} m="10px" bg="white" cursor="pointer" direction="column" border="1px solid" borderColor="light" borderRadius="13px" overflow="hidden" {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
					<Flex bg={data.get('live') ? "success" : "light"} fontSize="30px" color={data.get('live') ? "white" : "lightText"} height='100px' align="center" justify="center">
						{
							data.get('live') ?
							<i key="circle-check" className="fa-solid fa-circle-check"></i>
							:
							<i key="border-all" className="fa-solid fa-border-all"></i>
						}
					</Flex>
					<Flex direction="column" p="20px 15px 10px" justify="center">
						<Text variant="title" fontWeight="600" fontSize="16px">{data.get('title')}</Text>
						<Flex direction="column" borderTop="1px solid" borderTopColor="light" mt="20px">
							<Flex mt="10px" align="center">
								<Text flex="1" fontWeight="600" variant="subtitle">Units</Text>
								<Text variant="subtitle" fontSize="15px" color="primary" opacity="1" fontWeight="600">{data.unitCount}</Text>
							</Flex>
							<Flex mt="10px" align="center">
								<Text flex="1" fontWeight="600" variant="subtitle">Lessons</Text>
								<Text color="success" fontSize="15px" opacity="1" variant="subtitle" fontWeight="600">{data.lessonCount}</Text>
							</Flex>
						</Flex>
					</Flex>
					<Flex w="100%" p="5px 10px 10px">
						{
							!isLive && <Button onClick={handleMove} mr="10px" w="100%" p="13px" variant="secondary">Move</Button>
						}
						<Button as={Link} to={`sections/${data.id}`} w="100%" p="13px" variant="secondary">Edit section</Button>
					</Flex>
				</Flex>
			)}
		</Draggable>
	)
}
