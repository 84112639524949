import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

import MusicData from "../data/music.json";


export const useMusic = () => {
	return useQuery(['music'],
		async () => {
			const query = new Parse.Query("Musics");
			query.limit(10000);
			query.descending('createdAt');
			let data = await query.find();

			return data
		},
		{}
	)
}

export const useMusicArtists = () => {
	return useQuery(['music-artists'],
		async () => {
			const query = new Parse.Query("MusicArtists");
			query.limit(10000);
			query.descending('createdAt');
			
			let data = await query.find();
			data.sort((a, b) => b.get('order') - a.get('order'))

			return data
		},
		{}
	)
}

export const useAddMusic = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { artist, music, youtubeId } = data;
		let savedArtist;

		const query = new Parse.Query("MusicArtists");
		query.limit(10000);
		query.descending('createdAt');
		let artists = await query.find();

		// let youtubeURL = await Parse.Cloud.run('download-youtube-video', {
		// 	youtubeId
		// });

		if (!artist.savedChannelId) {
			const MusicArtists = Parse.Object.extend("MusicArtists");
			const musicArtist = new MusicArtists();

			let artistImageUrl = await Parse.Cloud.run('download-artist-image', {
				image: artist.image,
				id: music.videoYoutubeData.channelId
			});

			musicArtist.set("live", true);
			musicArtist.set("title", artist.title);
			musicArtist.set("image", artistImageUrl);
			musicArtist.set("order", artist.order);
			musicArtist.set("youtubeChannelID", music.videoYoutubeData.channelId);
			musicArtist.set('publishedAt', artist.videoChannelData.publishedAt);
			musicArtist.set('customUrl', artist.videoChannelData.customUrl);
			savedArtist = await musicArtist.save();

		} else {
			savedArtist = {
				id: artist.savedChannelId
			}
		}
		
		const MusicObj = Parse.Object.extend("Musics");
		const _music = new MusicObj();
		_music.set('title', music.title);
		_music.set("artist", {
			"__type":"Pointer",
			"objectId":savedArtist.id,
			"className":"MusicArtists"
		});
		_music.set('live', false);
		_music.set('youtubeId', youtubeId);
		_music.set('image', music.image);
		_music.set('downloads', 0);
		_music.set('url', null);
		await _music.save();

		queryClient.refetchQueries(['music'], ['music-artists']);
	}, {
		...options,
	})
}


export const useAddArtist = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { artist } = data;
				
		const MusicArtists = Parse.Object.extend("MusicArtists");
		const musicArtist = new MusicArtists();

		let artistImageUrl = await Parse.Cloud.run('download-artist-image', {
			image: artist.image,
			id: artist.channelId
		});

		musicArtist.set("live", true);
		musicArtist.set("title", artist.title);
		musicArtist.set("image", artistImageUrl);
		musicArtist.set("order", artist.order);
		musicArtist.set("youtubeChannelID", artist.channelId);
		musicArtist.set('publishedAt', artist.publishedAt);
		musicArtist.set('customUrl', artist.customUrl);
		await musicArtist.save();

		queryClient.refetchQueries(['music-artists']);
	}, {
		...options,
	})
}

export const useEditArtist = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { title, artistId } = data;
		
		const query = new Parse.Query("MusicArtists");
		let _artist = await query.get(artistId);

		_artist.set('title', title);
		await _artist.save();


		queryClient.refetchQueries(['music-artists']);
	}, {
		...options,
	})
}

export const useEditMusic = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { title, artistId, live, musicId } = data;
		
		const query = new Parse.Query("Musics");
		let _music = await query.get(musicId);

		_music.set('title', title);
		_music.set('live', live);
		_music.set('artist', {
			"__type":"Pointer",
			"objectId":artistId,
			"className":"MusicArtists"
		})
		await _music.save();


		queryClient.refetchQueries(['music']);
	}, {
		...options,
	})
}


export const useDeleteMusic = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (musicId) => {
		const query = new Parse.Query("Musics");
		let _music = await query.get(musicId);

		await _music.destroy();

		queryClient.refetchQueries(['music']);
	}, {
		...options,
	})
}







