import { useState } from "react";
import { Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Skeleton, Button, Box } from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { useQuestionnaireLessons, useEditQuestionnaireLessonOrder, useDeleteQuestionnaireUnit } from "../../../hooks";
import { CreateQuestionnaireLessonModal } from "../../../includes";

export function UnitRow({ data, index, isLive }){
	const { id, sectionID, lessonID } = useParams();
	const navigate = useNavigate();

	const { data: lessons, isLoading } = useQuestionnaireLessons(data.id);
	const { mutateAsync: updateOrder } = useEditQuestionnaireLessonOrder();
	const { mutateAsync: deleteUnit } = useDeleteQuestionnaireUnit();

	const [createLesson, setCreateLesson] = useState(false);

	async function onDragEnd({ source, destination }){
	    let _data = [...lessons];

	    if (source && destination) {
	        const [removed] = _data.splice(source.index, 1)
	        _data.splice(destination.index, 0, removed)

	        updateOrder({
	        	data: _data,
	        	unitID: data.id
	        });
	    }
	}

	async function handleDelete(){
		navigate(`/questionnaires/${id}/sections/${sectionID}`)
		await deleteUnit({
			id: data.id,
			sectionID: data.get('section').id
		})
	}

	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<AccordionItem border="none" bg="white" p="0" mb="0" {...provided.draggableProps} ref={provided.innerRef}>
					<Flex direction="column">
						<AccordionButton w="100%" p="0" mb="10px" _expanded={{ bg:"primary", color:"white", opacity:1 }} color="lightText" border="1px solid" borderColor="light" borderRadius="37px">
							<Flex w="100%" align="center" p="10px">
								<Flex {...provided.dragHandleProps} mr="10px" w="30px" h="30px" bg="rgba(0,0,0,0.06)" borderRadius="35px" fontSize="13px" color="default" align="center" justify="center">
									<i className="fa-solid fa-grip-vertical"></i>
								</Flex>
								<Text flex="1" fontSize="14px" textAlign="left" fontWeight="600" color="default" opacity="1">{data.get('title')}</Text>
								<AccordionIcon />
							</Flex>
						</AccordionButton>
						<AccordionPanel p="0" pb={4} pt="0">
							{ 
								!isLive && <Text onClick={handleDelete} variant="link" color="error" p="0" mt="-5px" mb="10px" textAlign="right">Delete unit</Text>
							}
							{
								isLoading || !lessons ?
								<Flex direction="column" ml="20px">
									<Skeleton height="50px" w="100%" opacity="0.5" borderRadius="30px" mb="10px" />
									<Skeleton height="50px" w="100%" opacity="0.5" borderRadius="30px" mb="10px"/>
									<Skeleton height="50px" w="100%" opacity="0.5" borderRadius="30px" mb="10px" />
								</Flex>
								: lessons.length == 0 ?
									<Flex cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="200px" border="2px solid" w="100%" borderColor="light" align="center" justify="center" overflow="hidden">
										<Flex w="35px" h="35px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
											<i className="fa-solid fa-school"></i>
										</Flex>
										<Text variant="subtitle" fontSize="14px" mt="10px">No lessons found!</Text>
										{
											!isLive && <Button onClick={() => setCreateLesson(true)} variant="dark" mt="20px">Create lesson</Button>
										}
									</Flex>		
								:
								<Flex direction="column" ml="20px">
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="questionnaire-section-lesson-wrapper">
											{(provided2) => (
												<Box w="100%" ref={provided2.innerRef} {...provided2.droppableProps}>
													{
														lessons.map((lesson, index) => (
															<LessonRow key={lesson.id} data={lesson} index={index} />
														))
													}
												</Box>
											)}
										</Droppable>
									</DragDropContext>
									<Flex mb="10px" >
										{
											!isLive && <Text onClick={() => setCreateLesson(true)} textAlign="right" flex="1" variant="link" p="0" fontSize="13px">Create lesson</Text>
										}
									</Flex>
								</Flex>
							}
						</AccordionPanel>
					</Flex>
					{
						createLesson && <CreateQuestionnaireLessonModal unitData={data} onClose={() => setCreateLesson(false)} />
					}
				</AccordionItem>
			)}
		</Draggable>
	)
}

function LessonRow({ data, index }){
	const { id, sectionID, lessonID } = useParams();

	let lessonActive = data.id == lessonID;
	let title = data.get('fullTitle') ? data.get('fullTitle')['english'] : data.get('title')

	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Flex {...provided.dragHandleProps} as={Link} to={`/questionnaires/${id}/sections/${sectionID}/lessons/${data.id}`} border="1px solid" bg={lessonActive ? "dark" : "white"} h="50px" mb="10px" borderColor="light" borderRadius="30px" align="center" p="10px" {...provided.draggableProps} ref={provided.innerRef}>
					<Flex mr="10px" w="30px" h="30px" bg={lessonActive ? "rgba(255,255,255,0.2)" : "light"} fontSize="14px" borderRadius="35px" color={lessonActive ? "white" : "lightText"} align="center" justify="center">
						<i className="fa-solid fa-grip-vertical"></i>
					</Flex>
					<Text fontWeight="600" fontSize="14px" color={ lessonActive ? "white" : "dark"} opacity={lessonActive ? 1 : 0.7 }>{title}</Text>
				</Flex>
			)}
		</Draggable>
	)
}




