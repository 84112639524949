import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useLoginUser = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		return await Parse.User.logIn(data.email, data.password);
	}, {
		...options
	})
}

export const useLogoutUser = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		return await Parse.User.logOut();
	}, {
		...options
	})
}
