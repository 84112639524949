import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useQuestionnaireLessons = (unitId) => {
	return useQuery(['questionnaire-lessons', unitId],
		async () => {
			const query = new Parse.Query("QuestionnaireLessons");
			query.equalTo("unit", {
				"__type":"Pointer",
				"objectId":unitId,
				"className":"QuestionnaireUnits"
			});
			let data = await query.find();
			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useQuestionnaireLessonByID = (id, options) => {
	return useQuery(['questionnaire-lesson', id],
		async () => {
			const query = new Parse.Query("QuestionnaireLessons");
			return await query.get(id);
		},
		{
			...options
		}
	)
}

export const useCreateQuestionnaireLesson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const QuestionnaireLessons = Parse.Object.extend("QuestionnaireLessons");
		const new_lesson = new QuestionnaireLessons();

		new_lesson.set("type", "level");
		new_lesson.set("fullTitle", {
			"english":data.title,
			"arabic":"",
			"persian":""
		});
		new_lesson.set("description", data.description);
		new_lesson.set('questionnaire',{
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		
		new_lesson.set('section',{
			"__type":"Pointer",
			"objectId":data.sectionID,
			"className":"QuestionnaireSections"
		})

		new_lesson.set('unit',{
			"__type":"Pointer",
			"objectId":data.unitID,
			"className":"QuestionnaireUnits"
		})


		const query = new Parse.Query("QuestionnaireLessons");
		query.equalTo('questionnaire', {
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		query.equalTo('section', {
			"__type":"Pointer",
			"objectId":data.sectionID,
			"className":"QuestionnaireSections"
		})
		query.equalTo('unit', {
			"__type":"Pointer",
			"objectId":data.unitID,
			"className":"QuestionnaireUnits"
		})

		const totalLessons = await query.count();
		new_lesson.set('order', totalLessons);

		await new_lesson.save();

		queryClient.refetchQueries(['questionnaire-lessons', data.unitID]);

		return new_lesson;	
	}, {
		...options,
	})
}

export const useDeleteQuestionnaireLesson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		const query = new Parse.Query("QuestionnaireLessons");
		const old_lesson = await query.get(data.lessonID);

		await old_lesson.destroy();

		queryClient.refetchQueries(['questionnaire-lessons', data.unitID]);

	}, {
		...options,
	})
}

export const useEditQuestionnaireLessonOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async ({ data, unitID }) => {
		
		queryClient.setQueryData(['questionnaire-lessons', unitID], data);

		await Promise.all(data.map(async (lesson, index) => {
			lesson.set('order', index);
			await lesson.save();
		}));

	}, {
		...options,
	})
}









