import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Tooltip } from "@chakra-ui/react";
import { isEmpty } from "../../../../utils";

export function TranslatePopover({ questions, setLoading, setQuestions }) {

	async function onTranslate(lang) {
		setLoading(`Translating to ${lang}...`)

		let _questions = await Promise.all(questions.map(async ques => {
			
			let title = ques.title['english'];
			let helpText = ques.helpText['english'];
			let source = ques.source['english'];
			let _options = [...ques.options];

			if (!isEmpty(title)) {
				ques.title[lang.toLowerCase()] = await translateGoogle(title, lang);
			}
			if (!isEmpty(helpText)) {
				ques.helpText[lang.toLowerCase()] = await translateGoogle(helpText, lang);
			}
			if (!isEmpty(source)) {
				ques.source[lang.toLowerCase()] = await translateGoogle(source, lang);
			}
			ques.options = await Promise.all(ques.options.map(async option => {
				let canTranslate = option['canTranslate'] ? option['canTranslate'][lang.toLowerCase()] : true;
				if (!isEmpty(option.title['english']) && canTranslate) {
					option.title[lang.toLowerCase()] = await translateGoogle(option.title['english'], lang);
				}
				return option;
			}))
			
			return ques;
		}))

		setQuestions(_questions)
		setLoading();
	}

	async function translateGoogle(text, lang) {
		let target = lang == "Arabic" ? "ar" : lang == "Persian" ? "fa" : null;
		console.log(text)
		if (!isEmpty(text) && !isEmpty(target)) {
			const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_YOUTUBE_API_KEY}`, {
				method: 'POST',
				body:JSON.stringify({
					q: text,
					target
				})
			})
			let { data } = await response.json();
			if (data && data.translations && data.translations.length > 0) {
				return data.translations[0].translatedText;
			} else {
				return text;
			}
		}

		return text;
	}

	return (
		<Menu>
			<Tooltip label='Translate questions' placement="top" hasArrow>
				<MenuButton>
					<Flex w="30px" h="30px" borderRadius="30px" bg="light" fontSize="15px" mr="10px" align="center" justify="center" cursor="pointer" color="lightText">
						<i className="fa-solid fa-language"></i>
					</Flex>
				</MenuButton>
			</Tooltip>
			<MenuList>
				<Text variant="subtitle" p="0 10px" fontSize="10px">Translations</Text>
				<MenuDivider />
				<MenuItem p="7px 10px" onClick={() => onTranslate('Arabic')}>
					<Text variant="subtitle" fontSize="14px">Arabic</Text>
				</MenuItem>
				<MenuItem p="7px 10px" onClick={() => onTranslate('Persian')}>
					<Text variant="subtitle" fontSize="14px">Persian</Text>
				</MenuItem>
			</MenuList>
		</Menu>
	)
}

