import { useState } from "react";
import { Flex, Text, Input, Select, Box, Tooltip, Spinner, useToast, Image, Textarea } from "@chakra-ui/react";
import { useQuestionnaire } from "../../../contexts/questionnaire";
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { RowMorePopover } from "./row-more-popover";
import { useGenerateAudioQuestion } from "../../../../hooks";
import { isEmpty, isURL, getYoutubeID } from "../../../../utils";

export function QuestionBox({ active, data, index, language }){
	const { updateQuestion, setChosenQuestion, removeQuestion, updateOption, addOption, removeOption, setAnswer, updateVideo } = useQuestionnaire();
	const [loading, setLoading] = useState(false);
	const { mutateAsync: generateAudio } = useGenerateAudioQuestion();

	let title = data['title'][language] || "";
	let source = data['source'][language] || "";

	function updateTitle(value){
		let title = data['title'];
		title[language] = value;
		updateQuestion(data.id, { title: title });
	}

	function updateSource(value){
		let source = data['source'];
		source[language] = value;
		updateQuestion(data.id, { source });
	}

	async function updateAudio() {
		setLoading(true);
		let lessonID = data.lessonID;
		let questionID = data.parseID;
		let questionnaireID = data.questionnaireID;
		let sectionID = data.sectionID;

		let title = data.title;
		if (questionID) {
			let new_audio = await generateAudio({
				lessonID,
				questionID,
				questionnaireID,
				sectionID,
				title,
				audio: data.audio,
				choices: data.options,
				answer: data.answer
			})
			data['audio'] = new_audio;
			updateQuestion(data.id, { audio: data['audio'] });
		}
		setLoading(false);
	}

	async function playAudio(type){
		setLoading(true);
		try {
			let audioPath = process.env.REACT_APP_GITHUB_BASE_PATH + data.audio[type][language];
			const audio = new Audio(audioPath);
			audio.play();
		}
		catch(error) {
			console.log(error)
		}
		setLoading(false);
	}

	let audioTitle = data.audio && data.audio.start[language];
	let audioChosen = data.audio && data.audio.end[language];

	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Flex onClick={() => setChosenQuestion(data.id)} borderLeftWidth={ active ? 5 : 0} bg="white" borderLeftColor={ active ? "primary" : null } transition="0.3s border" direction="column" boxShadow="base" align="flex-start" mb="20px" borderRadius="10px" w="100%" {...provided.draggableProps} ref={provided.innerRef}>
					<Flex {...provided.dragHandleProps} align="center" justify="center" w="100%" color="lightText" cursor="grab" p="10px 10px 0">
						<i className="fa-solid fa-grip"></i>
					</Flex>

					<Flex flex="1" p="10px" w="100%" direction="column">
						<Flex w="100%" align="center" justify="center">
							<Input
								type="text"
								placeholder="Write a lesson title"
								flex="4"
								h="50px"
								w="100%"
								value={title}
								onChange={(e) => updateTitle(e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:2
								}}
								fontWeight="500"
							/>
							<Select value={data.type} onChange={(e) => updateQuestion(data.id, { "type": e.target.value })} flex="1" h="50px" ml="10px" fontWeight="600" fontSize="15px">
								<option value="radio">Radio</option>
								<option value="video">Video</option>
							</Select>
							{
								audioTitle != "" && (
									<Tooltip label="Play title" hasArrow>
										<Flex onClick={() => playAudio('start')} ml="10px" w="30px" h="30px" color="lightText" bg="#f3f3f3" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
											{
												loading ?
												<Spinner size='xs' />
												:
												<div><i className="fa-solid fa-play"></i></div>
											}
										</Flex>
									</Tooltip>
								)
							}
						</Flex>
						{
							data.type == "radio" ?
								<Flex direction="column" mt="10px">
									<Droppable droppableId={data.id} key={data.id} type="options">
										{(provided, snapshot) => (
											<Box ref={provided.innerRef} {...provided.droppableProps} w="100%">
												{
													data.options.map((option, index) => (
														<RadioOption key={option.id} question={data} index={index} data={option} language={language} updateOption={updateOption} removeOption={removeOption} loading={loading} setAnswer={setAnswer} audioChosen={audioChosen} playAudio={playAudio} />
													))
												}
											</Box>
										)}
									</Droppable>
									<Flex align="center" justify="center" mt="5px">
										<Input opacity="0.6" type="text" w="50%" h="35px" border="none" pl="0" fontWeight="500" fontSize="13px" placeholder="Source" value={source} onChange={(e) => updateSource(e.target.value)} />
										<Flex onClick={() => addOption(data.id)} flex="1" align="center" justify="flex-end" h="35px" pl="13px" fontSize="13px" color="primary">
											<i className="fa-solid fa-circle-plus"></i>
											<Text variant="link" fontSize="13px" p="0" pt="1px" pl="5px">
												Add Option
											</Text>
										</Flex>
									</Flex>
								</Flex>
							:
							<Flex direction="column" mt="10px">
								<VideoOption 
									question={data} 
									updateVideo={updateVideo} 
									language={language} />
							</Flex>
						}
					</Flex>

					<Flex align="center" justify="space-between" w="100%" p="10px" borderTop="1px solid #f3f3f3">
						{
							data.type == "radio" ?
							<Tooltip label="Generate Audio" hasArrow>
								<Flex onClick={updateAudio} mr="10px" w="30px" h="30px" color="lightText" bg="#f3f3f3" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
									{
										loading ?
										<Spinner size='xs' />
										:
										<div><i className="fa-solid fa-rss"></i></div>
									}
								</Flex>
							</Tooltip>
							:
							<Flex></Flex>
						}
						<Flex onClick={() => removeQuestion(data.id)} w="30px" h="30px" color="lightText" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
							<i className="fa-solid fa-trash"></i>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Draggable>
	)
}


function RadioOption({ question, data, language, updateOption, removeOption, setAnswer, index, audioChosen, playAudio, loading }){
	let title = data['title'][language];

	function updateTitle(value){
		let title = data['title'];
		title[language] = value;
		updateOption(question.id, data.id, { title: title });
	}

	return (
		<Draggable draggableId={question.id + ":::" + data.id} key={data.id} index={index}>
			{(provided, snapshot) => (
				<Flex {...provided.draggableProps} ref={provided.innerRef} p="7px 0" align="center" justify="center" borderBottom="1px solid" borderBottomColor="light" bg="white">
					<Flex {...provided.dragHandleProps} w="30px" h="50px" fontSize="13px" cursor="grab" color="lightText" align="center" justify="center" borderRadius="30px">
						<i className="fa-solid fa-grip-vertical"></i>
					</Flex>

					<Flex onClick={() => setAnswer(question.id, data.id)} cursor="pointer" w="35px" h="50px" mr="5px" align="center" justify="center">
						<Flex w="20px" h="20px" borderRadius="30px" border="2px solid" borderColor={question.answer == data.id ? "primary" : "lightText"} align="center" justify="center">
							{
								question.answer == data.id && <Flex w="10px" h="10px" borderRadius="30px" bg="primary"></Flex>
							}
						</Flex>
					</Flex>

					<Flex flex="1">
						<Input
							type="text"
							variant="underline"
							placeholder="Option title"
							bg="white"
							value={title}
							onChange={(e) => updateTitle(e.target.value)}
							p="0"
							_placeholder={{
								opacity:0.5
							}}
							fontWeight="500"
						/>
					</Flex>
					{
						audioChosen != "" && question.answer == data.id && (
							<Tooltip label="Play Answer" hasArrow>
								<Flex onClick={() => playAudio('end')} mr="10px" w="30px" h="30px" color="lightText" bg="#f3f3f3" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
									{
										loading ?
										<Spinner size='xs' />
										:
										<div><i className="fa-solid fa-play"></i></div>
									}
								</Flex>
							</Tooltip>
						)
					}
					<RowMorePopover updateOption={updateOption} question={question} row={data} language={language} />
					<Flex onClick={() => removeOption(question.id, data.id)} w="35px" h="50px" align="center" justify="center" color="lightText" fontSize="17px" cursor="pointer">
						<i className="fa-solid fa-xmark"></i>
					</Flex>
				</Flex>
			)}
		</Draggable>
	)
}

function VideoOption({ question, updateVideo, language }) {
	const toast = useToast();
	async function updateUrl(vidId, value){
		let _videos = [...question.videos];
		let index = _videos.findIndex(e => e.id == vidId);
		if (index >= 0) {
			_videos[index].urls[language] = value;
			let savedData = { 
				urls: _videos[index].urls ,
				videoMetadata: _videos[index].videoMetadata
			}

			let metadata = await onCheckVideoUrl(value);
			if (metadata) {
				savedData.videoMetadata[language] = metadata;
			}

			updateVideo(question.id, vidId, savedData)
		}
	}

	async function updateVideoMetadata(vidId, type, value){
		let _videos = [...question.videos];
		let index = _videos.findIndex(e => e.id == vidId);
		if (index >= 0) {
			_videos[index].videoMetadata[language][type] = value;
			
			updateVideo(question.id, vidId, { 
				videoMetadata: _videos[index].videoMetadata
			})
		}
	}	

	async function onCheckVideoUrl(url){
		if (isEmpty(url) == false && isURL(url)) {
			let youtubeID = getYoutubeID(url);
			try {
				let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
				let data = await response.json();

				if (data && data.items.length > 0) {
					let { snippet } = data.items[0];
					return {
						"videoId": youtubeID,
						"title": snippet.title,
						"description": snippet.description,
						"image":snippet.thumbnails.high,
						"channel":{
							"id": snippet.channelId,
							"title": snippet.channelTitle
						},
						"publishedAt":snippet.publishedAt,
						"categoryId": snippet.categoryId
					}
				}
			}
			catch(error) {
				toast({
					status:"error",
					title:error.message,
					position: 'top',
				})	

				return {}			
			}
		}
		return {}
	}

	return (
		<Flex direction="column" w="100%" pt="10px" pb="10px">
			{
				question.videos.map(vid => (
					<Flex key={vid.id} direction="column" mb="10px">
						<Flex align="center" mb="10px">
							<Text variant="subtitle" mr="10px">Video URL</Text>
							<Input
								type="text"
								placeholder="What is the youtube url?"
								h="40px"
								flex="1"
								fontSize="14px"
								value={vid.urls[language]}
								onChange={(e) => updateUrl(vid.id, e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:2
								}}
								fontWeight="500"
							/>
						</Flex>
						{
							vid.videoMetadata[language].videoId ?
							<Flex mt="5px" bg="light" borderRadius="10px" p="15px 10px" align="center">
								<Image
									src={vid.videoMetadata[language].image.url}
									w="170px"
									h="100%"
									objectFit="cover"
									borderRadius="7px"
									mr="15px"
								/>
								<Flex w="100%" direction="column">
									<Input
										type="text"
										placeholder="Video title"
										mb="10px"
										h="40px"
										w="100%"
										bg="white"
										fontSize="14px"
										value={vid.videoMetadata[language].title}
										onChange={(e) => updateVideoMetadata(vid.id, 'title', e.target.value)}
										_focus={{
											borderColor:"primary",
											borderWidth:2
										}}
										fontWeight="500"
									/>
									<Textarea
										type="text"
										placeholder="Video Description"
										bg="white"
										w="100%"
										value={vid.videoMetadata[language].description}
										_focus={{
											borderColor:"primary",
											borderWidth:2
										}}
										onChange={(e) => updateVideoMetadata(vid.id, 'description', e.target.value)}
										p="10px"
										fontSize="14px"
										rows={4}
										fontWeight="500"
									/>
								</Flex>


							</Flex>
							:
							null
						}
					</Flex>
				))
			}
		</Flex>
	)
}














