import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Image
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useEditArtist } from "../../hooks";

export function EditArtist({ artist, onClose }) {
	const toast = useToast();
	const { mutateAsync: onEditArtist } = useEditArtist();

	const [loading, setLoading] = useState(false);
	const [language, setLanguage] = useState("english");
	const languges = ['English', 'Arabic', 'Persian'];
	const [channelTitle, setChannelTitle] = useState(artist.get('title') || "");

	function handleChannelTitle(value) {
		let _channelTitle = {...channelTitle};
		_channelTitle[language] = value;
		setChannelTitle(_channelTitle)
	}

	async function handleSubmit(){
		setLoading(true);
		try {
			await onEditArtist({
				artistId: artist.id,
				title: channelTitle
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})			
		}
		onClose();
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Edit artist</Text>
					
					
					<Flex align="center">
						<Image
							src={process.env.REACT_APP_CDN_PATH + artist.get('image')}
							w="80px"
							h="80px"
							borderRadius="130px"
							objectFit="cover"
						/>
						<Flex pl="10px" flex="1" direction="column">
							<Flex mb="10px">
								{
									languges.map(lang => (
										<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
											<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="11px">{lang}</Text>
										</Flex>
									))
								}
							</Flex>
							<Input
								type="text"
								placeholder="Artists Name"
								fontWeight="500"
								h="55px"
								p="10px"
								fontSize="15px"
								value={channelTitle[language]}
								onChange={(e) => handleChannelTitle(e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:"2px"
								}}
							/>	
						</Flex>
					</Flex>


					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary"  isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Save artist</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}








