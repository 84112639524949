import { Flex, Text, Button } from '@chakra-ui/react';
import { Header } from "../includes";

export default function PushNotifications() {
	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px">
				<Flex align="center" justify="center" mb="10px">
					<Flex flex="1" direction="column">
						<Text mb="7px" variant="title">Push Notifications</Text>
					</Flex>
					<Button variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Create notification</Button>
				</Flex>
				<Flex direction="column">



				</Flex>

			</Flex>
		</Flex>
	)
}

