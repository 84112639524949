import { useState, useRef } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Textarea,
	Image
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { isEmpty, toBase64 } from "../../utils";
import { useCreateQuestionnaireUnit } from "../../hooks";

export function CreateQuestionnaireUnitModal({ sectionData, onClose }) {
	const toast = useToast();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const { mutateAsync } = useCreateQuestionnaireUnit();

	async function handleSubmit(){
		setLoading(true);
		try {
			let data = await mutateAsync({
				title,
				sectionID:sectionData.id,
				questionnaireID: sectionData.get('questionnaire').id
			});
			onClose();
			toast({
				status:"success",
				title:"Unit created successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="5px">Create Unit</Text>
					<Text variant="subtitle" fontSize="14px" mb="20px">A unit is set of lessons that users need to complete to move forward.</Text>

					<Flex direction="column" mb="20px">
						<Text variant="subtitle">Title</Text>
						<Input
							type="text"
							placeholder="Set a great title"
							mt="10px"
							fontWeight="500"
							h="50px"
							p="10px"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>
					</Flex>
					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isDisabled={validate({ title })} isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create unit</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function validate({ title }) {
	if (isEmpty(title)) {
		return true
	}

	return false;
}







