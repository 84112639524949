import { useState, useEffect } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Image,
	Select
} from "@chakra-ui/react";
import { isEmpty, getYoutubeID, isURL } from "../../utils";
import { useAddMusic } from "../../hooks";

export function AddMusic({ onClose, artists, allMusic }) {
	const toast = useToast();
	const { mutateAsync: createMusic } = useAddMusic();
	
	const [loading, setLoading] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	const [playlistUrl, setPlaylistUrl] = useState("");
	const [videoYoutubeData, setVideoYoutubeData] = useState();
	const [videoChannelData, setVideoChannelData] = useState();
	const [channelTitle, setChannelTitle] = useState({});
	const [channelImage, setChannelImage] = useState();
	const [savedChannelId, setSavedChannelId] = useState();

	const [title, setTitle] = useState();
	const [image, setImage] = useState();
	const [language, setLanguage] = useState("english");
	const languges = ['English', 'Arabic', 'Persian'];

	useEffect(() => {
		onStart();
	},[])

	async function onStart(){
		const text = await navigator.clipboard.readText();
		if (text && isURL(text)) {
			setVideoUrl(text);
		}
	}

	useEffect(() => {
		if (isURL(videoUrl) && !videoYoutubeData) {
			fetchData()
		}
	},[videoUrl])

	async function fetchData(){
		setLoading(true);
		let youtubeID = getYoutubeID(videoUrl);
		if (youtubeID) {
			let index = allMusic.findIndex(e => e.get('youtubeId') == youtubeID);
			if (index < 0) {
				try {
					let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
					let data = await response.json();

					if (data && data.items.length > 0) {
						let { snippet } = data.items[0];

						let response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${snippet.channelId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
						let channelData = await response2.json();

						let { snippet: channelSnippet } = channelData.items[0];

						let artistIndex = artists.findIndex(e => e.get('youtubeChannelID') == snippet.channelId);
						if (artistIndex >= 0) {
							setSavedChannelId(artists[artistIndex].id)
						}

						setTitle(snippet.title);
						setImage(snippet.thumbnails.high.url)

						setChannelTitle({
							"english":channelSnippet.title,
							"arabic":"",
							"persian":""
						});

						setChannelImage(channelSnippet.thumbnails.high.url)
						setVideoChannelData(channelSnippet)
						setVideoYoutubeData(snippet);
					}
				}
				catch(error) {
					toast({
						status:"error",
						title:error.message,
						position: 'top',
					})				
				}
			} else {
				toast({
					status:"error",
					title:"Youtube video already exists",
					position: "top"
				})				
			}
		} else {
			toast({
				status:"error",
				title:"We could not find the youtube id from the url",
				position: "top"
			})
		}
		setLoading(false);
	}

	function handleChannelTitle(value) {
		let _channelTitle = {...channelTitle};
		_channelTitle[language] = value;
		setChannelTitle(_channelTitle)
	}


	async function handleSubmit(){
		setLoading(true);
		try {
			await createMusic({
				youtubeId: getYoutubeID(videoUrl),
				artist: {
					savedChannelId,
					title: channelTitle,
					image: channelImage,
					order: artists.length,
					videoChannelData
				},
				music:{
					title,
					image,
					videoYoutubeData
				}
			})
			onClose();
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	let synced;
	if (videoYoutubeData) {
		synced = artists.findIndex(e => e.get('youtubeChannelID') == videoYoutubeData.channelId);
		if (synced >= 0) {
			synced = artists[synced];
		} else {
			synced = null;
		}
	}

	return (
		<Modal isOpen={true} onClose={loading ? null : onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				{
					!loading && <ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				}
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Add music</Text>
					{
						videoChannelData && videoYoutubeData ? 
						<Flex direction="column" pb="50px">
							<Image
								src={image}
								w="100%"
								h="200px"
								objectFit='contain'
								bg="#f3f3f3"
								borderRadius="10px"
							/>
							<Flex flex="1" mt="15px" direction="column">
								<Text variant="subtitle" mb="5px">Title</Text>
								<Input
									type="text"
									placeholder="Preset Name"
									fontWeight="500"
									h="55px"
									p="10px"
									fontSize="15px"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>			
							</Flex>
							<Flex flex="1" mt="15px" direction="column">
								<Flex flex="1" align="center">
									<Text flex="1" variant="subtitle" mb="5px">Artist</Text>
									<Text w="100%" textAlign="right" color={synced ? "success" : "error"} opacity="1" variant="subtitle" fontSize="12px">{synced ? "Synced" : "Not Synced"}</Text>
								</Flex>
								<Flex align="center">
									<Image
										src={synced ? process.env.REACT_APP_CDN_PATH + synced.get('image') : channelImage}
										w="80px"
										h="80px"
										borderRadius="130px"
										objectFit="cover"
									/>
									<Flex pl="10px" flex="1" direction="column">
										<Flex mb="10px">
											{
												languges.map(lang => (
													<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
														<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="11px">{lang}</Text>
													</Flex>
												))
											}
										</Flex>
										<Input
											type="text"
											placeholder="Artists Name"
											fontWeight="500"
											h="55px"
											p="10px"
											disabled={savedChannelId ? true : false}
											fontSize="15px"
											value={synced ? synced.get('title')[language] : channelTitle[language]}
											onChange={synced ? null : (e) => handleChannelTitle(e.target.value)}
											_focus={{
												borderColor:"primary",
												borderWidth:"2px"
											}}
										/>	
									</Flex>
								</Flex>
							</Flex>
							{
								artists.length > 0 && (
									<>
										<Text variant="subtitle" textAlign="center" pl="90px" mt="10px">or select an existing artist</Text>
										<Select value={savedChannelId} onChange={(e) => setSavedChannelId(e.target.value)} pl="90px" mt="15px" w="100%" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select artist'>
											{
												artists.map(artist => (
													<option key={artist.id} value={artist.id}>{artist.get('title')['english']} - {artist.get('title')['arabic']}</option>
												))
											}
										</Select>
									</>
								)
							}
						</Flex>
						:
						<Flex mb="20px">
							<Flex direction="column" flex="1">
								<Text variant="subtitle">Youtube URL</Text>
								<Input
									type="text"
									placeholder="Youtube URL"
									mt="10px"
									fontWeight="500"
									h="50px"
									p="10px"
									autoFocus={true}
									value={videoUrl}
									onChange={(e) => setVideoUrl(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>

						</Flex>
					}
					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Add music</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}




							// <Flex direction="column" flex="1" ml="10px">
							// 	<Text variant="subtitle">Playlist URL</Text>
							// 	<Input
							// 		type="text"
							// 		placeholder="Playlist URL"
							// 		mt="10px"
							// 		fontWeight="500"
							// 		h="50px"
							// 		p="10px"
							// 		value={playlistUrl}
							// 		onChange={(e) => setPlaylistUrl(e.target.value)}
							// 		_focus={{
							// 			borderColor:"primary",
							// 			borderWidth:"2px"
							// 		}}
							// 	/>
							// </Flex>



