import { Global } from "@emotion/react"

export const Fonts = () => (
  <Global
    styles={`
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/Gilroy-Bold.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/Gilroy-ExtraBold.ttf') format('truetype');
          font-weight: 600;
          font-style: normal;
        }
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/Gilroy-Black.ttf') format('truetype');
          font-weight: 700;
          font-style: normal;
        }
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/Gilroy-Heavy.ttf') format('truetype');
          font-weight: 800;
          font-style: normal;
        }

      *:focus {outline:none !important; box-shadow: none !important;}
      a:hover {
        text-decoration:none !important;
      }
      ::-webkit-scrollbar {
        display: none  /* Remove scrollbar space */;
        width: 0 !important;
        height:0 !important;
        background: transparent !important;
      }
      /* Optional: show position indicator in red */
      ::-webkit-scrollbar-thumb {
        display: none  /* Remove scrollbar space */;
        width: 0 !important;
        height:0 !important;
        background: transparent !important;
      }
      b {
        font-weight:600
      }
     
      body {
        background-color: #fff;
        font-family: 'Gilroy', sans-serif !important;
        margin: 0;
        padding: 0;
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
      }

      .PhoneInput {
        flex:1;
        border:1px solid rgba(0,0,0,0.1);
        padding:7px 10px;
        margin-right:10px;
        border-radius:5px;
      }
      
      .PhoneInputInput {
        font-family: 'Gilroy', sans-serif !important;
        font-weight:500;
        font-size:15px;
        background:white
      }

      .ql-toolbar.ql-snow {
        border:1px solid #f3f3f3 !important;
        border-radius:5px;
        margin-bottom:10px;
        font-family: 'Gilroy', sans-serif !important;
        text-align:center
      }
      #description-editor {
        border:1px solid #f3f3f3 !important;
        border-radius:5px !important;
      }
      .ql-editor {
        font-family: 'Gilroy', sans-serif !important;
      }
      .ql-editor h1 span, .ql-editor h1 {
        font-weight:600 !important;
        font-size:19px !important;
        color:#020147 !important;
        margin-bottom:5px !important;
      }
      .ql-editor h2 span, .ql-editor h2 {
        font-weight:500 !important;
        font-size:17px !important;
        color:#020147 !important;
      }
      .ql-editor a {
        color:#0097f5 !important;
        font-weight:500 !important;
      }
      .ql-editor {
        color:#020147 !important;
      }
      .ql-editor p {
        line-height:1.6;
        font-size:14px;
        color:#020147 !important;
      }
      .ql-picker-options {
        border:1px solid #f3f3f3 !important;
        padding:10px !important;
        color:#020147 !important;
      }
      .ql-formats {
        .ql-stroke {
          stroke:#020147 !important;
        }
        .ql-fill {
          fill:#020147 !important;
        }
      }
      .ql-active {
        .ql-stroke {
          stroke:#0097f5 !important;
        }
        .ql-fill {
          fill:#0097f5 !important;
        }
      }
      .ql-syntax {
        background:#020147 !important;
        padding:10px 10px !important;
        color:white !important;
        border-radius:7px !important;
        font-weight:600 !important;
      }

      .ql-picker-label {
        border:1px solid rgba(0,0,0,0.1) !important;
        color:#020147 !important;
        border-radius:30px !important;
        text-align:left;
      }
      .ql-picker-item:hover, .ql-selected {
        color:#0097f5 !important;
      }
      .ql-toolbar.ql-snow .ql-formats:last-child {
        margin-right:0 !important;
      }
      
      .react-datepicker__header  {
        background:#fff
      }
      .react-datepicker {
        font-family: 'Gilroy', sans-serif !important;
      }
      .react-datepicker__day--selected {
        background:#0097f5 !important;
        color:white !important;
        opacity:1 !important;
      }
      .react-datepicker__header  {
        border-bottom:1px solid #f3f3f3;

      }
      .react-datepicker__current-month {
        font-size:18px !important;
        color:#020147 !important;
      }
      .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
        padding-top:20px
      }
      .react-datepicker__day-name {
        color:#020147;
        font-weight:600;
        opacity:0.5
      }
      .react-datepicker__day--keyboard-selected {
        background:#f3f3f3 !important;
      }
      .react-datepicker__day--disabled {
        opacity:0.2
      }
      .react-datepicker__day--outside-month {
        opacity:0.5;
      }

      .react-datepicker__day {
        font-weight:500;
        color:#020147;
        font-size:14px;
      }
      .react-datepicker {
        border-radius:15px;
        overflow:hidden;
        padding-top:10px;
      }
      .react-datepicker--time-only {
        width:200px;
        border-radius:10px;
        height:400px;
        overflow:scroll;
      }
      .react-datepicker__time-container  {
        width:200px;
      }
      .react-datepicker__time-list {
        height:100% !important;
      }
      .react-datepicker__time-list-item--selected {
        background:#0097f5 !important;
        color:white !important;
        opacity:1 !important;        
      }
      .react-datepicker__time-list-item  {
        width:200px;
        font-weight:500;
        padding:10px 0 !important;
        height: auto !important;
        opacity:0.6=8;
      }
      .react-datepicker__time-box {
        width:200px !important;
        color:#020147 !important;
      }
      .react-datepicker-time__header {
        font-size:18px !important;
        color:#020147 !important;
      }

      #description-viewer .ql-editor {
        padding:0 !important;
      } 

    `}
  />
)







