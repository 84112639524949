import { Flex, Text, Button, SimpleGrid } from '@chakra-ui/react';
import { Header, EditSection, MainEditor, LoadingScreen } from "../includes";
import { Link, useParams } from "react-router-dom";

import { useQuestionnaireSectionUnits, useQuestionnaireSectionByID, useQuestionnaireByID } from "../hooks";

export default function SectionDetails() {
	const { id, lessonID, sectionID } = useParams();
	const { data } = useQuestionnaireByID(id);
	const { data: sectionData = [], isLoading } = useQuestionnaireSectionByID(sectionID);

	let isLive = data ? data.get('live') : true;

	return (
		<Flex direction="column" height={window.innerHeight}>
			<Header />
			<Flex p="12px 20px" borderBottom="1px solid" borderBottomColor="light">
				<Flex as={Link} to={`/questionnaires/${id}`} fontSize="12px" align="center" color="primary">
					<i className="fa-solid fa-arrow-left"></i>
					<Text pl="5px" variant="link">Back to Sections</Text>
				</Flex>
				<Text flex="1" textAlign="right" variant="subtitle" fontSize="10px">{id}</Text>
			</Flex>
			{
				isLoading || !sectionData ?
				<Flex flex="1" mt="-50px">
					<LoadingScreen text={isLoading ? "Loading section..." : !sectionData ? "No section found!" : null } />
				</Flex>
				:
				<Flex flex="1">
					<EditSection isLive={isLive} sectionData={sectionData} />
					<Flex flex='1' p="15px 20px 60px" direction="column" overflow="scroll" maxH={window.innerHeight - 140}>
						{
							lessonID && <MainEditor />
						}
					</Flex>
				</Flex>
			}	
		</Flex>
	)
}










