import {
	Flex,
	Box,
	Image,
	Text
} from "@chakra-ui/react";
import LogoIcon from "../../assets/logo.png";

export function LoadingScreen({ text }){
	return (
		<Flex direction="column" flex="1" align="center" justify="center">
			<Image
				src={LogoIcon}
				width="45px"
				h="45px"
				borderRadius="5px"
			/>
			<Text fontSize="15px" fontWeight="600" mt="15px">{
				text ? text : "Loading..."
			}</Text>
		</Flex>
	)
}