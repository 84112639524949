import { useState, useEffect } from "react";
import { Flex, Text, Button, SimpleGrid, Skeleton, Table, Thead, Tbody, Tr, Th, Td, Image } from '@chakra-ui/react';
import { useParams, Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Header, Segment, AddCategoryModal, AddVideoModal, VideoBox, EditVideoModal, CreateAlbumModal, AlbumBox, EditAlbumModal, AddMultiLanguage } from "../includes";
import { useCategories, useVideos, useVideoAlbums, useEditVideoOrder } from "../hooks";

export default function Videos() {
	const { id } = useParams();
	const [chosen, setChosen] = useState("All");
	const [addCategory, setAddCategory] = useState(false);
	const [addVideo, setAddVideo] = useState(false);
	const [addAlbum, setAddAlbum] = useState(false);
	const [editVideo, setEditVideo] = useState();
	const [editAlbum, setEditAlbum] = useState();
	const [addMultiLanguage, setAddMultiLanguage] = useState(false);
	const [album, setAlbum] = useState();

	const { data: categories } = useCategories('video');
	const { data: videos, isLoading } = useVideos(id);

	const { data: albums = [] } = useVideoAlbums();
	const { mutateAsync: updateOrder } = useEditVideoOrder();

	useEffect(() => {
		if (id && albums) {
			let album = albums.filter(e => e.id == id);
			if (album.length > 0) {
				setAlbum(album[0])
			}
		}
	},[id, albums])

	async function onDragEnd({ source, destination }){
		if (chosen == "All") {
		    let _videos = [...videos];
		    if (source && destination) {
		        const [removed] = _videos.splice(source.index, 1)
		        _videos.splice(destination.index, 0, removed);

		        updateOrder(_videos);
		    }
		}
	}

	async function onDragAlbumEnd({ source, destination }) {
		if (chosen == "All") {

		}
	}

	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px" pt="15px">
				<Flex align="center" justify="center" mb="20px">
					{
						!id ? (
							<>
								<Flex flex="1" direction="column">
									{
										categories && (
											<Segment 
												chosen={chosen}
												onChoose={setChosen}
												options={[{
													"title":"All"
												},
												...categories
												]}
											/>
										)
									}
								</Flex>
								<Button onClick={() => setAddCategory(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Edit categories</Button>
								<Button onClick={() => setAddMultiLanguage(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Create Multi Language</Button>
								<Button onClick={() => setAddAlbum(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Create album</Button>
							</>
						)
						: album ?
						<Flex flex="1" pt="20px" align="center">
							<Flex as={Link} to="/videos" w="30px" h="30px" mr="15px" bg="#f3f3f3" borderRadius="30px" align="center" justify="center">
								<i className="fa-solid fa-chevron-left"></i>
							</Flex>
							<Text variant="title">{album.title} Videos ({album.language})</Text>
						</Flex>
						:
						null
					}
					<Button onClick={() => setAddVideo(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Add video</Button>
				</Flex>
				{
					isLoading || !videos ?
					<Flex direction="column">
						<Skeleton height="50px" w="100%" opacity="0.5" mb="10px" />
						<Skeleton height="50px" w="100%" opacity="0.5" mb="10px" />
						<Skeleton height="50px" w="100%" opacity="0.5" mb="10px" />
						<Skeleton height="50px" w="100%" opacity="0.5" mb="10px" />
					</Flex>
					:
					<>
						{
							!id && (
								<>
									<Text mb="15px" fontSize="15px" variant="title">Albums</Text>
									<Flex w="100%" border="1px solid" mb="40px" maxH="410px" borderColor="light" borderRadius="10px" overflow="scroll">
										<Table variant='simple'>
											<Thead>
												<Tr>
													<Th w="40px" pl="0" pr="0"></Th>
													<Th w="50px"><Text variant="subtitle" textTransform="capitalize">Album</Text></Th>
													<Th w="200px"><Text variant="subtitle" textTransform="capitalize">Name</Text></Th>
													<Th><Text variant="subtitle" textTransform="capitalize">Total videos</Text></Th>
													<Th><Text variant="subtitle" textTransform="capitalize">Category</Text></Th>
													<Th><Text variant="subtitle" textTransform="capitalize">Languages</Text></Th>
													<Th isNumeric></Th>
												</Tr>
											</Thead>
											<DragDropContext onDragEnd={onDragAlbumEnd}>
												<Droppable droppableId="album-videos-wrapper">
													{(provided) => (
														<Tbody flexGrow="1" ref={provided.innerRef} {...provided.droppableProps} w="100%">
															{
																chosen != "All" ?
																albums.filter(e => e.category && e.category.id == chosen).map((album, i) => (
																	<AlbumRow key={album.id} index={i} data={album} onChoose={(e) => setEditAlbum(e)} />
																))
																:
																albums.map((album, i) => (
																	<AlbumRow key={album.id} index={i} data={album} onChoose={(e) => setEditAlbum(e)} />
																))
															}
															{ provided.placeholder }
														</Tbody>
													)}
												</Droppable>
											</DragDropContext>
										</Table>
									</Flex>
								</>
							)
						}
						{
							!id &&
							<Text mb="15px" fontSize="15px" variant="title">Other Videos</Text>
						}

						<Flex w="100%" border="1px solid" mb="40px" borderColor="light" borderRadius="10px">
							<Table variant='simple'>
								<Thead>
									<Tr>
										<Th w="40px" pl="0" pr="0"></Th>
										<Th w="50px"><Text variant="subtitle" textTransform="capitalize">Album</Text></Th>
										<Th w="300px"><Text variant="subtitle" textTransform="capitalize">Name</Text></Th>
										<Th w="250px"><Text variant="subtitle" textTransform="capitalize">Author</Text></Th>
										<Th><Text variant="subtitle" textTransform="capitalize">Category</Text></Th>
										<Th><Text variant="subtitle" textTransform="capitalize">Languages</Text></Th>
										<Th isNumeric></Th>
									</Tr>
								</Thead>

								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="videos-wrapper">
										{(provided) => (
											<Tbody flexGrow="1" ref={provided.innerRef} {...provided.droppableProps} w="100%">
												{
													chosen != "All" ?
													videos.filter(e => e.get('category') && e.get('category').id == chosen).map((video, index) => (
														<VideoRow onChoose={(e) => setEditVideo(e)} key={video.id} data={video} index={index} />
													))
													:
													videos.map((video, index) => (
														<VideoRow onChoose={(e) => setEditVideo(e)} key={video.id} data={video} index={index} />
													))									
												}
												{ provided.placeholder }
											</Tbody>
										)}
									</Droppable>
								</DragDropContext>


							</Table>
						</Flex>


					</>
				}
			</Flex>	
			{
				addCategory && <AddCategoryModal totalCategories={categories} type="video" onClose={() => setAddCategory(false)} />
			}
			{
				addAlbum && <CreateAlbumModal categories={categories} totalAlbums={albums} onClose={() => setAddAlbum(false)} />
			}
			{
				addVideo && <AddVideoModal chosenAlbum={album} albums={albums} chosenCategory={chosen} totalVideos={videos} onClose={() => setAddVideo(false)} />
			}
			{
				editVideo && <EditVideoModal albums={albums} video={editVideo} onClose={() => setEditVideo()} />
			}
			{
				editAlbum && <EditAlbumModal data={editAlbum} categories={categories} onClose={() => setEditAlbum()} />
			}
			{
				addMultiLanguage && <AddMultiLanguage totalVideos={videos} totalAlbums={albums} onClose={() => setAddMultiLanguage(false)} />
			}
		</Flex>
	)
}


function AlbumRow({ data, onChoose, index }){
	const [alb, setAlb] = useState(0);
	
	function handleLangAlb(e, index){
		e.stopPropagation();
		e.preventDefault();
		setAlb(index);
	}

	let album = data.albums ? data.albums[alb] : data;


	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Tr {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} w="100%" bg="white">
					<Td pt="10px" pb="10px" pl="0" pr="0" fontSize="13px" w="40px" color="lightText" fontWeight="500">
						<Flex cursor="drag" w="40px" h="30px" align="center" justify="center" borderRadius="30px">
							<div><i className="fa-solid fa-grip-vertical"></i></div>
						</Flex>
					</Td>
					<Td pt="10px" pb="10px" pr="0" fontSize="15px" fontWeight="500">
						{
							album.videos.length > 0 ?
							<Image 
								w="100%"
								h="35px"
								borderRadius="5px"
								objectFit='cover'
								objectPosition="center"
								alt="Section Box"
								src={album.videos[0].get('image')} />
							:
							<Flex flex='1' bg="light" color="lightText" align="center" justify="center" fontSize="15px" h="35px" borderRadius="5px">
								<div><i className="fa-solid fa-images"></i></div>
							</Flex>
						}
					</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="600" textTransform="capitalize">{album.title}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{album.videos.length} videos</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{album.category.get('fullTitle')['english']}</Td>
					{
						data.albums ? (
							<Td pt="10px" pb="10px" pr="10px">
								<Flex gap="2">
									{
										data.albums.map((album, index) => (
											<Button key={index} onClick={(e) => handleLangAlb(e, index)} variant={alb == index ? "primary" : "secondary"} flex="1" textTransform="capitalize">{album.language}</Button>
										))
									}
								</Flex>
							</Td>
						)
						:
						<Td pt="10px" pb="10px" pr="10px"></Td>
					}

					<Td pt="10px" pb="10px" isNumeric pr="10px">
						<Flex direction="row" align="center" justify="flex-end">
							<Button onClick={() => onChoose(album)} variant="white">Edit</Button>
							<Button as={Link} to={`/videos/albums/${album.id}`} onClick={(e) => e.stopPropagation()} variant="secondary" p="10px" ml="10px">View videos</Button>
						</Flex>
					</Td>					
				</Tr>
			)}
		</Draggable>
	)
}


function VideoRow({ data, onChoose, index }){
	const [vid, setVid] = useState(0);

	function handleLangVid(e, index){
		e.stopPropagation();
		e.preventDefault();
		setVid(index);
	}

	let video = data.videos ? data.videos[vid] : data;

	if (!video.get) {
		return;
	}

	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Tr {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} w="100%" bg="white">
					<Td pt="10px" pb="10px" pl="0" pr="0" fontSize="13px" w="40px" color="lightText" fontWeight="500">
						<Flex cursor="drag" w="40px" h="30px" align="center" justify="center" borderRadius="30px">
							<div><i className="fa-solid fa-grip-vertical"></i></div>
						</Flex>
					</Td>

					<Td pt="10px" pb="10px" pr="0" fontSize="15px" fontWeight="500">
						{
							video.get('image') ?
							<Image 
								w="100%"
								h="35px"
								borderRadius="5px"
								objectFit='cover'
								objectPosition="center"
								alt="Section Box"
								src={video.get('image')} />
							:
							<Flex flex='1' bg="light" color="lightText" align="center" justify="center" fontSize="15px" h="35px" borderRadius="5px">
								<div><i className="fa-solid fa-image"></i></div>
							</Flex>
						}
					</Td>
					<Td key={video.id + "_title"} pt="10px" pb="10px" fontSize="14px" fontWeight="600" textTransform="capitalize">{video.get('title')}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{video.get('author')}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{video.get('category') && video.get('category').get && video.get('category').get('fullTitle') ? video.get('category').get('fullTitle')['english'] : ""}</Td>
					{
						data.videos ? (
							<Td pt="10px" pb="10px" pr="10px">
								<Flex gap="2">
									{
										data.videos.map((video, index) => (
											<Button key={index} onClick={(e) => handleLangVid(e, index)} variant={vid == index ? "primary" : "secondary"} flex="1" textTransform="capitalize">{video.get('language')}</Button>
										))
									}
								</Flex>
							</Td>
						)
						:
						<Td pt="10px" pb="10px" pr="10px"></Td>
					}

					<Td pt="10px" pb="10px" isNumeric pr="10px">
						<Flex direction="row" align="center" justify="flex-end">
							<Button onClick={() => onChoose(video)} variant="white">Edit</Button>
						</Flex>
					</Td>					
				</Tr>
			)}
		</Draggable>
	)
}




