import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
} from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { isEmpty } from "../../utils";
import { useAddCategory, useCategories } from "../../hooks";
import { TranslatePopover } from "../popover";

export function AddCategoryModal({ totalCategories, type, onClose }) {
	const { mutateAsync } = useAddCategory();
	const { data } = useCategories("video");
	const toast = useToast();
	const languges = ['English', 'Arabic', 'Persian'];

	const [categories, setCategories] = useState(data || [])
	const [language, setLanguage] = useState("english");

	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");

	async function handleSubmit(){
		setLoading(true);
		try {
			let data = await mutateAsync({
				categories: reorderCategories(categories),
				type
			});
			onClose();
			toast({
				status:"success",
				title:"Video categories have been updated!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	function addNewCategory(){
		let _categories = [...categories];
		_categories.push({
			localId: nanoid(),
			type: type,
			order: categories.length,
			fullTitle:{
				"english": title
			}
		})
		setTitle("");
		setCategories(_categories);
	}

	async function handleTranslations(cat) {
		setCategories(cat);
	}

	async function onChange(id, value) {
		let _categories = [...categories];
		let index = _categories.findIndex(e => e.localId || e.id == id);
		if (index >= 0) {
			_categories[index].fullTitle[language] = value;
			setCategories(_categories)
		}
	}

	async function onDelete(id) {
		let _categories = [...categories];
		let index = _categories.findIndex(e => e.localId || e.id == id);
		if (index >= 0) {
			if (_categories[index].id) {
				_categories[index].deleted = true;
			} else {
				_categories.splice(index, 1);
			}
			setCategories(_categories)
		}
	}

	async function onDragEnd({ source, destination }){
	    let _categories = [...categories];
	    _categories = _categories.filter(e => e.deleted != true);
	    if (source && destination) {
	        const [removed] = _categories.splice(source.index, 1)
	        _categories.splice(destination.index, 0, removed)

	        let deletedCats = categories.filter(e => e.deleted == true);
	        	
	        setCategories([..._categories, ...deletedCats])
	    }
	}

	function reorderCategories() {
		let _categories = [...categories];
		_categories.map((cat, index) => {
			cat.order = index;
		})
		return _categories;
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Flex mb="10px" mr="20px">
						<Text flex="1" variant="title" fontSize="21px">Categories</Text>
						<TranslatePopover 
							categories={categories}
							onChange={handleTranslations}
						/>
						<Flex ml="10px"></Flex>
						{
							languges.map(lang => (
								<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
									<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
								</Flex>
							))
						}
					</Flex>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="categories-modal-wrapper">
							{(provided) => (
								<Flex direction="column" maxH="450px" overflow="scroll" ref={provided.innerRef} {...provided.droppableProps}>
									{
										categories.filter(e => e.deleted != true).map((category, index) => (
											<Draggable draggableId={category.id} index={index}>
												{(provided2) => (
													<Flex key={category.id} bg="white" mb="10px" mt="10px" align="center" justify="center" {...provided2.dragHandleProps} {...provided2.draggableProps} ref={provided2.innerRef}>
														<Text variant="subtitle" mr="10px">{index + 1}.</Text>
														<Input
															type="text"
															placeholder="Set a great title"
															value={category.fullTitle[language] || ""}
															onChange={(e) => onChange(category.id || category.localId, e.target.value)}
															fontWeight="500"
															h="50px"
															p="10px"
															flex="1"
															_focus={{
																borderColor:"primary",
																borderWidth:"2px"
															}}
														/>
														<Flex onClick={() => onDelete(category.id || category.localId )} w="30px" h="30px" cursor="pointer" color="gray" bg="#f3f3f3" borderRadius="30px" ml="10px" align="center" justify="center">
															<i className="fa-solid fa-circle-xmark"></i>
														</Flex>						
													</Flex>													
												)}
											</Draggable>
										))
									}
								</Flex>
							)}
						</Droppable>
					</DragDropContext>
					<Flex direction="column" mb="20px" borderTop="1px solid #f3f3f3" pt="25px">
						<Text variant="subtitle">Add category</Text>
						<Flex mt="7px" align="center" justify="center">
							<Input
								type="text"
								placeholder="Set a great category title"
								fontWeight="500"
								h="50px"
								p="10px"
								mr="10px"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:"2px"
								}}
							/>
							<Button variant="success" onClick={addNewCategory} isDisabled={isEmpty(title)} p="15px">Create</Button>
						</Flex>
					</Flex>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="0px">
						<Flex as={Button} variant="primary" isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Save categories</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function validate({ title }) {
	if (isEmpty(title)) {
		return true
	}

	return false;
}







