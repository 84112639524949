import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useCategories = (type) => {
	return useQuery(['categories', type],
		async () => {
			const query = new Parse.Query("Categories");
			query.equalTo('type', type);

			let data = await query.find();

			data.sort((a, b) => a.get('order') - b.get('order'))
			return data.map(category => {
				return {
					id:category.id,
					order: category.get('order'),
					type: category.get('type'),
					title: category.get('fullTitle') ? category.get('fullTitle')['english'] : category.get('title'),
					fullTitle: category.get('fullTitle') || {
						"english": category.get('title')
					}
				}
			})
		},
		{}
	)
}

export const useAddCategory = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		await Promise.all(data.categories.map(async (cat) => {
			if (cat.localId) {
				const Categories = Parse.Object.extend("Categories");
				const new_category = new Categories();

				new_category.set("type", cat.type);
				new_category.set("title", cat.fullTitle['english']);
				new_category.set("fullTitle", cat.fullTitle);
				new_category.set("order", cat.order);

				await new_category.save();

			} else {
				const query = new Parse.Query("Categories");
				let data = await query.get(cat.id);
				if (cat.deleted == true) {
					// remove all videos/albums from this category 

					const videoAlbumsQuery = new Parse.Query("VideoAlbums");
					videoAlbumsQuery.equalTo('category', data);
					videoAlbumsQuery.limit(1000);
					let _albums = await videoAlbumsQuery.find();

					await Promise.all(_albums.map(async album => {
						album.set('category', null);
						await album.save();
					}))

					const videosQuery = new Parse.Query("Videos");
					videosQuery.equalTo('category', data);
					videosQuery.limit(1000);
					let _videos = await videosQuery.find();

					await Promise.all(_videos.map(async video => {
						video.set('category', null);
						await video.save();
					}))

					await data.destroy();
				} else {
					data.set('fullTitle', cat.fullTitle);
					data.set('order', cat.order);
					await data.save();
				}
			}
		}))

		queryClient.refetchQueries(['categories', data.type]);
	}, {
		...options,
	})
}



