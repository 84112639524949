import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useAddMultiLanguageGroup } from "../../hooks";

export function AddMultiLanguage({ onClose, totalVideos, totalAlbums }) {
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState('Videos');
	const { mutateAsync } = useAddMultiLanguageGroup();
	const [languageGroup, setLanguageGroup] = useState({});
	const [live, setLive] = useState(true);

	async function onSelect(lang, videoId){
		let _languageGroup = { ...languageGroup };
		_languageGroup[lang] = videoId;
		setLanguageGroup(_languageGroup);
	}

	async function handleType(type) {
		setLanguageGroup({})
		setType(type);
	}

	async function handleSubmit(){
		setLoading(true);
			
		await mutateAsync({
			live,
			type,
			languageGroup
		})
		onClose();

		setLoading(false);
	}

	if (!totalVideos) {
		return;
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px">Create Language Group</Text>
					<Text variant="subtitle" fontSize="15px" mb="10px">Select each video for each language</Text>
					
					<Flex gap="2" w="100%" mt="10px" borderBottom="1px solid #f3f3f3" pb="20px">
						<Button onClick={() => handleType('Videos')} flex="1" pt="11px" pb="11px" fontSize="14px" variant={type == "Videos" ? "success" : "secondary"}>Videos</Button>
						<Button onClick={() => handleType('Albums')} flex="1" pt="11px" pb="11px" fontSize="14px" variant={type == "Albums" ? "success" : "secondary"}>Albums</Button>
					</Flex>

					<Flex direction="column" mt="20px">
						<LanguageRow language="english" type={type} totalAlbums={totalAlbums} totalVideos={totalVideos} languageGroup={languageGroup} onSelect={onSelect} />
						<LanguageRow language="arabic" type={type} totalAlbums={totalAlbums} totalVideos={totalVideos} languageGroup={languageGroup} onSelect={onSelect} />
						<LanguageRow language="persian" type={type} totalAlbums={totalAlbums} totalVideos={totalVideos} languageGroup={languageGroup} onSelect={onSelect} />
					</Flex>
					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Live</Text>
						<Switch isChecked={live} onChange={(e) => setLive(e.target.checked)} colorScheme="green" size='md' />
					</Flex>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="30px">
						<Flex as={Button} variant="primary" isLoading={loading} isDisabled={Object.keys(languageGroup).length <= 1} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Save multi language</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function LanguageRow({ language, totalVideos, languageGroup, onSelect, type, totalAlbums }){
	return (
		<Flex direction="row" h="50px" align="center" justify="center" mb="10px">
			<Text variant="subtitle" fontSize="15px" textTransform="capitalize" flex="1">{language}</Text>
			<Select value={languageGroup[language] || ""} onChange={(e) => onSelect(language, e.target.value)} h="50px" flex="1" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select'>
				{
					type == "Videos" ?
					totalVideos.filter(e => e.get('language') == language && !e.get('MultiLanguageGroup')).map(video => (
						<option key={video.id} value={video.id}>{video.get('title')}</option>
					))
					:
					totalAlbums.filter(e => e.language == language && !e.MultiLanguageGroup).map(album => (
						<option key={album.id} value={album.id}>{album.title}</option>
					))	
				}
			</Select>	
		</Flex>		
	)
}






