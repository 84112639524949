import { useState, useEffect } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Image
} from "@chakra-ui/react";
import { isEmpty, getYoutubeID, isURL } from "../../utils";

import { useAddArtist } from "../../hooks";

export function CreateArtist({ onClose, artists }) {
	const toast = useToast();
	const { mutateAsync: onAddArtist } = useAddArtist();
	const [loading, setLoading] = useState(false);
	const [videoUrl, setVideoURL] = useState("");
	const [videoChannelData, setVideoChannelData] = useState();
	const [videoYoutubeData, setVideoYoutubeData] = useState();
	const [title, setTitle] = useState({})
	const [channelImage, setChannelImage] = useState();
	const [language, setLanguage] = useState("english");
	const languges = ['English', 'Arabic', 'Persian'];

	useEffect(() => {
		if (isURL(videoUrl) && !videoYoutubeData) {
			fetchData()
		}
	},[videoUrl])

	async function fetchData(){
		setLoading(true);
		let youtubeID = getYoutubeID(videoUrl);
		if (youtubeID) {
			try {
				let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
				let data = await response.json();

				if (data && data.items.length > 0) {
					let { snippet } = data.items[0];

					let response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${snippet.channelId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
					let channelData = await response2.json();

					let { snippet: channelSnippet } = channelData.items[0];

					let artistIndex = artists.findIndex(e => e.get('youtubeChannelID') == snippet.channelId);
					if (artistIndex >= 0) {
						toast({
							status:"error",
							title:"Artist already exists",
							position: 'top',
						})	
						return;
					}

					setTitle({
						"english":channelSnippet.title,
						"arabic":"",
						"persian":""
					});
					setChannelImage(channelSnippet.thumbnails.high.url)
					setVideoChannelData(channelSnippet)
					setVideoYoutubeData(snippet);
				}
			}
			catch(error) {
				toast({
					status:"error",
					title:error.message,
					position: 'top',
				})				
			}
		} else {
			toast({
				status:"error",
				title:"We could not find the youtube id from the url",
				position: "top"
			})
		}
		setLoading(false);
	}

	function handleChannelTitle(value) {
		let _title = {...title};
		_title[language] = value;
		setTitle(_title)
	}

	async function handleSubmit(){
		setLoading(true);
		try {
			await onAddArtist({
				artist:{
					title,
					image: channelImage,
					order: artists.length,
					channelId: videoYoutubeData.channelId,
					publishedAt: videoChannelData.publishedAt,
					customUrl: videoChannelData.customUrl
				}
			})
			onClose();
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Create artist</Text>

					{
						videoChannelData && videoYoutubeData ?
						<Flex align="center">
							<Image
								src={channelImage}
								w="80px"
								h="80px"
								borderRadius="130px"
								objectFit="cover"
							/>
							<Flex pl="10px" flex="1" direction="column">
								<Flex mb="10px">
									{
										languges.map(lang => (
											<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
												<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="11px">{lang}</Text>
											</Flex>
										))
									}
								</Flex>
								<Input
									type="text"
									placeholder="Artists Name"
									fontWeight="500"
									h="55px"
									p="10px"
									fontSize="15px"
									value={title[language]}
									onChange={(e) => handleChannelTitle(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>	
								<Input
									type="text"
									placeholder="Artists Image"
									fontWeight="500"
									h="55px"
									p="10px"
									mt="10px"
									fontSize="15px"
									value={channelImage}
									onChange={(e) => setChannelImage(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>	

							</Flex>
						</Flex>
						:
						<Flex mb="20px">
							<Flex direction="column" flex="1">
								<Text variant="subtitle">Channel ID</Text>
								<Input
									type="text"
									placeholder="Youtube URL"
									mt="10px"
									fontWeight="500"
									h="50px"
									p="10px"
									value={videoUrl}
									onChange={(e) => setVideoURL(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>
						</Flex>
					}

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary"  isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create artist</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}








