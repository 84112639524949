import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { nanoid } from 'nanoid'

export const useQuranPersons = () => {
	return useQuery(['quran-person'],
		async () => {
			const query = new Parse.Query("QuranPersons");
			query.include('createdBy')
			query.descending('updatedAt')

			let data = await query.find();
			// data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useAddQuranPerson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("QuranPersons");
		query.equalTo('title.english', data.name['english']);
		const check = await query.first();

		if (check) {
			throw new Error('Person already exists')
			return;
		}

		const QuranPersons = Parse.Object.extend("QuranPersons");
		const new_person = new QuranPersons();

		let result = await Parse.Cloud.run('upload-image', {
			base64: data.image.base64,
			type: data.image.type,
			path: "quran-persons"
		});

		new_person.set("image", result);
		new_person.set('title', data.name);
		new_person.set('titleEn2', data.name['english'].toString().toLowerCase());
		new_person.set('titleAr2', data.name['arabic']);
		new_person.set('titlePer2', data.name['persian']);
		new_person.set('type', data.type);
		new_person.set('totalKhatmat', data.totalKhatmat);
		new_person.set('createdBy', {
			"objectId":"sNGIqAlLph",
			"className":"_User",
			"__type":"Pointer"
		});
		new_person.set('status', data.status);
		new_person.set('order', data.sort);
		new_person.set('featured', data.featured);

		const person = await new_person.save();

		await Promise.all(Array.from(Array(data.totalKhatmat).keys()).map(async e => {
			const QuranKhatmat = Parse.Object.extend("QuranKhatmat");
			const khatmat = new QuranKhatmat();

			khatmat.set("person", {
				"__type":"Pointer",
				"objectId":person.id,
				"className":"QuranPersons"
			});
			khatmat.set("type", data.type);
			await khatmat.save();
		}))
		
		queryClient.refetchQueries(['quran-person']);

		return person;	
	}, {
		...options,
	})
}

export const useEditQuranPerson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("QuranPersons");
		let old_person  = await query.get(data.id)

		old_person.set('title', data.name);
		if (data.image.base64) {
			let result = await Parse.Cloud.run('upload-image', {
				base64: data.image.base64,
				type: data.image.type,
				path: "quran-persons"
			});	
			old_person.set('image', result);
		}
		old_person.set('status', data.status);
		old_person.set('featured', data.featured);

		old_person.set('titleEn2', data.name['english'].toString().toLowerCase());
		old_person.set('titleAr2', data.name['arabic']);
		old_person.set('titlePer2', data.name['persian']);

		const query2 = new Parse.Query("QuranKhatmat");
		query2.equalTo('person', {
			"__type":"Pointer",
			"objectId": data.id,
			"className":"QuranPersons"
		})
		let all_khatmat  = await query2.find();

		if (all_khatmat.length == 0) {
			await Promise.all(Array.from(Array(data.totalKhatmat).keys()).map(async e => {
				const QuranKhatmat = Parse.Object.extend("QuranKhatmat");
				const khatmat = new QuranKhatmat();

				khatmat.set("person", {
					"__type":"Pointer",
					"objectId":data.id,
					"className":"QuranPersons"
				});
				khatmat.set("type", data.type);

				await khatmat.save();
			}))
		}

		if (data.status != "live") {
			old_person.set('type', data.type);
			old_person.set('totalKhatmat', data.totalKhatmat);
		}

		await old_person.save();
		queryClient.refetchQueries(['quran-person']);
	}, {
		...options,
	})
}

export const useDeleteQuranPerson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("QuranPersons");
		let old_person  = await query.get(data.id)

		const query2 = new Parse.Query("QuranKhatmat");
		query2.equalTo('person', old_person)
		let all_khatmat  = await query2.find();	
		
		await Promise.all(all_khatmat.map(async khat => {
			await khat.destroy();
		}))


		const query3 = new Parse.Query("QuranPersonMapping");
		query3.equalTo('person', old_person)
		let all_khatmatMap  = await query3.find();	
		
		await Promise.all(all_khatmatMap.map(async khat => {
			await khat.destroy();
		}))

		await old_person.destroy();

		queryClient.refetchQueries(['quran-person']);
	}, {
		...options,
	})
}


export const useEditQuranPersonOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		await Promise.all(data.map(async (person, index) => {
			person.set('order', index);
			await person.save();
		}));
		
		queryClient.refetchQueries(['quran-person']);
	}, {
		...options,
	})
}




