import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Tooltip } from "@chakra-ui/react";
import { isEmpty } from "../../utils";

export function TranslatePopover({ title, description, categories, onChange }) {

	async function onTranslate(lang) {
		if (categories) {
			let _categories = [...categories];

			await Promise.all(_categories.map(async cat => {
				let titleTrans = await translateGoogle(cat.fullTitle["english"], lang);
				cat.fullTitle[lang.toLowerCase()] = titleTrans;
			}))

			onChange(_categories);
			return;
		}

		if (lang == "All") {
			await Promise.all(['Arabic', 'Persian'].map(async language => {
				let titleTrans = await translateGoogle(title, language);
				let descriptionTrans;
				if (description) {
					descriptionTrans = await translateGoogle(description, language);
				}
				onChange({
					titleTrans,
					descriptionTrans,
					lang: language.toLowerCase()
				})
			}))
		} else {
			let titleTrans = await translateGoogle(title, lang);
			let descriptionTrans;
			if (description) {
				descriptionTrans = await translateGoogle(description, lang);
			}
			onChange({
				titleTrans,
				descriptionTrans,
				lang: lang.toLowerCase()
			})
		}
	}

	async function translateGoogle(text, lang) {
		let target = lang == "Arabic" ? "ar" : lang == "Persian" ? "fa" : null;

		if (!isEmpty(text) && !isEmpty(target)) {
			const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_YOUTUBE_API_KEY}`, {
				method: 'POST',
				body:JSON.stringify({
					q: text,
					target
				})
			})
			let { data } = await response.json();
			if (data && data.translations && data.translations.length > 0) {
				return data.translations[0].translatedText;
			} else {
				return text;
			}
		}

		return text;
	}

	return (
		<Menu>
			<Tooltip label='Translate' placement="top" hasArrow>
				<MenuButton>
					<Flex w="30px" h="30px" borderRadius="30px" bg="light" fontSize="15px" align="center" justify="center" cursor="pointer" color="lightText">
						<i className="fa-solid fa-language"></i>
					</Flex>
				</MenuButton>
			</Tooltip>
			<MenuList>
				<Text variant="subtitle" p="0 10px" fontSize="10px">Translations</Text>
				<MenuDivider />
{/*				<MenuItem p="7px 10px" onClick={() => onTranslate('All')}>
					<Text variant="subtitle" fontSize="14px">All</Text>
				</MenuItem>*/}
				<MenuItem p="7px 10px" onClick={() => onTranslate('Arabic')}>
					<Text variant="subtitle" fontSize="14px">Arabic</Text>
				</MenuItem>
				<MenuItem p="7px 10px" onClick={() => onTranslate('Persian')}>
					<Text variant="subtitle" fontSize="14px">Persian</Text>
				</MenuItem>
			</MenuList>
		</Menu>
	)
}

