import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useAddVideoAlbum, useCategories } from "../../hooks";

export function CreateAlbumModal({ totalAlbums, categories, onClose }) {
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [category, setCategory] = useState("");
	const [language, setLanguage] = useState("");

	const { mutateAsync } = useAddVideoAlbum();

	async function handleSubmit(){
		setLoading(true);
		try {
			let data = await mutateAsync({
				title,
				category,
				language,
				order: totalAlbums.length
			});
			onClose();
			toast({
				status:"success",
				title:"Video Album created!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Create album</Text>

					<Flex direction="column" mb="20px">
						<Text variant="subtitle">Title</Text>
						<Input
							type="text"
							placeholder="Set a great title"
							mt="10px"
							fontWeight="500"
							h="50px"
							p="10px"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>
					</Flex>

					<Flex direction="column" mb="20px">
						<Flex>
							<Text variant="subtitle">Language</Text>
							<Text ml="2px" color="error">*</Text>
						</Flex>
						<Select value={language} onChange={(e) => setLanguage(e.target.value)} w="100%" mt="10px" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select language'>
							<option value="english">English</option>
							<option value="arabic">Arabic</option>
							<option value="persian">Persian</option>
							<option value="mixed">Mixed</option>
						</Select>						
					</Flex>

					<Flex direction="column" mb="20px">
						<Flex>
							<Text variant="subtitle">Category</Text>
							<Text ml="2px" color="error">*</Text>
						</Flex>
						
						<Select value={category} onChange={(e) => setCategory(e.target.value)} w="100%" mt="10px" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select category'>
							{
								categories.map((category, index) => (
									<option key={index} value={category.id}>{category.title}</option>
								))
							}
						</Select>
					</Flex>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isDisabled={validate({ title, category, language })} isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create album</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function validate({ title, category, language }) {
	if (isEmpty(title)) {
		return true
	}
	if (isEmpty(category)) {
		return true;
	}
	if (isEmpty(language)) {
		return true;
	}


	return false;
}







