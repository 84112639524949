import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useAddMultiLanguageGroup = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { live, languageGroup, type } = data;

		const MultiLanguageGroups = Parse.Object.extend("MultiLanguageGroups");
		const mulit_language_videos = new MultiLanguageGroups();

		mulit_language_videos.set('live', live);
		mulit_language_videos.set('type', type.toLowerCase());
		let _saved_multi = await mulit_language_videos.save();
		
		await Promise.all(Object.keys(languageGroup).map(async key => {
			if (type == "Videos") {
				const query = new Parse.Query("Videos");
				query.equalTo('objectId', languageGroup[key]);

				let getVideo  = await query.first();
				if (getVideo) {
					getVideo.set('MultiLanguageGroup', _saved_multi);
					await getVideo.save();
				}
			} else {
				const query = new Parse.Query("VideoAlbums");
				query.equalTo('objectId', languageGroup[key]);

				let getAlbum  = await query.first();
				if (getAlbum) {
					getAlbum.set('MultiLanguageGroup', _saved_multi);
					await getAlbum.save();
				}
			}
		}))

		queryClient.refetchQueries(['videos', 'video-albums']);

		return "done";	
	}, {
		...options,
	})
}