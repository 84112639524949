export function isEmpty(text) {
  try {
    if (text) {
      if (text.toString().trim().replace(/ /g, '').replace(/\n/g, '') === '') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } catch (error) {
    return true
  }
}

export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function comma(x) {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
}

export function isEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}


export function isURL(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function getYoutubeID(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

export function getYoutubePlaylistID(url){
    var regPlaylist = /[?&]list=([^#\&\?]+)/;
    var match = regPlaylist.exec(url);

    return match[1];
}

 export const imageExists = (url) =>
  new Promise((resolve) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });