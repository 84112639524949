import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Tooltip } from "@chakra-ui/react";
import { isEmpty } from "../../../../utils";

export function ExcelPopover({ questions, title }) {
	function handleDownload(lang){
		let data = [];

		questions.map(ques => {
			if (!isEmpty(ques.title[lang]) && ques.answer && ques.options.length >= 4) {
				let row = {
					title: ques.title[lang],
					answerStart:"",
					answerEnd:""
				}

				ques.options.map((opt, index) => {
					row['choice' + (index + 1)] = opt.title[lang]
				});

				let chosenAnswer = ques.options.filter(e => e.id == ques.answer)[0];
				let endText = chosenAnswer['endingAudioText'] ? chosenAnswer['endingAudioText'][lang] : "";

				if (lang == "english") {
					row.answerStart = "Answer is";
				}
				if (lang == "arabic") {
					row.answerStart = "الجواب هو"
				}
				if (lang == "persian") {
					row.answerStart = "پاسخ این است"
				}

				row.answer = chosenAnswer.title[lang];

				if (!isEmpty(endText)) {
					row.answerEnd = endText
				}

				row.source = ques.source && ques.source[lang] ? ques.source[lang] : "";
				data.push(row);
			}
		})

		let csv = JsonToCSV(data);
		downloadCSV(csv, lang)
	}

	function JsonToCSV(data) {
		let csvStr = [];
		let csvStr2 = [];
		Object.keys(data[0]).map(title => {
			csvStr.push(title)
			csvStr2.push(title)
		})

	    csvStr = csvStr.join(",") + "\n";
	    data.forEach((element) => {
	    	csvStr2.map((a, index) => {
	    		csvStr += element[a].replaceAll(',', '‚')
	    		if (index == csvStr2.length - 1) {
	    			csvStr += "\n"
	    		} else {
	    			csvStr += ","
	    		}
	    	})
	    })
	    return csvStr;
	}

	function downloadCSV(csvStr, lang) {
	    let hiddenElement = document.createElement('a');
	    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
	    hiddenElement.target = '_blank';
	    hiddenElement.download = `${title.replace(/ /g, '-').toLowerCase()}-${lang.slice(0, 2).toLowerCase()}.csv`;
	    hiddenElement.click();
	}

	return (
		<Menu>
			<Tooltip label='Export CSV' placement="top" hasArrow>
				<MenuButton>
					<Flex w="30px" h="30px" borderRadius="30px" bg="light" fontSize="17px" mr="10px" align="center" justify="center" cursor="pointer" color="lightText">
						<i className="fa-solid fa-file-csv"></i>
					</Flex>
				</MenuButton>
			</Tooltip>
			<MenuList>
				<Text variant="subtitle" p="0 10px" fontSize="10px">TikTok Videos</Text>
				<MenuDivider />
				<MenuItem p="10px" onClick={() => handleDownload('english')}>
					<Text variant="subtitle" fontSize="14px">English .csv</Text>
				</MenuItem>
				<MenuItem p="10px" onClick={() => handleDownload('arabic')}>
					<Text variant="subtitle" fontSize="14px">Arabic .csv</Text>
				</MenuItem>
				<MenuItem p="10px" onClick={() => handleDownload('persian')}>
					<Text variant="subtitle" fontSize="14px">Persian .csv</Text>
				</MenuItem>
			</MenuList>
		</Menu>
	)
}





