import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Tooltip, Textarea, Button } from "@chakra-ui/react";
import { useState } from "react";
import { isEmpty } from "../../../../utils";
import { nanoid } from "nanoid";

export function TextUpload({ lessonID, questions, setQuestions }){
	const [text, setText] = useState('');

	function handleText(){
		let new_questions = [];

		let _questions = text.split(']\n')
		if (_questions.length > 0) {
			_questions.map(question => {
				let new_question = {
		            "id":nanoid(),
		            "lessonID":lessonID,
		            "title":{
		                "english":"",
		                "arabic":"",
		                "persian":""
		            },
		            "options":[],
		            "audio":{
		                "start":{
		                    "english":"",
		                    "arabic":"",
		                    "persian":""
		                },
		                "helpText":{
		                    "english":"",
		                    "arabic":"",
		                    "persian":""
		                },
		                "end":{
		                    "english":"",
		                    "arabic":"",
		                    "persian":""
		                }
		            },
		            "helpText":{
		                "english":"",
		                "arabic":"",
		                "persian":""
		            },
		            "type":"radio",
		            "answer":"",
		            "source":{
		            	"english":"General Knowledge"
		            }
		        }

				let items = question.trim().split('\n');
				if (items.length >= 5) {
					let title = items[0];
					if (isEmpty(title) == false) {
						title = title.split('.');
						title = title[1];
						if (isEmpty(title) == false) {
							new_question.title['english'] = title.trim();
						}
					}

					let choices = [items[1].trim(), items[2].trim(), items[3].trim(), items[4].trim()]
					let answer = items[5].trim();

					if (isEmpty(answer)) {
						answer = items[6].trim();
					}
					choices.map(choice => {
						if (isEmpty(choice) == false) {
							let new_choice = choice.split(') ');
							if (new_choice.length == 1) {
								new_choice = new_choice[0].trim()
							} else {
								new_choice = new_choice[1].trim();
							}
							if(isEmpty(new_choice) == false) {
								new_question.options.push({
						            "id":nanoid(),
						            "title":{
						                "english":new_choice.trim(),
						                "arabic":"",
						                "persian":""
						            },
						            "endingAudioText":{
						                "english":"",
						                "arabic":"",
						                "persian":""
						            },
						            "canTranslate":{
						                "english":true,
						                "arabic":true,
						                "persian":true,
						            }									
								})
							}
						}
					})

					answer = answer.split('Answer: ');
					answer = answer[1];
					if (isEmpty(answer) == false) {
						answer = answer.split(') ');
						if (answer.length == 1) {
							answer = answer[0]
						} else {
							answer = answer[1];
						}

						if (isEmpty(answer) == false) {
							let answerID = new_question.options.findIndex(e => e.title['english'].toLowerCase() == answer.toLowerCase())
							if (answerID >= 0) {
								new_question.answer = new_question.options[answerID].id;
							}
						}
					}

					if (isEmpty(new_question.title['english']) == false && isEmpty(new_question.answer) == false && new_question.options.length > 0) {
						new_questions.push(new_question);
					}
				}
			})

			if (new_questions.length > 0) {
				let _new_questions = questions.concat(new_questions);
				setQuestions(_new_questions);
				setText('');
			}
		}

	}

	return (
		<Menu>
			<Tooltip label='Add questions from text' placement="top" hasArrow>
				<MenuButton>
					<Flex w="30px" h="30px" borderRadius="30px" bg="light" fontSize="17px" mr="10px" align="center" justify="center" cursor="pointer" color="lightText">
						<i className="fa-solid fa-clipboard-list"></i>
					</Flex>
				</MenuButton>
			</Tooltip>
			<MenuList>
				<Flex direction="column" p="5px 10px" w="350px">
					<Textarea 
						h="300px" 
						resize="none" 
						p="10px" 
						_focus={{
							borderColor:"primary",
							borderWidth:2
						}}
						value={text}
						onChange={(e) => setText(e.target.value)}
						placeholder="Add questions from text"
						fontWeight="500"
						fontSize="13px"
						mb="15px">
					</Textarea>
					<Button onClick={handleText} isDisabled={isEmpty(text)} variant="primary" fontSize="14px" p="12px" pt="15px">Create Questions</Button>
				</Flex>
			</MenuList>
		</Menu>
	)
}