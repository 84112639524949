import { useEffect, useState } from "react";
import { Flex, Text, Input, Switch, Box, Button, useToast, Spinner, Tooltip } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { QuestionBox } from "./index";
import { useQuestionnaire } from "../../../contexts/questionnaire";
import { useQuestionnaireLessonByID, useDeleteQuestionnaireLesson, useQuestionnaireQuestions, useGenerateAudioLesson } from "../../../../hooks";
import { LoadingScreen } from "../../../../includes";
import { isEmpty } from "../../../../utils";
import { TextUpload, TranslatePopover, ExcelPopover } from "./index";
import { TranslatePopover as TitleTranslatePopover } from "../../../popover";
import Parse from 'parse';

export function MainEditor(){
	const { id, sectionID, lessonID } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const user = Parse.User.current();

	const { questions, chosenQuestion, addQuestion, onDragEnd, title, setFullTitle, handleTranslation, fullTitle, handleTextChange, setTitle, handleSave, loading, setLoading, setQuestions, convertQuestion } = useQuestionnaire(lessonID);

	const [language, setLanguage] = useState('english');
	const { data: lesson, isLoading } = useQuestionnaireLessonByID(lessonID, {
		onSuccess: (data) => {
			setTitle(data.get('title'))
			setFullTitle(data.get('fullTitle') || {
		        "english":data.get('title'),
		        "arabic":"",
		        "persian":""
		    })
		}
	});
	const { mutateAsync: createLessonAudio } = useGenerateAudioLesson();
	const { data: questionData } = useQuestionnaireQuestions(lessonID, {
		refetchOnWindowFocus:false
	});
	const { mutateAsync: deleteLesson } = useDeleteQuestionnaireLesson();

	useEffect(() => {
		if (user && user.get('language')) {
			setLanguage(user.get('language'))
		}
	},[user])


	useEffect(() => {
		if (questionData) {
			setLoading(true);
			if (questionData.length > 0) {
				let _questions = [];
				questionData.map(question => {
					_questions.push(convertQuestion(question))
				});

				_questions.sort((a, b) => parseInt(a.order) - parseInt(b.order))

				setQuestions(_questions)
			} else if (questionData.length == 0) {
				addQuestion(lessonID, [])
			}
			setLoading(false);
		}
	},[questionData, lessonID])

	async function handleDelete(){
		setLoading("Deleting lesson...");
		try {
			await deleteLesson({
				lessonID: lesson.id,
				unitID: lesson.get('unit').id
			});
			toast({
				status:"success",
				title:"Lesson deleted successfully!",
				position: 'top',
			})
			navigate(`/questionnaires/${id}/sections/${sectionID}`)
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function generateAudio(){
		
		try {
			if (questionData.length > 0) {
				await handleSave(lesson);

				setLoading("Generating Lesson Audio...");

				let first = questionData[0];
				await createLessonAudio({
					questionnaireID: first.get('questionnaire').id,
					sectionID: first.get('section').id,
					lessonID: first.get('lesson').id
				})
				// window.location.reload();
			}
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})			
		}
		setLoading(false);
	}

	if (!lessonID) {
		return;
	}

	if (isLoading || !lesson || loading || !user) {
		return (
			<Flex w="100%" align="center" justify="center" flex="1" mt="-50px">
				<LoadingScreen text={loading ? loading : isLoading ? "Loading questions..." : !lesson ? "We could not find the lesson" : null } />
			</Flex>
		)
	}
	const languges = ['English', 'Arabic', 'Persian'];

	return (
		<Flex direction="column" align="flex-start" maxW="800px" w="100%" alignSelf="center">
			<Flex align="flex-end" justify="flex-end" position="fixed" top="90px" w="800px" zIndex="99" >
				<Flex flex="1">
					{
						languges.map(lang => (
							<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
								<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
							</Flex>
						))
					}
				</Flex>
				{
					user.get('role') == "admin" && (
						<>
							<Tooltip label="Generate Lesson Audio" hasArrow>
								<Flex onClick={generateAudio} mr="10px" w="30px" h="30px" color="lightText" bg="light" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
									<div><i className="fa-solid fa-rss"></i></div>
								</Flex>
							</Tooltip>
							<TextUpload lessonID={lessonID} questions={questions} setQuestions={setQuestions} />
							<TranslatePopover questions={questions} setLoading={setLoading} setQuestions={setQuestions} />
							<ExcelPopover questions={questions} title={fullTitle["english"]} />
						</>
					)
				}
				<Button onClick={() => handleSave(lesson)} variant="primary" p="8px 15px" fontSize="14px" borderRadius="30px">Save lesson</Button>
			</Flex>

			<Flex mb="20px" pt="20px" flex="1" w="100%" align="center" justify="center">
				<Flex mr="10px">
					<TitleTranslatePopover 
						title={fullTitle["english"] || title}
						onChange={handleTranslation}
					/>
				</Flex>
				<Input
					type="text"
					fontSize="30px"
					fontWeight="600"
					color="dark"
					p="0"
					value={fullTitle[language]}
					onChange={(e) => handleTextChange(e.target.value, language)}
					placeholder="Lesson Name"
					border="none"
					flex="1"
				/>
				<Flex onClick={handleDelete} w="30px" h="30px" borderRadius="30px" bg="light" fontSize="13px" ml="10px" align="center" justify="center" cursor="pointer" color="lightText">
					<i className="fa-solid fa-trash"></i>
				</Flex>
			</Flex>
			<Flex mb="10px" w="100%"> 
				<Text flex="1" variant="subtitle" fontWeight="600">{questions.length} Question{questions.length == 1 ? "" : "s"}</Text>
			</Flex>
			<Flex align="center" direction="column" w="100%" pb="100px">
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="main-wrapper" type="questions">
						{(provided) => (
							<Box w="100%" ref={provided.innerRef} {...provided.droppableProps}>
								{
									questions.map((question, index) => (
										<QuestionBox language={language} index={index} data={question} key={question.id} active={question.id == chosenQuestion} />
									))
								}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
				<Flex onClick={() => addQuestion(lessonID)} flex="1" align="center" justify="center" p="13px 0 0" fontSize="13px" color="primary">
					<i className="fa-solid fa-circle-plus"></i>
					<Text variant="link" fontSize="13px" p="0" pt="1px" pl="5px">
						Add Question
					</Text>
				</Flex>
			</Flex>
		</Flex>
	)
}

function validate({ title }) {
	if (isEmpty(title)) {
		return true;
	}

	return false;
}





