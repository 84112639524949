import { Flex, Text } from '@chakra-ui/react';
import { Header } from "../includes";

import { useBasicAnalytics } from "../hooks";

export default function Home() {

	const { data = {} } = useBasicAnalytics();

	return (
		<Flex direction="column">
			<Header />

			<Flex direction="row" w="100%" p="25px 40px" pb="0px">
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-user"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Total users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalUsers || "..."}</Text>
				</Flex>

				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-user"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">iOS users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalIOSUsers || "..."}</Text>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-user"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Android users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalAndroidUsers || "..."}</Text>
				</Flex>


				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" mr="15px" borderRadius="10px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-book-quran"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">English users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalEnglishUsers || "..."}</Text>
				</Flex>

				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-question"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Arabic users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalArabicUsers || 0}</Text>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-question"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Persian users</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalPersianUsers || 0}</Text>
				</Flex>
			</Flex>
			<Flex direction="row" w="100%" p="25px 40px" pb="0px">
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-user"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Total user accounts</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalUserAccounts || "..."}</Text>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-question"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Total lessons</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalLessons || "..."}</Text>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-circle-question"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Support tickets</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalSupportTickets || 0}</Text>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="1" h="200px" align="center" justify="center" borderRadius="10px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-video"></i></div>
					</Flex>
					<Text variant="subtitle" fontWeight="600">Total videos</Text>
					<Text fontSize="35px" fontWeight="600">{data.totalVideos || "..."}</Text>
				</Flex>
			</Flex>
		</Flex>
	)
}



