import { useState } from "react";
import { Flex, Text, Button, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { Link, useParams } from "react-router-dom";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useQuestionnaireByID, useQuestionnaireSections, useEditQuestionnaireSectionOrder } from "../hooks";
import { Header, UnitBox, EditQuestionnaire, LoadingScreen, CreateQuestionnaireSectionModal, MoveQuestionnaireModal } from "../includes";

export default function QuestionnaireDetails() {
	const { id } = useParams();
	const { data, isLoading } = useQuestionnaireByID(id);
	const { data: sections, isLoading: isLoadingSections } = useQuestionnaireSections(id);
	const { mutateAsync: updateOrder } = useEditQuestionnaireSectionOrder();
	const [modal, setModal] = useState(false);
	const [onMoveModal, setOnMoveModal] = useState(false);

	async function onDragEnd({ source, destination }){
	    let _data = [...sections];
	    if (source && destination) {
	        const [removed] = _data.splice(source.index, 1)
	        _data.splice(destination.index, 0, removed)

	        updateOrder({
	        	data: _data,
	        	questionnaireID: id
	        });
	    }
	}

	let isLive = data ? data.get('live') : true;

	return (
		<Flex direction="column" height={window.innerHeight}>
			<Header />
			<Flex p="12px 20px" borderBottom="1px solid" borderBottomColor="light">
				<Flex as={Link} to="/questionnaires" fontSize="12px" align="center" color="primary">
					<i className="fa-solid fa-arrow-left"></i>
					<Text pl="5px" variant="link">Back to Questionnaires</Text>
				</Flex>
				<Text flex="1" textAlign="right" variant="subtitle" fontSize="10px">{id}</Text>
			</Flex>	
			{
				isLoading || !data ? 
				<Flex flex="1" mt="-50px">
					<LoadingScreen text={isLoading ? "Loading Questionnaire..." : !data ? "No Questionnaire found!" : null } />
				</Flex>
				:
				<Flex flex="1">
					<EditQuestionnaire isLive={isLive} data={data} />
					<Flex w="100%" flex='1' p="15px 20px 60px" direction="column" overflow="scroll" maxH={window.innerHeight - 140}>
						<Flex align="center" justify="center" mb="30px">
							<Flex direction="column" flex="1">
								<Text variant="title">Sections</Text>
								<Text variant="description">These are different parts within the questionnaire</Text>
							</Flex>
							{
								!isLive && (
									<Button onClick={() => setModal(true)} variant="dark" p="10px 15px" borderRadius="30px">Create section</Button>
								) 
							}
						</Flex>
						{
							isLoadingSections || !sections ?
							<SimpleGrid columns={3} spacing={10}>
								<Skeleton height="310px" w="100%" opacity="0.5" borderRadius="10px" />
								<Skeleton height="310px" w="100%" opacity="0.5" borderRadius="10px" />
								<Skeleton height="310px" w="100%" opacity="0.5" borderRadius="10px" />
							</SimpleGrid>
							: sections.length == 0 ?
							<Flex align="center" justify="center" flex="1" direction="column" mt="-100px">	
								<Flex w="50px" h="50px" bg="light" borderRadius="30px" mb="10px" align="center" justify="center" fontSize="20px" color="lightText">
									<i className="fa-solid fa-puzzle-piece"></i>
								</Flex>
								<Text variant="title" fontSize="25px">No sections found!</Text>
								<Text fontSize="15px" mt="2px" variant="subtitle">You haven't created an sections for this questionnaire</Text>
								{
									!isLive && <Button onClick={() => setModal(true)} variant="primary" p="10px 15px" borderRadius="30px" mt="50px" fontSize="15px">Create one</Button>
								}
							</Flex>
							:
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="questionnaire-section-wrapper" direction="horizontal">
									{(provided) => (
										<SimpleGrid columns={3} spacing={0} ref={provided.innerRef} {...provided.droppableProps}>
											{
												sections.map((sect, index) => (
													<UnitBox isLive={isLive} onMove={() => setOnMoveModal(sect)} key={sect.id} data={sect} index={index} />
												))
											}
										</SimpleGrid>
									)}
								</Droppable>
							</DragDropContext>
						}
					</Flex>
				</Flex>
			}		
			{
				modal && <CreateQuestionnaireSectionModal questionnaire={data} onClose={() => setModal(false)} />
			}
			{
				onMoveModal && <MoveQuestionnaireModal data={onMoveModal} onClose={() => setOnMoveModal(false)} type="section" />
			}
		</Flex>
	)
}










