import { useState, useEffect } from "react";
import { Flex, Text, Image, Input, Switch, Select, Button, Textarea, Accordion, useToast } from "@chakra-ui/react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useNavigate } from "react-router-dom";
import Parse from 'parse';

import { UnitRow } from "./unit-row";
import { useQuestionnaireSections, useEditQuestionnaireSection, useQuestionnaireSectionUnits, useEditQuestionnaireUnitOrder, useDeleteQuestionnaireSection } from "../../../hooks";
import { CreateQuestionnaireUnitModal } from "../../../includes";
import { isEmpty } from "../../../utils";
import { TranslatePopover } from "../../popover";

export function EditSection({ sectionData, unitData, isLive }){
	const toast = useToast();
	const navigate = useNavigate();
	const user = Parse.User.current();
	
	const { data: sections = [] } = useQuestionnaireSections(sectionData.get('questionnaire').id)
	const { mutateAsync } = useEditQuestionnaireSection();
	const { data: unitsData = [], isLoading: isLoadingUnits } = useQuestionnaireSectionUnits(sectionData.id);
	const { mutateAsync: updateOrder } = useEditQuestionnaireUnitOrder();
	const { mutateAsync: deleteSection } = useDeleteQuestionnaireSection();

	const [title, setTitle] = useState(sectionData.get('title'));
	const [description, setDescription] = useState(sectionData.get('description'));

	const [titleTrans, setTitleTrans] = useState(sectionData.get('fullTitle') || {
		"english":sectionData.get('title'),
		"arabic":"",
		"persian":""
	});

	const [descriptionTrans, setDescriptionTrans] = useState(sectionData.get('fullDescription') || {
		"english":sectionData.get('description'),
		"arabic":"",
		"persian":""
	});

	const [showTitle, setShowTitle] = useState(sectionData.get('showTitle') == undefined ? true : sectionData.get('showTitle'))
	const [language, setLanguage] = useState("english");
	const [createUnit, setCreateUnit] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (user && user.get('language')) {
			setLanguage(user.get('language'))
		}
	},[user])

	async function handleSave(){
		setLoading(true);
		try {
			await mutateAsync({
				id:sectionData.id,
				title,
				description,
				showTitle,
				titleTrans,
				descriptionTrans
			});
			toast({
				status:"success",
				title:"Section updated successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handleDelete(){
		setLoading(true);
		try {
			await deleteSection({
				id:sectionData.id,
				questionnaireID: sectionData.get('questionnaire').id
			});
			navigate(`/questionnaires/${sectionData.get('questionnaire').id}`);

			toast({
				status:"success",
				title:"Section deleted successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function onDragEnd({ source, destination }){
	    let _data = [...unitsData];
	    if (source && destination) {
	        const [removed] = _data.splice(source.index, 1)
	        _data.splice(destination.index, 0, removed)
	        
	        updateOrder({ 
	        	data: _data,
	        	sectionID: sectionData.id
	        });
	    }
	}

	function handleTranslation(translation){
		let _titleTrans = { ...titleTrans };
		_titleTrans[translation.lang] = translation.titleTrans;

		setTitleTrans(_titleTrans);

		let _descriptionTrans = { ...descriptionTrans };
		_descriptionTrans[translation.lang] = translation.descriptionTrans;
		setDescriptionTrans(_descriptionTrans);
	}

	function handleTextChange(type, value) {
		if (type == "title") {
			let _titleTrans = { ...titleTrans };
			_titleTrans[language] = value;
			setTitleTrans(_titleTrans);	
		}
		if (type == "description") {
			let _descriptionTrans = { ...descriptionTrans };
			_descriptionTrans[language] = value;
			setDescriptionTrans(_descriptionTrans);	
		}
	}

	const languges = ['English', 'Arabic', 'Persian'];
	return (
		<Flex w="350px" direction="column" borderRight="1px solid" borderRightColor="light" p="15px">
			<Flex direction="column" flex="1" overflow="scroll" maxH={window.innerHeight - 220}> 

				<Flex direction="column" borderBottom="1px solid" borderBottomColor="light" mb="20px" pb="10px">
					<Flex direction="column">
						<Flex>
							<Text flex="1" variant="subtitle">Units</Text>
							{
								!isLive && <Text onClick={() => setCreateUnit(true)} variant="link">Add unit</Text>
							}
						</Flex>
						<Flex direction="column" mt="10px">
							{
								unitsData.length == 0 ?
									<Flex cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="200px" border="2px solid" w="100%" borderColor="light" align="center" justify="center" overflow="hidden">
										<Flex w="35px" h="35px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
											<i className="fa-solid fa-layer-group"></i>
										</Flex>
										<Text variant="subtitle" fontSize="14px" mt="10px">No units found</Text>
										<Button onClick={() => setCreateUnit(true)} variant="primary" mt="20px">Add unit</Button>
									</Flex>									
								:
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="questionnaire-section-unit-wrapper">
										{(provided) => (
											<Accordion allowToggle defaultIndex={0} ref={provided.innerRef} {...provided.droppableProps}>
												{
													unitsData.map((unit, index) => (
														<UnitRow isLive={isLive} key={unit.id} data={unit} index={index} />
													))
												}
											</Accordion>
										)}
									</Droppable>
								</DragDropContext>
							}
						</Flex>
					</Flex>
				</Flex>

				<Flex align="flex-end" w="100%" direction="row" justify="flex-end" mb="10px">
					<Flex flex="1">
						{
							languges.map(lang => (
								<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
									<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
								</Flex>
							))
						}
					</Flex>
					<TranslatePopover 
						title={titleTrans['english'] || title}
						description={descriptionTrans['english'] || description}
						onChange={handleTranslation}
					/>
				</Flex>

				<Flex direction="column" mb="20px">
					<Text variant="subtitle">Title</Text>
					<Input
						mt="7px"
						value={titleTrans[language]}
						onChange={(e) => handleTextChange('title', e.target.value)}
						fontWeight="500"
						textAlign={language == "english" ? "left" : "right"}
						h="45px"
						p="10px"
						fontSize="15px"
					/>
				</Flex>

				<Flex direction="column" mb="20px">
					<Text variant="subtitle">Description</Text>
					<Textarea
						mt="7px"
						value={descriptionTrans[language]}
						onChange={(e) => handleTextChange('description', e.target.value)}
						fontWeight="500"
						textAlign={language == "english" ? "left" : "right"}
						resize="none"
						rows={3}
						p="10px"
						fontSize="15px"
					/>
				</Flex>

				<Flex mb="20px" align="center" justify="center">
					<Text flex="1" variant="subtitle">Show Title</Text>
					<Switch isChecked={showTitle} onChange={(e) => setShowTitle(e.target.checked)} colorScheme="green" size='md' />
				</Flex>
			</Flex>

			<Flex borderTop="1px solid" borderTopColor="light" pt="15px">
				{
					!isLive && <Button variant="white" p="13px 15px" fontSize="14px" onClick={handleDelete} mr="10px">Delete</Button>
				}
				<Button isLoading={loading} isDisabled={validate({ title })} variant="secondary" p="13px 15px" fontSize="14px" w="100%" onClick={handleSave}>Save details</Button>
			</Flex>
			{
				createUnit && <CreateQuestionnaireUnitModal sectionData={sectionData} onClose={() => setCreateUnit(false)} />
			}
		</Flex>
	)
}

function validate({ title }) {
	if (isEmpty(title)) {
		return true;
	}

	return false;
}










