import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useToast } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";
import { useCreateQuestionnaireQuestions, useDeleteQuestion } from "../../hooks";


export const QuestionnaireContext = createContext();
export const QuestionnaireProvider = (props) => {
    const toast = useToast();
    const [questions, setQuestions] = useState([]);
    const [title, setTitle] = useState("");
    const [fullTitle, setFullTitle] = useState({})

    const [chosenQuestion, setChosenQuestion] = useState();
    const [loading, setLoading] = useState(false);

    const { mutateAsync } = useCreateQuestionnaireQuestions();
    const { mutateAsync: deleteQuestion } = useDeleteQuestion();

    // questions
    function addQuestion(lessonID, array){
        let _questions = array ? array : [...questions];
        let questionID = nanoid();
        _questions.push({
            "id":questionID,
            "lessonID":lessonID,
            "title":{
                "english":"",
                "arabic":"",
                "persian":""
            },
            "options":[addOption(), addOption(), addOption(), addOption()],
            "audio":{
                "start":{
                    "english":"",
                    "arabic":"",
                    "persian":""
                },
                "helpText":{
                    "english":"",
                    "arabic":"",
                    "persian":""
                },
                "end":{
                    "english":"",
                    "arabic":"",
                    "persian":""
                }
            },
            "helpText":{
                "english":"",
                "arabic":"",
                "persian":""
            },
            "type":"radio",
            "videos":[addVideoOption()],
            "answer":"",
            "source":{}
        })
        setQuestions(_questions);
        setChosenQuestion(questionID)
    }

    function convertQuestion(question) {
        return {
            "id":nanoid(),
            "lessonID":question.get('lesson').id,
            "sectionID":question.get('section').id,
            "questionnaireID":question.get('questionnaire').id,
            "order":question.get('order'),
            "parseID":question.id,
            "title":question.get('title'),
            "options":question.get('options'),
            "audio":question.get('audio'),
            "helpText":question.get('helpText'),
            "type":question.get('type'),
            "videos":question.get('videos') || [addVideoOption()],
            "answer":question.get('answer'),
            "source":question.get('source')
        }
    }

    function updateQuestion(id, data){
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == id);
        if (index >= 0) {
            _questions[index] = {
                ..._questions[index],
                ...data
            }
            setQuestions(_questions)
        }
    }

    function removeQuestion(id) {
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == id);
        if (index >= 0) {
            
            if (_questions[index].parseID) {
                deleteQuestion(_questions[index].parseID)
            }

            _questions.splice(index,1);
            setQuestions(_questions);
            setChosenQuestion();
        }
    }

    function setAnswer(questionId, optionID) {
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == questionId);
        if (index >= 0) {
            _questions[index].answer = optionID;
            setQuestions(_questions);
        }        
    }

    function addVideoOption(questionId) {
        let videoData = {
            "id":nanoid(),
            "urls":{
                "english":"",
                "arabic":"",
                "persian":""
            },
            "videoMetadata":{
                "english":{},
                "arabic":{},
                "persian":{}
            },
            "canSkip": true
        }

        if (questionId) {
            let _questions = [...questions];
            let index = _questions.findIndex(e => e.id == questionId);
            if (index >= 0) {
                _questions[index].videos.push(videoData)
            }
            setQuestions(_questions);
            return;            
        }

        return videoData;
    }
    // options
    function addOption(questionId) {
        let optionData = {
            "id":nanoid(),
            "title":{
                "english":"",
                "arabic":"",
                "persian":""
            },
            "endingAudioText":{
                "english":"",
                "arabic":"",
                "persian":""
            },
            "canTranslate":{
                "english":true,
                "arabic":true,
                "persian":true,
            }
        }
        if (questionId) {
            let _questions = [...questions];
            let index = _questions.findIndex(e => e.id == questionId);
            if (index >= 0) {
                _questions[index].options.push(optionData)
            }
            setQuestions(_questions);
        } else {
            return optionData
        }   
    }

    function updateVideo(questionId, videoId, data) {
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == questionId);
        if (index >= 0) {
            let _videos = [..._questions[index].videos];
            let vidIndex = _videos.findIndex(e => e.id == videoId);
            if (vidIndex >= 0) {
                _questions[index].videos[vidIndex] = {
                    ..._videos[vidIndex],
                    ...data
                }
                setQuestions(_questions)
            }
        }
    } 

    function updateOption(questionId, optionId, data) {
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == questionId);
        if (index >= 0) {
            let _options = [..._questions[index].options];
            let optIndex = _options.findIndex(e => e.id == optionId);
            if (optIndex >= 0) {
                _questions[index].options[optIndex] = {
                    ..._options[optIndex],
                    ...data
                }
                setQuestions(_questions)
            }
        }
    }

    function removeOption(questionId, optionId) {
        let _questions = [...questions];
        let index = _questions.findIndex(e => e.id == questionId);
        if (index >= 0) {
            let _options = [..._questions[index].options];
            let optIndex = _options.findIndex(e => e.id == optionId);
            if (optIndex >= 0) {
                _questions[index].options.splice(optIndex, 1)
                if (_questions[index].answer == optionId) {
                    _questions[index].answer = null;
                }
                setQuestions(_questions);
            }
        }
    }

    // Draggability
    function onDragEnd(data) {
        const { source, destination, type, draggableId } = data;
        let _questions = [...questions];
        
        if (type == "questions") {
            const [removed] = _questions.splice(source.index, 1)
            _questions.splice(destination.index, 0, removed)
            setQuestions(_questions)
        } else if (type == "options" && draggableId.includes(':::')) {
            let questionId = draggableId.split(':::')[0]
            let index = _questions.findIndex(e => e.id == questionId);
            if (index >= 0) {
                const [removed] = _questions[index].options.splice(source.index, 1);
                _questions[index].options.splice(destination.index, 0, removed);
                setQuestions(_questions)
            }
        }
    }

    async function handleSave(lesson){
        setLoading("Saving questions...");
        try {
           let new_questions = await mutateAsync({
                lesson,
                questions,
                title,
                fullTitle
            })
            setQuestions(new_questions)
            toast({
                status:"success",
                title:"Lesson plan updated successfully!",
                position: 'top',
            })
        }
        catch(error) {
            toast({
                status:"error",
                title:error.message,
                position: 'top',
            })
        }
        setLoading(false);
    }

    function handleTranslation(translation){
        let _fullTitle = {...fullTitle};
        _fullTitle[translation.lang] = translation.titleTrans;
        if (_fullTitle['english'] == "") {
            _fullTitle['english'] = title;
        }
        setFullTitle(_fullTitle);
    }

    function handleTextChange(value, language) {
        let _fullTitle = {...fullTitle};
        _fullTitle[language] = value;
        setFullTitle(_fullTitle);
    }

    const value = useMemo(() => ({ questions, chosenQuestion, addQuestion, setFullTitle, updateQuestion, setChosenQuestion, removeQuestion, updateOption, updateVideo, addOption, removeOption, setAnswer, onDragEnd, setTitle, title, handleTranslation, handleTextChange, fullTitle, loading, setLoading, handleSave, setQuestions, convertQuestion }), [fullTitle, title, chosenQuestion, questions, loading])
    return <QuestionnaireContext.Provider value={value} {...props} />

}

export const useQuestionnaire = () => {
    const context = useContext(QuestionnaireContext)
    if (!context) {
        throw new Error(`useQuestionnaire must be used within an QuestionnaireProvider`)
    }
    return context
}







