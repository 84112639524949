import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useQuestionnaireSectionUnits = (id) => {
	return useQuery(['questionnaires-sections-units', id],
		async () => {
			const query = new Parse.Query("QuestionnaireUnits");
			query.equalTo("section", {
				"__type":"Pointer",
				"objectId":id,
				"className":"QuestionnaireSections"
			});

			let data = await query.find();

			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useQuestionnaireAllUnits = (id) => {
	return useQuery(['questionnaires-all-units', id],
		async () => {
			const query = new Parse.Query("QuestionnaireUnits");
			query.equalTo("questionnaire", {
				"__type":"Pointer",
				"objectId":id,
				"className":"Questionnaires"
			});
			query.limit(10000);
			let data = await query.find();
			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useCreateQuestionnaireUnit = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const QuestionnaireUnits = Parse.Object.extend("QuestionnaireUnits");
		const new_unit = new QuestionnaireUnits();

		new_unit.set("type", "level");
		new_unit.set("title", data.title);
		new_unit.set('questionnaire',{
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		new_unit.set('section',{
			"__type":"Pointer",
			"objectId":data.sectionID,
			"className":"QuestionnaireSections"
		})

		const query = new Parse.Query("QuestionnaireUnits");
		query.equalTo('questionnaire', {
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		query.equalTo('section', {
			"__type":"Pointer",
			"objectId":data.sectionID,
			"className":"QuestionnaireSections"
		})

		const totalUnits = await query.count();
		new_unit.set('order', totalUnits);

		await new_unit.save();
		queryClient.refetchQueries(['questionnaires-sections-units', data.sectionID]);

		return new_unit;	
	}, {
		...options,
	})
}

export const useEditQuestionnaireUnitOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async ({ data, sectionID }) => {

		queryClient.setQueryData(['questionnaires-sections-units', sectionID], data);
		
		await Promise.all(data.map(async (ques, index) => {
			ques.set('order', index);
			await ques.save();
		}));

	}, {
		...options,
	})
}

export const useDeleteQuestionnaireUnit = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("QuestionnaireUnits");
		let old_unit  = await query.get(data.id)

		if (old_unit) {
		
			const query_questions = new Parse.Query("QuestionnaireQuestions");
			query_questions.equalTo('unit', old_unit);
			let questions_data = await query_questions.find();
			await Promise.all(questions_data.map(async data => {
				await data.destroy();
			}));

			const query_lessons = new Parse.Query("QuestionnaireLessons");
			query_lessons.equalTo('unit', old_unit);
			let lessons_data = await query_lessons.find();
			await Promise.all(lessons_data.map(async data => {
				await data.destroy();
			}));

			await old_unit.destroy();

			queryClient.refetchQueries(['questionnaires-sections-units', data.sectionID]);

		}
	}, {
		...options,
	})
}

