import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  exs: "320px",
  sm:"410px",
  md: "700px",
  lg: "1000px",
  xl: "1300px",
  "2xl": "1700px",
})

export const theme = extendTheme({
    breakpoints,
    fonts: {
        heading: "Gilroy",
        body: "Gilroy",
        text:"Gilroy"
    },
    colors: {
        primary:"#7E7AFF",
        secondary:"#716de5",
        dark:"#020147",
        error:"#ff4b6e",
        warning:"#fabc00",
        success:"#2ecc71",
        purple:"#8b56fd",
        light:"#EDF2F7",
        ultraLight:"rgba(237,242,247,0.5)",
        lightText:"rgba(2,1,71,0.5)"
    },
    components:{
        Alert:{
            variants:{
                solid:(props) => {
                    const { status } = props;
                    return {
                        container:{
                            padding:"15px",
                            bg:status == "error" ? "error" : status == "success" ? "primary" : "dark",
                        },
                        title:{
                            fontWeight:600,
                            lineHeight:1,
                            mt:"3px",
                        },
                        description:{
                            fontSize:"14px",
                            fontWeight:500,
                            lineHeight:1.4,
                            mt:"10px"
                        }
                    }
                }
            }
        },
        Text:{
            baseStyle:{
                fontSize:15,
                fontWeight:500,
                color:"dark"
            },
            variants:{
                uppercase:{
                    fontSize:"8px",
                    letterSpacing:"1px",
                    textTransform:"uppercase",
                    fontWeight:600,
                    opacity:0.6
                },
                subtitle: {
                    fontSize:"13px",
                    fontWeight:600,
                    opacity:0.6,
                    letterSpacing: 0
                },
                link:{
                    fontSize:"12px",
                    color:"primary",
                    cursor:"pointer",
                    padding:"0 15px",
                    pr:0
                },
                description:{
                    fontSize:"15px",
                    fontWeight:500,
                    lineHeight:1.7,
                    opacity:0.5
                },
                logo: {
                    fontSize:"20px",
                    fontWeight:700,
                    color:"dark"
                },
                title: {
                    fontSize:"25px",
                    fontWeight:"600"
                }
            }
        },
        Tooltip:{
            baseStyle:{
                maxWidth:"160px",
                fontSize:"12px",
                borderRadius:"5px",
                padding:"8px 10px",
            }
        },
        Tag:{
            baseStyle:{
                container:{
                    background:"light",
                    mr:"5px",
                    borderRadius:"30px",
                    p:"5px 10px",
                    color:"rgba(2,1,71,0.7)",
                },
            },
            defaultProps:{
                variant:"none",
                fontSize:"12px"
            }
        },
        Badge: {
            variants: {
                primary: {
                    background:"dark",
                    width:"15px",
                    height:"15px",
                    borderRadius:"30px",
                    color:"white",
                    textAlign:"center",
                    fontWeight:500,
                    fontSize:"10px",
                    pt:"1px"
                }
            },
            defaultProps:{
                variant:"primary"
            }         
        },
        Button: {
            variants: {
                primary: {
                    background:"primary",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"secondary",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"secondary !important"
                        }
                    }
                },
                white: {
                    background:"white",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"lightText",
                    border:'1px solid #f3f3f3',
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"white",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"white !important"
                        }
                    }
                },
                success: {
                    background:"success",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"#29b765 !important",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"#29b765 !important"
                        }
                    }
                },
                error: {
                    background:"error",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"#e54363 !important",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"#e54363 !important"
                        }
                    }
                },
                warning: {
                    background:"warning",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"#e1a900 !important",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"#e1a900 !important"
                        }
                    }
                },
                secondary: {
                    background:"light",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"lightText",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"#d5d9de",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"#d5d9de !important"
                        }
                    }
                },
                purple: {
                    background:"purple",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"#7d4de3",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"#7d4de3 !important"
                        }
                    }
                },
                black: {
                    background:"black",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"black",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"black !important"
                        }
                    }
                },
                dark: {
                    background:"dark",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"600",
                    borderRadius:"5px",
                    _hover:{
                        background:"dark",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"dark !important"
                        }
                    }
                },

                darkBG: {
                    background:"rgba(0,0,0,0.2)",
                    fontSize:"13px",
                    padding:"6px 10px",
                    lineHeight:1,
                    color:"white",
                    height:"auto",
                    fontWeight:"500",
                    borderRadius:"5px",
                    _hover:{
                        background:"rgba(0,0,0,0.4)",
                    },
                    _disabled:{
                        opacity:1,
                        _hover:{
                            background:"rgba(0,0,0,0.4) !important"
                        }
                    }
                }
            }
        }
    }
})




