import { Flex, Text } from '@chakra-ui/react';
import { Header } from "../includes";

export default function Users() {
	return (
		<Flex direction="column">
			<Header />
			

		</Flex>
	)
}