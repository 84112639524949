import { useState, useRef, useEffect } from "react";
import { Flex, Text, Image, Input, Switch, Select, Button, Textarea, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Parse from 'parse';

import { useQuestionnaires, useEditQuestionnaire, useDeleteQuestionnaire } from "../../../hooks";
import { isEmpty, toBase64 } from "../../../utils";
import { TranslatePopover } from "../../popover";
import { QuestionnaireLiveChecks } from "../../modals";

export function EditQuestionnaire({ data, isLive }){
	const { mutateAsync } = useEditQuestionnaire();
	const { mutateAsync: deleteQuestionnare } = useDeleteQuestionnaire();

	const inputRef = useRef();
	const toast = useToast();
	const navigate = useNavigate();
	const user = Parse.User.current();

	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(data.get('title'));
	const [description, setDescription] = useState(data.get('description'));
	const [image, setImage] = useState(data.get('image') ? {
		"imageURL":data.get('image')
	} : null);

	const [titleTrans, setTitleTrans] = useState(data.get('fullTitle') || {
		"english":data.get('title'),
		"arabic":"",
		"persian":""
	});

	const [descriptionTrans, setDescriptionTrans] = useState(data.get('fullDescription') || {
		"english":data.get('description'),
		"arabic":"",
		"persian":""
	});

	const [language, setLanguage] = useState("english");
	const [live, setLive] = useState(data.get('live') || false);
	const [comingSoon, setComingSoon] = useState(data.get('comingSoon') || false);
	const [showLiveModal, setShowLiveModal] = useState(false);

	useEffect(() => {
		if (user && user.get('language')) {
			setLanguage(user.get('language'))
		}
	},[user])

	async function handleImage(e){
		setLoading(true);
		let { files } = e.target;

		let file = files[0];
		let base64 = await toBase64(file);

		setImage({
			"type":file.type,
			"name":file.name,
			"size":file.size,
			"base64":base64
		})
		setLoading(false);
	}

	async function handleSubmit(_live) {
		let isLive = _live ? _live : live;
		setLoading(true);
		try {
			await mutateAsync({
				id:data.id,
				title,
				description,
				titleTrans,
				descriptionTrans,
				image,
				live: isLive,
				comingSoon,
			});
			toast({
				status:"success",
				title:"Questionnaire updated successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handleDelete(){
		setLoading(true);
		try {
			await deleteQuestionnare({
				id:data.id,
			});
			navigate('/questionnaires')
			toast({
				status:"success",
				title:"Questionnaire deleted successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	function handleTranslation(translation){
		let _titleTrans = { ...titleTrans };
		_titleTrans[translation.lang] = translation.titleTrans;

		setTitleTrans(_titleTrans);

		let _descriptionTrans = { ...descriptionTrans };
		_descriptionTrans[translation.lang] = translation.descriptionTrans;
		setDescriptionTrans(_descriptionTrans);
	}

	function handleTextChange(type, value) {
		if (type == "title") {
			let _titleTrans = { ...titleTrans };
			_titleTrans[language] = value;
			setTitleTrans(_titleTrans);	
		}
		if (type == "description") {
			let _descriptionTrans = { ...descriptionTrans };
			_descriptionTrans[language] = value;
			setDescriptionTrans(_descriptionTrans);	
		}
	}

	async function handleLive(val){
		if (val == true) {
			await handleSubmit();
			setShowLiveModal(true);
		} else {
			setLive(val);
		}
	}

	async function onLiveModalStatus() {
		setLive(true);
		handleSubmit(true);
	}

	const languges = ['English', 'Arabic', 'Persian'];



	if (!user) {
		return
	}

	return (
		<Flex w="400px" direction="column" borderRight="1px solid" borderRightColor="light" p="15px">
			<Flex direction="column" flex="1"> 
				<Flex direction="column" mb="20px">
					<Flex align="flex-end" w="100%" direction="row" justify="flex-end" mb="10px">
						<Flex flex="1">
							{
								languges.map(lang => (
									<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
										<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
									</Flex>
								))
							}
						</Flex>
						<TranslatePopover 
							title={titleTrans['english'] || title}
							description={descriptionTrans['english'] || description}
							onChange={handleTranslation}
						/>
					</Flex>
					<Flex>
						<Text mr="4px" variant="subtitle">Title</Text>
						<Text color="red">*</Text>
					</Flex>
					<Input
						mt="7px"
						value={titleTrans[language] || title}
						onChange={(e) => handleTextChange('title', e.target.value)}
						fontWeight="500"
						h="45px"
						textAlign={language == "english" ? "left" : "right"}
						p="10px"
						fontSize="15px"
					/>
				</Flex>

				<Flex direction="column" mb="20px">
					<Flex>
						<Text mr="4px" variant="subtitle">Description</Text>
						<Text color="red">*</Text>
					</Flex>
					<Textarea
						mt="7px"
						value={descriptionTrans[language] || description}
						onChange={(e) => handleTextChange('description', e.target.value)}
						fontWeight="500"
						textAlign={language == "english" ? "left" : "right"}
						resize="none"
						rows={3}
						p="10px"
						fontSize="15px"
					/>
				</Flex>

				<Flex direction="column" mb="20px">
					<Flex align="center" justify="center">
						<Flex flex="1">
							<Text mr="4px" variant="subtitle">Cover Image</Text>
							<Text color="red">*</Text>
						</Flex>						
						{
							image && <Text onClick={() => inputRef.current.click()} variant="link">Update Image</Text>
						}
					</Flex>
					<Flex w="100%" borderRadius="10px" overflow="hidden" mt="7px">
						{
							image ? 
							<Image 
								width="100%"
								h="220px"
								objectFit='cover'
								objectPosition="center"
								alt="Cover Image"
								src={image.imageURL || image.base64} />	
							:
							<Flex onClick={() => inputRef.current.click()} cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="220px" border="2px solid" w="100%" borderColor="light" align="center" justify="center" overflow="hidden">
								<Flex w="35px" h="35px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
									<i className="fa-solid fa-cloud-arrow-up"></i>
								</Flex>
								<Text variant="subtitle" fontSize="14px" mt="10px">Upload an image</Text>
							</Flex>
						}
					</Flex>
				</Flex>

				<Flex mb="20px" align="center" justify="center">
					<Text flex="1" variant="subtitle">Live</Text>
					<Switch isDisabled={isLive || user.get('role') != "admin"} isChecked={live} onChange={(e) => handleLive(e.target.checked)} colorScheme="green" size='md' />
				</Flex>
				
				<Flex mb="20px" align="center" justify="center">
					<Text flex="1" variant="subtitle">Coming Soon</Text>
					<Switch isChecked={comingSoon} isDisabled={user.get('role') != "admin"} onChange={(e) => setComingSoon(e.target.checked)}  colorScheme="red" size='md' />
				</Flex>
			</Flex>
			<Flex borderTop="1px solid" borderTopColor="light" pt="15px">
				{
					!isLive && <Button onClick={handleDelete} mr="10px" variant="white" p="13px 15px" fontSize="14px">Delete</Button>
				}
				<Button isLoading={loading} isDisabled={validate({ title, description, image, live, comingSoon })} onClick={() => handleSubmit()} variant="secondary" p="13px 15px" fontSize="14px" w="100%">Save details</Button>
			</Flex>
			<input type="file" style={{ display:"none", opacity: 0}} accept="image/png, image/jpg, image/jpeg" ref={inputRef} onChange={handleImage} />
			{
				showLiveModal && <QuestionnaireLiveChecks onLiveModalStatus={onLiveModalStatus} data={data} onClose={() => setShowLiveModal(false)}  />
			}
		</Flex>
	)
}



function validate({ title, description, image, live }){
	if (isEmpty(title)) {
		return true;
	}
	if (isEmpty(description)) {
		return true;
	}
	if (live == true && !image) {
		return true;
	}

	return false;
}





