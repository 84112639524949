import { useRef, useState, useEffect } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Textarea,
	Image,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty, isURL, getYoutubeID, getYoutubePlaylistID, toBase64 } from "../../utils";
import { useCategories, useAddVideo } from "../../hooks";

export function AddVideoModal({ totalVideos, onClose, chosenCategory, albums, chosenAlbum }) {
	const toast = useToast();
	const inputRef = useRef();
	const [loading, setLoading] = useState(false);
	const [url, setURL] = useState("");
	const [embeddedURL, setEmbeddedURL] = useState("")
	const [listID, setListID] = useState("");
	const [embeddedImage, setEmbeddedImage] = useState();

	const [category, setCategory] = useState(chosenCategory == "All" ? null : chosenCategory);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState("");
	const [youtubeData, setYoutubeData] = useState();
	const [playlistData, setPlaylistData] = useState();
	const [language, setLanguage] = useState("");

	const [album, setAlbum] = useState(chosenAlbum ? chosenAlbum.id : null);
	const [live, setLive] = useState(true);
	const [embedded, setEmbedded] = useState(false);

	const { mutateAsync } = useAddVideo();
	const { data: categories } = useCategories('video');

	async function handleSubmit(){
		setLoading(true);
		try {

			if (embedded == true) {
				await mutateAsync({
					title,
					description,
					embeddedURL,
					embeddedImage, 
					type:"embed",
					category,
					live,
					album,
					language,
					order: category ? totalVideos.filter(e => e.get('category') && e.get('category').id == category).length : totalVideos.length,
				});
			} else {
				await mutateAsync({
					title,
					description,
					image, 
					youtubeCategoryID: youtubeData.categoryId,
					author: youtubeData.channelTitle,
					category,
					live,
					album,
					language,
					order: category ? totalVideos.filter(e => e.get('category') && e.get('category').id == category).length : totalVideos.length,
					videoID:getYoutubeID(url)
				});
			}
			onClose();
			toast({
				status:"success",
				title:"New video added!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handlePlaylist(){
		setLoading(true);
		try {
			let videos = [];
			let playlistID = getYoutubePlaylistID(listID);

			playlistData.map((video, index) => {
				let _title = title ? title + " " + (index + 1) : video.snippet.title;
				videos.push({
					title: _title,
					description: video.snippet.description,
					image: video.snippet.thumbnails.high.url,
					youtubeCategoryID: playlistID,
					author: video.snippet.channelTitle,
					category,
					live,
					album,
					order: index,
					videoID: video.snippet.resourceId.videoId
				})
			})

			await Promise.all(videos.map(async video => {
				await mutateAsync(video);
			}))

			onClose();
			toast({
				status:"success",
				title:`${videos.length} videos have been added!`,
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})			
		}

		setLoading(false);
	}

	useEffect(() => {
		if (isURL(url) && !youtubeData) {
			fetchData()
		}
	},[url])

	useEffect(() => {
		if (isURL(listID) && !playlistData) {
			fetchPlaylistData();
		}
	},[listID])


	async function fetchPlaylistData(){
		setLoading(true);

		let playlistID = getYoutubePlaylistID(listID);
		if (playlistID) {
			try {
				let response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistID}&maxResults=100&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
				let data = await response.json();

				if (data.items.length > 0) {
					setPlaylistData(data.items);
				} else {
					toast({
						status:"error",
						title:"No videos found",
						position: 'top',
					})						
				}
			}
			catch(error) {
				toast({
					status:"error",
					title:error.message,
					position: 'top',
				})					
			}
		} else {
			toast({
				status:"error",
				title:"We could not find the youtube playlist id from the url",
				position: "top"
			})
		}

		setLoading(false);
	}

	async function fetchData() {
		setLoading(true);
		let youtubeID = getYoutubeID(url);
		if (youtubeID) {
			try {
				let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youtubeID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
				let data = await response.json();

				if (data && data.items.length > 0) {
					let { snippet } = data.items[0];
					setTitle(snippet.title);
					setDescription(snippet.description);
					setImage(snippet.thumbnails.high.url)
					setYoutubeData(snippet);
				}
			}
			catch(error) {
				toast({
					status:"error",
					title:error.message,
					position: 'top',
				})				
			}
		} else {
			toast({
				status:"error",
				title:"We could not find the youtube id from the url",
				position: "top"
			})
		}
		setLoading(false);
	}

	async function handleImage(e){
		setLoading(true);
		let { files } = e.target;

		let file = files[0];
		let base64 = await toBase64(file);

		setEmbeddedImage({
			"type":file.type,
			"name":file.name,
			"size":file.size,
			"base64":base64
		})
		setLoading(false);
	}

	function changeURL(){
		setTitle("");
		setDescription("");
		setImage("");
		setYoutubeData();
		setURL("");
		setListID("");
		setPlaylistData();
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Add video</Text>
					{
						playlistData ? (
							<Flex direction="column">
								<Flex borderBottom="1px solid" pb="20px" align="center" justify="center" borderColor="light" w="100%" mb="20px">
									<Input
										type="text"
										placeholder="Preset Name"
										fontWeight="500"
										h="50px"
										p="10px"
										fontSize="14px"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										mr="10px"
										_focus={{
											borderColor:"primary",
											borderWidth:"2px"
										}}
									/>
									<Button onClick={changeURL} w="100%" variant="secondary" h="50px" p="10px 0">Change URL</Button>
								</Flex>

								<Flex direction="column" overflow="scroll" maxH="600px">
									{
										playlistData.map((video, index) => (
											<Flex key={video.id} mb="13px" align="center" justify="center">
												<Image src={video.snippet.thumbnails.medium.url} borderRadius="5px" w="60px" h="60px" objectFit="cover" objectPosition="center" />
												<Input
													type="text"
													ml="15px"
													placeholder="Title"
													fontWeight="500"
													h="50px"
													p="10px"
													fontSize="14px"
													value={title ? title + " " + (index + 1) : video.snippet.title}
													_focus={{
														borderColor:"primary",
														borderWidth:"2px"
													}}
												/>
											</Flex>
										))
									}
								</Flex>
							</Flex>							
						)
						: youtubeData || embedded == true  ? (
							<Flex direction="column">
								{
									youtubeData ?
									<Flex borderBottom="1px solid" borderColor="light" w="100%" mb="20px">
										<Button onClick={changeURL} w="100%" variant="secondary" p="10px 0" mb="15px">Change URL</Button>
									</Flex>
									:
									embedded ?
									<Flex direction="column" mb="20px">
										<Flex>
											<Text variant="subtitle">Embedded Url</Text>
											<Text ml="2px" color="error">*</Text>
										</Flex>
										<Input
											type="text"
											placeholder="URL"
											mt="5px"
											fontWeight="500"
											h="50px"
											p="10px"
											fontSize="14px"
											value={embeddedURL}
											onChange={(e) => setEmbeddedURL(e.target.value)}
											_focus={{
												borderColor:"primary",
												borderWidth:"2px"
											}}
										/>
									</Flex>
									:
									null
								}

								<Flex direction="column" mb="20px">
									<Flex>
										<Text variant="subtitle">Image</Text>
										<Text ml="2px" color="error">*</Text>
									</Flex>
									<Flex onClick={embedded ? () => inputRef.current.click() : null} cursor="pointer" direction="column" mt="10px" borderRadius="10px" h="200px" border="1px solid" borderColor="light" align="center" justify="center" overflow="hidden">
										{
											embeddedImage ?
											<Image src={embeddedImage.base64} w="100%" h="100%" objectFit="cover" objectPosition="center" />
											:
											image ? 
											<Image src={image} w="100%" h="100%" objectFit="cover" objectPosition="center" />
											:
											null
										}
									</Flex>
								</Flex>
								<Flex mb="20px" align="center" justify="center">
									<Text flex="1" variant="subtitle">Live</Text>
									<Switch isChecked={live} onChange={(e) => setLive(e.target.checked)} colorScheme="green" size='md' />
								</Flex>
								<Flex direction="column" mb="20px">
									<Flex>
										<Text variant="subtitle">Title</Text>
										<Text ml="2px" color="error">*</Text>
									</Flex>
									<Input
										type="text"
										placeholder="Title"
										mt="5px"
										fontWeight="500"
										h="50px"
										p="10px"
										fontSize="14px"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										_focus={{
											borderColor:"primary",
											borderWidth:"2px"
										}}
									/>
								</Flex>
								<Flex direction="column" mb="20px">
									<Flex>
										<Text variant="subtitle">Description</Text>
										<Text ml="2px" color="error">*</Text>
									</Flex>
									<Textarea
										type="text"
										placeholder="Set a compelling description"
										mt="5px"
										fontWeight="500"
										rows={4}
										resize="none"
										p="10px"
										fontSize="14px"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										_focus={{
											borderColor:"primary",
											borderWidth:"2px"
										}}
									/>
								</Flex>
								<Flex mb="20px">
									<Flex flex="1" direction="column" mr="10px">
										<Flex>
											<Text variant="subtitle">Category</Text>
										</Flex>
										<Select value={category} onChange={(e) => setCategory(e.target.value)} w="100%" mt="8px" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select category'>
											{
												categories.map((category, index) => (
													<option key={index} value={category.id}>{category.title}</option>
												))
											}
										</Select>
									</Flex>
									<Flex flex="1" direction="column">
										<Flex>
											<Text variant="subtitle">Language</Text>
											<Text ml="2px" color="error">*</Text>
										</Flex>
										<Select value={language} onChange={(e) => setLanguage(e.target.value)} mt="5px" w="100%" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select language'>
											<option value="english">English</option>
											<option value="arabic">Arabic</option>
											<option value="persian">Persian</option>
											<option value="mixed">Mixed</option>
										</Select>						
									</Flex>								
								</Flex>
								<Flex direction="column" mb="20px">
									<Text variant="subtitle">Album</Text>
									<Select value={album} onChange={(e) => setAlbum(e.target.value)} w="100%" mt="10px" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select album'>
										{
											albums.filter(e => !e.albums).map((album, index) => (
												<option key={index} value={album.id}>{album.title}</option>
											))
										}
									</Select>
								</Flex>
							</Flex>
						)
						:
						<Flex mb="20px">
							<Flex direction="column" flex="1">
								<Text variant="subtitle">Youtube URL</Text>
								<Input
									type="text"
									placeholder="Youtube URL"
									mt="10px"
									fontWeight="500"
									h="50px"
									p="10px"
									value={url}
									onChange={(e) => setURL(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>
							<Flex direction="column" flex="1" ml="10px">
								<Text variant="subtitle">Playlist URL</Text>
								<Input
									type="text"
									placeholder="Playlist URL"
									mt="10px"
									fontWeight="500"
									h="50px"
									p="10px"
									value={listID}
									onChange={(e) => setListID(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>
							<Flex direction="column" flex="1" ml="10px">
								<Text variant="subtitle" mb="10px">Embedded Link</Text>
								<Button onClick={() => setEmbedded(true)} flex="1" variant="secondary" h="40px" p="10px 0">Embed Video</Button>
							</Flex>
						</Flex>
					}
					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						{
							playlistData ?
							<Flex as={Button} variant="primary" isLoading={loading} onClick={handlePlaylist} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
								<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create {playlistData.length} videos</Text>
							</Flex>
							:
							<Flex as={Button} variant="primary" isDisabled={validate({ title, description, image, language, url, embedded, embeddedImage, embeddedURL })} isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
								<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create video</Text>
							</Flex>
						}
					</Flex>
					<input type="file" style={{ display:"none", opacity: 0 }} accept="image/png, image/jpg, image/jpeg" ref={inputRef} onChange={handleImage} />
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function validate({ title, description, image, language, url, embedded, embeddedImage, embeddedURL }) {
	if (isEmpty(title)) {
		return true
	}
	if (isEmpty(description)) {
		return true
	}
	if (isEmpty(language)) {
		return true
	}
	if (embedded == true) {
		if (isEmpty(embeddedImage)) {
			return true;
		}
		if (isEmpty(embeddedURL)) {
			return true;
		}
	} else {
		if (isEmpty(image)) {
			return true
		}
		if (isEmpty(url)) {
			return true
		}
	}
	return false;
}







