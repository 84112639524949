import { useState } from "react";
import { Flex, Text, Button, SimpleGrid, Image, IconButton, Menu, MenuButton, MenuList, MenuItem, MenuDivider } from '@chakra-ui/react';
import { Header, AddMusic, EditArtist, EditMusic, CreateArtist } from "../includes";
import { useMusicArtists, useMusic, useDeleteMusic } from "../hooks";
import { useAudioPlayer } from 'react-use-audio-player';

export default function Music() {
	const { load, togglePlayPause, playing, paused, isReady } = useAudioPlayer();
	const [addMusic, setAddMusic] = useState(false);
	const [createArtist, setCreateArtist] = useState(false);

	const [editArtist, setEditArtist] = useState();
	const [editMusic, setEditMusic] = useState();
	const [activeMusic, setActiveMusic] = useState();

	const { data: allMusic = [] } = useMusic();
	const { mutateAsync: deleteMusic } = useDeleteMusic();
	const { data: musicArtists = [] } = useMusicArtists();

	function onPlay(music){
		setActiveMusic(music.id);
		let fullURL = `${process.env.REACT_APP_CDN_PATH}${music.get('url')}`

		console.log(fullURL)
		load(fullURL, {
			autoplay: true,
			html5: true,
			format: 'mp3'
		});
	}

	function onPause() {
		if (activeMusic) {
			togglePlayPause();
		}
	}

	async function onDelete(music) {
		if (window.confirm("Are you sure you want to delete this music?")) {
			await deleteMusic(music.id);
		}
	}

	return (
		<Flex direction="column" h={window.innerHeight}>
			<Header />
			<Flex flex="1" direction="column" p="30px 40px" w="100%" alignSelf="center">
				<Flex align="center" justify="center" mb="20px">
					<Flex flex="1" direction="column">
						<Text variant="title">Music</Text>
						<Text variant="description">Add artists and music directly from youtube.</Text>
					</Flex>
					<Button onClick={() => setCreateArtist(true)} variant="secondary" mr="10px" borderRadius="30px" fontSize="14px" p="12px 15px">Create artist</Button>
					<Button onClick={() => setAddMusic(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Add music</Button>
				</Flex>
				<Flex w="100%" m="0 auto" flex="1" border="1px solid #f3f3f3" borderRadius="10px">
					<Flex flex="1" mr="20px" borderRight="1px solid #f3f3f3" p="15px" pb="0" direction="column">
						<Text variant="subtitle" fontSize="14px" mb="10px">Artists ({musicArtists.length})</Text>
						<Flex overflow="scroll" maxH="830px" direction="column">
							<SimpleGrid columns={3} spacing={5}>
								{
									musicArtists.map(artist => (
										<ArtistRow key={artist.id} onEdit={() => setEditArtist(artist)} data={artist} totalMusic={allMusic.filter(e => e.get('artist').id == artist.id).length} />
									))
								}
							</SimpleGrid>
						</Flex>
					</Flex>
					<Flex flex="1" p="15px" pl="0" pb="0" direction="column">
						<Text variant="subtitle" fontSize="14px">Music ({allMusic.length})</Text>
						<Flex overflow="scroll" maxH="830px" direction="column">
							{
								allMusic.map(music => (
									<MusicRow onPlay={onPlay} isReady={isReady} isPlaying={playing} isPaused={paused} onDelete={() => onDelete(music)} activeMusic={activeMusic} onPause={onPause} key={music.id} onEdit={() => setEditMusic(music)} data={music} artist={musicArtists.filter(e => e.id == music.get('artist').id)[0]} />
								))
							}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			{
				addMusic && <AddMusic onClose={() => setAddMusic(false)} allMusic={allMusic} artists={musicArtists} />
			}
			{
				editArtist && <EditArtist artist={editArtist} onClose={() => setEditArtist()} />
			}
			{
				editMusic && <EditMusic music={editMusic} artists={musicArtists} onClose={() => setEditMusic()} />
			}
			{
				createArtist && <CreateArtist artists={musicArtists} onClose={() => setCreateArtist(false)} />
			}
		</Flex>
	)
}

function ArtistRow({ data, totalMusic, onEdit }){

	function handleEdit(e) {
		e.stopPropagation();
		e.preventDefault();
		onEdit()
	}

	return (
		<Flex as="a" target="_blank" href={`https://www.youtube.com/channel/${data.get('youtubeChannelID')}`} cursor="pointer" borderRadius="7px" p="15px" pt="30px" pb="15px" border="1px solid #f3f3f3" align="center" justify="center" direction="column">
			<Image
				src={process.env.REACT_APP_CDN_PATH + data.get('image')}
				w="80px"
				h="80px"
				borderRadius="105px"
			/>
			<Text mt="10px" fontWeight="600" fontSize="15px" textAlign="center">{data.get('title')['english']}</Text>
			<Text variant="subtitle" mt="3px" fontSize="13px">{totalMusic} song{totalMusic == 1 ? "" : "s"}</Text>

			<Button onClick={handleEdit} variant="secondary" mt="30px" w="100%">Edit</Button>
		</Flex>
	)
}

function MusicRow({ data, artist, onEdit, activeMusic, onPause, onPlay, isPlaying, isPaused, isReady, onDelete }){
	return (
		<Flex p="13px" pl="0" pr="0" borderBottom="1px solid #f3f3f3" align="center" justify="center" direction="row">
			<Image
				src={data.get('image')}
				w="80px"
				h="50px"
				objectFit="cover"
				borderRadius="5px"
			/>
			<Flex flex="1" pl="20px" pr="10px" direction="column">
				<Text fontWeight="600" fontSize="16px" noOfLines="1">{data.get('title')}</Text>
				<Text variant="subtitle" fontWeight="500" fontSize="11px" mt="3px">{artist ? artist.get('title')['english'] : "..."} | {data.get('downloads')} download{data.get('downloads') == 1 ? "" : "s"}</Text>
			</Flex>
			<Flex onClick={activeMusic == data.id ? isReady == true ? () => onPause(data) : null : () => onPlay(data)} w="27px" h="27px" bg="light" color="lightText" align="center" cursor="pointer" fontSize="14px" justify="center" borderRadius="30px">
				{
					isPlaying && activeMusic == data.id ?
					<div key="audio-music-row-play"><i className="fa-solid fa-pause"></i></div>
					:
					<div key="audio-music-row-pause"><i className="fa-solid fa-play"></i></div>
				}
			</Flex>
			<Menu>
				<MenuButton>
					<Flex w="20px" h="30px" align="center" justify="flex-end" fontSize="15px" color="lightText" cursor="pointer">
						<div><i className="fa-solid fa-ellipsis-vertical"></i></div>
					</Flex>
				</MenuButton>
				<MenuList>
					<MenuItem onClick={onEdit} color="dark" fontWeight="500" fontSize="15px">Edit</MenuItem>
					<MenuDivider mt="0" mb="0" />
					<MenuItem onClick={onDelete} color="error" fontWeight="500" fontSize="15px">Delete</MenuItem>
				</MenuList>
			</Menu>
		</Flex>
	)
}

