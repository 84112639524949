import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useVideoAlbums = () => {
	return useQuery(['video-albums'],
		async () => {
			const query = new Parse.Query("VideoAlbums");
			query.equalTo('MultiLanguageGroup', null);
			let data = await query.find();

			let albums = [];
			await Promise.all(data.map(async album => {
				const query2 = new Parse.Query("Videos");
				query2.equalTo('album', album);
				let album_video = await query2.find();

				albums.push({
					"id":album.id,
					"live": album.get('live'),
					"title":album.get('title'),
					"category": album.get('category'),
					"order":album.get('order'),
					"language":album.get('language'),
					"videos":album_video
				})
			}));

			const query2 = new Parse.Query("MultiLanguageGroups");
			query2.equalTo('type', 'albums');
			let _data = await query2.find();

			await Promise.all(_data.map(async group => {
				let _group_data = {
					"id":group.id,
					"live": group.get('live'),
					"albums":[]
				}

				const albumQuery = new Parse.Query("VideoAlbums");
				albumQuery.equalTo('MultiLanguageGroup', group);
				albumQuery.ascending('order');
				let all_albums = await albumQuery.find();

				if (all_albums.length > 0) {
					_group_data.order = all_albums[0].get('order');
				}

				await Promise.all(all_albums.map(async alb => {
					const query2 = new Parse.Query("Videos");
					query2.equalTo('album', alb);
					let album_video = await query2.find();

					_group_data.albums.push({
						"id":alb.id,
						"live": alb.get('live'),
						"title":alb.get('title'),
						"category": alb.get('category'),
						"order":alb.get('order'),
						"language":alb.get('language'),
						"videos":album_video						
					})
				}))	
				albums.push(_group_data)
			}))

			albums.sort((a, b) => a.order - b.order)
			return albums;
		},
		{}
	)
}

export const useAddVideoAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const VideoAlbums = Parse.Object.extend("VideoAlbums");
		const new_video_album = new VideoAlbums();

		new_video_album.set("title", data.title);
		new_video_album.set("category", {
			"__type":"Pointer",
			"objectId":data.category,
			"className":"Categories"
		});
		new_video_album.set("order", data.order);
		new_video_album.set("language", data.language);
		new_video_album.set('live', false);
		await new_video_album.save();
		queryClient.refetchQueries(['video-albums']);

		return new_video_album;	
	}, {
		...options,
	})
}

export const useEditVideoAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("VideoAlbums");
		let video_album  = await query.get(data.id)

		video_album.set("category", {
			"__type":"Pointer",
			"objectId":data.category,
			"className":"Categories"
		});
		video_album.set("title", data.title);
		video_album.set("language", data.language);
		video_album.set('live', data.live || false);

		await video_album.save();

		queryClient.refetchQueries(['video-albums']);

		return video_album;	
	}, {
		...options,
	})
}

export const useDeleteAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("VideoAlbums");
		let album  = await query.get(data.id)

		const query2 = new Parse.Query("Videos");
		query2.equalTo('album', album);
		let album_videos = await query2.find();

		await Promise.all(album_videos.map(async video => {
			await video.destroy();
		}));

		await album.destroy();

		queryClient.refetchQueries(['videos']);
		queryClient.refetchQueries(['video-albums']);
	}, {
		...options,
	})
}







