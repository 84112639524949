import { Flex, Text } from "@chakra-ui/react";

export function Segment({ options, chosen, onChoose }){

	if (!options) {
		return
	}

	return (
		<Flex align="center">
			{
				options.map((option, i) => {
					let id = option.id || "All";
					let type = option.type;

					return (
						<Flex key={i} onClick={() => onChoose(id)} color={chosen == id ? "white" : 'dark' } bg={chosen == id ? "dark" : "light"} p="6px 12px" cursor="pointer" align="center" justify="center" borderRadius="30px" mr="10px" transition="0.3s all">
							<Text fontSize="13px" fontWeight="600" opacity={chosen == id ? 1 : 0.5 } color="default">{ option.title }</Text>
						</Flex>
					)
				})
			}
		</Flex>
	)
}