import { useState, useRef } from "react";
import { Flex, Text, Button, Input, Spinner, Popover, PopoverTrigger, PopoverContent, PopoverArrow, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useClipboard } from '@chakra-ui/react';

import Parse from "parse";
import CodeMirror from '@uiw/react-codemirror';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { json } from '@codemirror/lang-json';

import { Header } from "../includes";
import { isEmpty } from "../utils";

export default function Search() {
	const scrollView = useRef();
	const [search, setSearch] = useState("");
	const [type, setType] = useState("chat");

	const [loading, setLoading] = useState(false);

	const [history, setHistory] = useState([{
		"role":"system",
		"content":"Hi! How can I help?"
	}]);

	function onEnter(e){
		if (e.key == "Enter" && isEmpty(search) == false) {
			handleSearch();
		}
	}

	async function handleSearch(){
		setLoading(true);
		let _history = [...history];
		_history.push({
			"role":"user",
			"content": search
		})
		setHistory(_history)
		setSearch("")
		
		setTimeout(() => {
			scrollToBottom();
		},100)

		// send API with history and current target prompt
		try {
			let response = await Parse.Cloud.run('search-by-shia-ai', {
				prompt: search,
				history: history.filter(e => e.type == "error"),
				type,
				tags: []
			});

			if (type == "chat") {
				_history.push({
					"role":"system",
					"content": response.answer || response.Answer,
					"metadata": response.metadata
				})
			} else {
				_history.push({
					"role":"system",
					"content": response.questions,
					"contentType":"code"
				})
			}

			setHistory(_history)

			setTimeout(() => {
				scrollToBottom();
			},100)			
		}
		catch(error) {
			console.log(error)
			_history.push({
				"role":"system",
				"type":"error",
				"content": error.message
			})
			setHistory(_history)
			setTimeout(() => {
				scrollToBottom();
			},100)
		}
		setLoading(false);
	}

	function scrollToBottom(){
		scrollView.current.scrollTop = scrollView.current.scrollHeight;
	}

	function clearChat(){
		setHistory([{
			"role":"system",
			"content":"Hi! How can I help?"
		}])
	}

	function handleTab(type){
		setType(type);
		// clearChat();
	}

	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px" pt="30px" h={window.innerHeight - 130} align="center" justify="center">
				<Flex maxW="700px" w="100%" direction="column" flex="1" border="1px solid #f3f3f3" borderRadius="15px">
					<Flex borderBottom="1px solid #f3f3f3" p="5px 10px" w="100%" align="center">
						<Flex direction="row" flex="1" align="center">
							<Text fontWeight="600" fontSize="15px">Chat Session</Text>
						</Flex>
						<Button onClick={clearChat} ml="5px" variant="white" borderRadius="30px" pt="10px" pb="10px">Clear chat</Button>
					</Flex>
					<Flex ref={scrollView} direction="column" flex="1" bg="#fff" p="15px" pb="0" overflow="scroll"  maxH="800px">
						{
							history.map((hist, i) => {
								if (hist.role == "user") {
									return <UserMessageRow key={i} index={i} data={hist} />
								} else {
									return <SystemMessageRow key={i} index={i} data={hist} />
								}
							})
						}
						{
							loading && <LoadingRow />
						}
					</Flex>
					<Flex borderTop="1px solid #f3f3f3" direction="column" position="relative" p="15px" align="center">
						
						<Flex w="100%" mb="10px">
							<Button onClick={() => handleTab('chat')} variant={type == "chat" ? "error" : "secondary"} borderRadius="5px" pt="10px" pb="10px">Chat</Button>
							<Button onClick={() => handleTab('questionnaire')} ml="5px" variant={type == "questionnaire" ? "error" : "secondary"} borderRadius="5px" pt="10px" pb="10px">Questionnaire</Button>
						</Flex>

						<Flex flex="1" w="100%" align="center">
							<Input
								type="text"
								placeholder={type == "questionnaire" ? "Generate 10 multiple choice questions about" : "What is your question?"}
								bg="#fafafa"
								borderRadius="30px"
								autoFocus
								pl="20px"
								pr="10px"
								onKeyDown={onEnter}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								fontSize="15px"
								border="none"
								flex="1"
								fontWeight="500"
								h="50px"
							/>
							<Button onClick={handleSearch} isDisabled={loading || isEmpty(search)} ml="10px" p="10px 20px" fontSize="15px" borderRadius="30px" variant="primary">Send</Button>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

function LoadingRow(){
	return (
		<Flex w="100%" mb="15px" align="flex-start" justify="flex-start">
			<Flex bg="#0097f5" p="10px 10px" borderRadius="30px">
				<Spinner size="sm" color="white" />
			</Flex>
		</Flex>
	)
}
function UserMessageRow({ data }){
	return (
		<Flex w="100%" mb="15px" align="flex-end"  justify="flex-end">
			<Flex bg="light" p="12px 15px" maxW="500px" borderRadius="30px">
				<Text color="lightText" fontWeight="600">{data.content}</Text>
			</Flex>
		</Flex>
	)
}

function SystemMessageRow({ data, index }){
	const { onCopy, setValue, hasCopied } = useClipboard(JSON.stringify(data.content, null, 4))

	function onCopyData(){
		onCopy();
	}

	return (
		<Flex w="100%" mb="15px" align="flex-start">
			<Flex direction="column" w="100%" align="flex-start">
				{
					data.contentType == "code" ?
					<Flex flex='1' direction="column" borderRadius="10px" w="100%" align="flex-start">
						<Flex w="100%" align="center" justify="center" mb="15px">
							<Flex maxW="500px" bg="#0097f5" p="12px 15px" maxH="500px" overflow="scroll" borderRadius="30px">
								<Text color="white" fontWeight="600">These are {data.content.length} questions:</Text>
							</Flex>
							<Flex flex='1'></Flex>
							<Button onClick={onCopyData} variant="secondary">{hasCopied ? "Copied!" : "Copy"}</Button>
						</Flex>
						<Accordion  border="1px solid" borderColor="light" p="10px" pl="0" pr="0" borderRadius="10px" allowToggle w="95%" flex="1">
							{
								data.content.map((question, i) => (
									<AccordionItem key={i} w="100%">
										<AccordionButton w="100%" pl="10px" pr="10px" pt="10px" pb='10px'>
											<Text flex="1" w="100%" textAlign="left">{question.question}</Text>
											<Flex mr="-50px">
												{
													question.metadata && Object.keys(question.metadata).length > 0 && (
														<MetadataPopover metadata={question.metadata} index={i} />
													)
												}
											</Flex>
										</AccordionButton>
										<AccordionPanel pt={0} pb={4}>
									    	{
									    		question.options.map((option, i2) => (
													<RadioOption key={i2} answer={question.answer} text={option} />
									    		))
									    	}
									    </AccordionPanel>
									</AccordionItem>
								))
							}
						</Accordion>
					</Flex>
					:
					<Flex maxW="500px" align="center" justify="center">
						<Flex bg="#0097f5" p="12px 15px" borderRadius="30px">
							<Text color="white" fontWeight="600">{data.content}</Text>
						</Flex>
						{
							data.metadata && Object.keys(data.metadata).length > 0 && (
								<MetadataPopover metadata={data.metadata} index={index} />
							)
						}
					</Flex>
				}
			</Flex>
		</Flex>
	)
}

function MetadataPopover({ metadata, index }){
	return (
		<Popover key={`popover-section-question-${index}`}>
			<PopoverTrigger>
				<Flex w="25px" h="25px" ml="10px" align="center" justify="center" color="lightText" fontSize="15px" cursor="pointer">
					<div><i className="fa-solid fa-circle-info"></i></div>
				</Flex>
			</PopoverTrigger>
			<PopoverContent w="350px" >
				<PopoverArrow />
				<Flex direction="column" w="100%" p="3px 10px">
					{
						Object.keys(metadata).map(key => {
							if (key != "source" && key != "tags" && key != "author" && key != "order" && key != "translator" && key != "distance" && key != "url") {
								return (
									<Flex key={key} direction="row" pb="5px" pt="5px">
										<Text variant="subtitle" textTransform="capitalize" mb="5px" pr="20px" flex="1">{key}:</Text>
										<Text fontSize="14px" textAlign="right">{metadata[key]}</Text>
									</Flex>	
								)
							}						
						})
					}
					{
						metadata['source'] && metadata['source'].url && metadata['source'].url != "" ?
						<Button as="a" href={metadata['source'].url} target="_blank" cursor="pointer" variant="secondary" mt="10px" mb="10px" pt="12px" pb="12px">View Source 1</Button>
						:
						null
					}
					{
						metadata['url'] ?
						<Button as="a" href={metadata['url']} target="_blank" cursor="pointer" variant="secondary" mt="10px" mb="10px" pt="12px" pb="12px">View Source 2</Button>
						:
						null
					}
				</Flex>
			</PopoverContent>
		</Popover>
	)
}



function RadioOption({ answer, text }){
	return (
		<Flex align="center" justify="center" borderBottom="1px solid" borderBottomColor="light" bg="white">
			<Flex cursor="pointer" w="35px" h="50px" mr="5px" align="center" justify="center">
				<Flex w="20px" h="20px" borderRadius="30px" border="2px solid" borderColor={answer == text ? "primary" : "lightText"} align="center" justify="center">
					{
						answer == text && <Flex w="10px" h="10px" borderRadius="30px" bg="primary"></Flex>
					}
				</Flex>
			</Flex>

			<Flex flex="1">
				<Input
					type="text"
					variant="underline"
					placeholder="Option title"
					bg="white"
					value={text}
					p="0"
					fontSize="15px"
					_placeholder={{
						opacity:0.5
					}}
					fontWeight="500"
				/>
			</Flex>
		</Flex>
	)
}





