import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Image,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useEditMusic } from "../../hooks";

export function EditMusic({ music, artists, onClose }) {
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(music.get('title'));
	const [savedChannelId, setSavedChannelId] = useState(music.get('artist').id);
	const [live, setLive] = useState(music.get('live') || true);
	const { mutateAsync: onEditMusic } = useEditMusic();

	async function handleSubmit(){
		setLoading(true);
		try {
			await onEditMusic({
				musicId: music.id,
				title,
				artistId: savedChannelId,
				live
			})
			
			onClose();
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}

		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Edit music</Text>

					<Flex direction="column" pb="50px">
						
						<Image
							src={music.get('image')}
							w="100%"
							h="200px"
							objectFit='contain'
							bg="#f3f3f3"
							borderRadius="10px"
						/>

						<Flex flex="1" mt="15px" direction="column">
							<Text variant="subtitle" mb="5px">Title</Text>
							<Input
								type="text"
								placeholder="Preset Name"
								fontWeight="500"
								h="55px"
								p="10px"
								fontSize="15px"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:"2px"
								}}
							/>			
						</Flex>
						
						{
							artists.length > 0 && (
								<>
									<Text variant="subtitle"  mt="20px">Artist</Text>
									<Select value={savedChannelId} onChange={(e) => setSavedChannelId(e.target.value)} mt="5px" w="100%" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select artist'>
										{
											artists.map(artist => (
												<option key={artist.id} value={artist.id}>{artist.get('title')['english']}</option>
											))
										}
									</Select>
								</>
							)
						}

						<Flex mt="20px" align="center" justify="center">
							<Text flex="1" variant="subtitle">Live</Text>
							<Switch isChecked={live} onChange={(e) => setLive(e.target.checked)} colorScheme="green" size='md' />
						</Flex>
					</Flex>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary"  isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Save music</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}








