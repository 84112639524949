import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useQuestionnaireSections = (id) => {
	return useQuery(['questionnaires-sections', id],
		async () => {
			const query = new Parse.Query("QuestionnaireSections");
			query.equalTo("questionnaire", {
				"__type":"Pointer",
				"objectId":id,
				"className":"Questionnaires"
			});

			let data = await query.find();
			await Promise.all(data.map(async section => {
				const unit_query = new Parse.Query("QuestionnaireUnits");
				unit_query.equalTo("section", {
					"__type":"Pointer",
					"objectId":section.id,
					"className":"QuestionnaireSections"
				});
				section.unitCount = await unit_query.count();

				const lesson_query = new Parse.Query("QuestionnaireLessons");
				lesson_query.equalTo("section", {
					"__type":"Pointer",
					"objectId":section.id,
					"className":"QuestionnaireSections"
				});
				section.lessonCount = await lesson_query.count();
			}))

			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}

export const useQuestionnaireSectionByID = (id) => {
	return useQuery(['questionnaire-section', id],
		async () => {
			const query = new Parse.Query("QuestionnaireSections");
			return await query.get(id);
		},
		{}
	)
}

export const useMoveQuestionnaireSection = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async ({ sectionId, old_questionnaire_id, new_questionnaire_id }) => {
		
		const newQuestionnaireSection = new Parse.Query("QuestionnaireSections");
		newQuestionnaireSection.equalTo('questionnaire', {
			"__type":"Pointer",
			"className":"Questionnaires",
			"objectId": new_questionnaire_id
		})
		const questionnaireCount = await newQuestionnaireSection.count();

		const querySections = new Parse.Query("QuestionnaireSections");
		querySections.equalTo('questionnaire', {
			"__type":"Pointer",
			"className":"Questionnaires",
			"objectId": old_questionnaire_id
		})
		querySections.equalTo('objectId', sectionId);

		let active_section = await querySections.first();

		if (active_section) {
			active_section.set('questionnaire', {
				"__type":"Pointer",
				"className":"Questionnaires",
				"objectId": new_questionnaire_id
			})
			active_section.set('order', questionnaireCount)


			const queryUnits = new Parse.Query("QuestionnaireUnits");
			queryUnits.equalTo('section', active_section);
			queryUnits.limit(10000);
			let units = await queryUnits.find();

			await Promise.all(units.map(async unit => {
				unit.set('questionnaire', {
					"__type":"Pointer",
					"className":"Questionnaires",
					"objectId": new_questionnaire_id
				})
				await unit.save();
			}))

			const queryLessons = new Parse.Query("QuestionnaireLessons");
			queryLessons.equalTo('section', active_section)
			queryLessons.limit(10000);
			let lessons = await queryLessons.find();

			await Promise.all(lessons.map(async lesson => {
				lesson.set('questionnaire', {
					"__type":"Pointer",
					"className":"Questionnaires",
					"objectId": new_questionnaire_id
				})
				await lesson.save();
			}))

			const queryQuestions = new Parse.Query("QuestionnaireQuestions");
			queryQuestions.equalTo('section', active_section)
			queryQuestions.limit(10000);
			let questions = await queryQuestions.find();

			await Promise.all(questions.map(async question => {
				question.set('questionnaire', {
					"__type":"Pointer",
					"className":"Questionnaires",
					"objectId": new_questionnaire_id
				})
				await question.save();
			}))

			active_section.save();
		}

		queryClient.refetchQueries(['questionnaires-sections', old_questionnaire_id]);

	}, {
		...options,
	})
}

export const useEditQuestionnaireSection = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const query = new Parse.Query("QuestionnaireSections");
		const old_section = await query.get(data.id);

		old_section.set("title", data.title);
		old_section.set("description", data.description);
		old_section.set("fullTitle", data.titleTrans);
		old_section.set("fullDescription", data.descriptionTrans);
		old_section.set("showTitle", data.showTitle);

		await old_section.save();

		queryClient.refetchQueries(['questionnaires-sections', data.questionnaireID]);

		return old_section;	
	}, {
		...options,
	})
}

export const useCreateQuestionnaireSection = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const QuestionnaireSections = Parse.Object.extend("QuestionnaireSections");
		const new_section = new QuestionnaireSections();

		new_section.set("type", "level");
		new_section.set("title", data.title);
		new_section.set("description", data.description);
		new_section.set('questionnaire',{
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		new_section.set("fullTitle", {
			"english":data.title,
			"arabic":"",
			"persian":""
		});
		new_section.set("fullDescription", {
			"english": data.description,
			"arabic":"",
			"persian":""
		});
		new_section.set("showTitle", true);

		const query = new Parse.Query("QuestionnaireSections");
		query.equalTo('questionnaire', {
			"__type":"Pointer",
			"objectId":data.questionnaireID,
			"className":"Questionnaires"
		})
		const totalSections = await query.count();

		new_section.set('order', totalSections);

		await new_section.save();

		queryClient.refetchQueries(['questionnaires-sections', data.questionnaireID]);

		return new_section;	
	}, {
		...options,
	})
}

export const useEditQuestionnaireSectionOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async ({ data, questionnaireID }) => {

		queryClient.setQueryData(['questionnaires-sections', questionnaireID], data);
		
		await Promise.all(data.map(async (ques, index) => {
			ques.set('order', index);
			await ques.save();
		}));

	}, {
		...options,
	})
}

export const useDeleteQuestionnaireSection = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("QuestionnaireSections");
		let old_section  = await query.get(data.id)

		if (old_section) {
			const query_units = new Parse.Query("QuestionnaireUnits");
			query_units.equalTo('section', old_section);
			let unit_data = await query_units.find();
			await Promise.all(unit_data.map(async unit => {
				await unit.destroy();
			}));

			const query_questions = new Parse.Query("QuestionnaireQuestions");
			query_questions.equalTo('section', old_section);
			let questions_data = await query_questions.find();
			await Promise.all(questions_data.map(async data => {
				await data.destroy();
			}));

			const query_lessons = new Parse.Query("QuestionnaireLessons");
			query_lessons.equalTo('section', old_section);
			let lessons_data = await query_lessons.find();
			await Promise.all(lessons_data.map(async data => {
				await data.destroy();
			}));

			await old_section.destroy();
			queryClient.refetchQueries(['questionnaires-sections', data.questionnaireID]);

		}
	}, {
		...options,
	})
}




