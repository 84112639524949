import { useState } from "react";
import { Flex, Text, Button, SimpleGrid, Skeleton, Image, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { format } from "date-fns";

import { Header, AddPersonModal, EditPersonModal } from "../includes";
import { useQuranPersons, useEditQuranPersonOrder } from "../hooks";

export default function Quran() {
	const [addPerson, setAddPerson] = useState(false);
	const [editPerson, setEditPerson] = useState();

	const { data } = useQuranPersons();
	const { mutateAsync: updateOrder } = useEditQuranPersonOrder();

	async function onDragEnd({ source, destination }){
	    let _data = [...data];
	    if (source && destination) {
	        const [removed] = _data.splice(source.index, 1)
	        _data.splice(destination.index, 0, removed);

	        updateOrder(_data);
	    }
	}

	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px">
				<Flex align="center" justify="center" mb="10px">
					<Flex flex="1" direction="column">
						<Text mb="7px" variant="title">Khatmat Quran</Text>
					</Flex>
					<Button onClick={() => setAddPerson(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Add person</Button>
				</Flex>
				<Flex w="100%" border="1px solid" borderColor="light" borderRadius="10px" overflow="hidden">
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th w="40px" pl="0" pr="0"></Th>
								<Th><Text variant="subtitle" textTransform="capitalize">Name</Text></Th>
								<Th><Text variant="subtitle" textTransform="capitalize">Created by</Text></Th>
								<Th><Text variant="subtitle" textTransform="capitalize">Updated on</Text></Th>
								<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Type</Text></Th>
								<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Total Khatmat</Text></Th>
								<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Score</Text></Th>
								<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Status</Text></Th>
								<Th isNumeric></Th>
							</Tr>
						</Thead>
						{
							!data ?
							null
							:
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="quran-persons-wrapper">
									{(provided) => (
										<Tbody flexGrow="1" ref={provided.innerRef} {...provided.droppableProps} w="100%">
											{
												data.map((person, i) => (
													<TableRow key={person.id} index={i} data={person} onEdit={() => setEditPerson(person)} />
												))
											}
											{ provided.placeholder }
										</Tbody>
									)}
								</Droppable>
							</DragDropContext>
						}
					</Table>
				</Flex>
			</Flex>
			{
				addPerson && <AddPersonModal total={data.length} onClose={() => setAddPerson(false)} />
			}
			{
				editPerson && <EditPersonModal data={editPerson} onClose={() => setEditPerson()} />
			}
		</Flex>
	)
}

function TableRow({ data, onEdit, index }){
	const status = data.get('status');
	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<Tr {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} w="100%" bg="white" _hover={{ backgroundColor:"light" }} transition="0.3s all">
					<Td pt="10px" pb="10px" pl="0" pr="0" fontSize="13px" w="40px" color="lightText" fontWeight="500">
						<Flex cursor="drag" w="40px" h="30px" align="center" justify="center" borderRadius="30px">
							<div><i className="fa-solid fa-grip-vertical"></i></div>
						</Flex>
					</Td>
					<Td pt="10px" pb="10px" fontSize="15px" fontWeight="500">
						<Flex align="center">
							<Image
								src={data.get('image')}
								alt="Image"
								w="35px"
								h="35px"
								borderRadius="30px"
								objectFit="cover"
								mr="12px"
							/>
							{data.get('title')['english']}
						</Flex>
					</Td>
					<Td pt="10px" pb="10px" fontSize="12px" fontWeight="500">{data.get('createdBy').get('firstName')}</Td>
					<Td pt="10px" pb="10px" fontSize="12px" fontWeight="500">{format(data.get('updatedAt'), 'MMMM dd, yyyy')}</Td>

					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="600" textTransform="capitalize" isNumeric>{data.get('type')}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="600" isNumeric>{data.get('totalKhatmat')}</Td>
					<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" isNumeric>0</Td>
					<Td pt="10px" pb="10px" fontSize="14px" textTransform="capitalize" fontWeight="500" color={status == "live" || status == "finished" ? "success" : status == "pending" || status == "not-live" ? "warning" : status == "rejected" ? "error" : "primary"} isNumeric>{status}</Td>
					<Td pt="10px" pb="10px" isNumeric pr="10px">
						<Button onClick={onEdit} variant="white">Edit</Button>
					</Td>
				</Tr>
			)}
		</Draggable>
	)
}








