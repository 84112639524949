import { useEffect, useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	Spinner,
	Button
} from "@chakra-ui/react";
import { isEmpty, imageExists, comma } from "../../utils";
import { useQuestionnaireSections, useQuestionnaireAllUnits, useQuestionnaireAllQuestions } from "../../hooks";

export function QuestionnaireLiveChecks({ data, onClose, onLiveModalStatus }) {
	const { data: sections } = useQuestionnaireSections(data.id);
	const { data: units } = useQuestionnaireAllUnits(data.id);
	const { data: questions } = useQuestionnaireAllQuestions(data.id);

	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState();
	const [errors, setErrors] = useState([]);
	const languages = ['english', 'arabic', 'persian'];

	useEffect(() => {
		if (data && sections && units && questions) {
			questionnaireChecks();
		}
	},[data, sections, units, questions])

	async function questionnaireChecks(){
		setLoading(true);
		setStatus('Checking Questionnaire...');
		
		let _errors = [];
		if (isEmpty(data.get('image')) && await imageExists(data.get('image')) == false) {
			_errors.push({
				"type":"image",
				"tagColor":"#e91e63",
				"component":"Questionnaire",
				"message":"Please upload a working image"
			})
		}

		if (data.get('order') == null) {
			_errors.push({
				"type":"order",
				"tagColor":"#e91e63",
				"component":"Questionnaire",
				"message":"The questionnaire has no order value"
			})			
		}

		if (isEmpty(data.get('fullTitle'))) {
			_errors.push({
				"type":"fullTitle",
				"tagColor":"#e91e63",
				"component":"Questionnaire",
				"message":"There is no fullTitle object"
			})
		} else {
			languages.map(key => {
				if (isEmpty(data.get('fullTitle')[key])) {
					_errors.push({
						"type":"fullTitle.translation.title",
						"tagColor":"#e91e63",
						"component":"Questionnaire",
						"message":`There is no ${key} title translation`
					})					
				}
			})
		}

		if (isEmpty(data.get('fullDescription'))) {
			_errors.push({
				"type":"fullDescription",
				"tagColor":"#e91e63",
				"component":"Questionnaire",
				"message":"There is no fullDescription object"
			})
		} else {
			languages.map(key => {
				if (isEmpty(data.get('fullDescription')[key])) {
					_errors.push({
						"type":"fullDescription.translation.description",
						"tagColor":"#e91e63",
						"component":"Questionnaire",
						"message":`There is no ${key} description translation`
					})					
				}
			})
		}		

		if (sections.length == 0) {
			_errors.push({
				"type":"sections.counts",
				"tagColor":"#e91e63",
				"component":"Questionnaire",
				"message":`You must have at least one section.`
			})					
		}

		handleSections(_errors);
	}

	async function handleSections(_errors){
		setStatus('Checking Questionnaire Sections...');

		sections.map(sect => {
			let title = sect.get('fullTitle') ? sect.get('fullTitle')['english'] : sect.get('title');
			
			if (sect.get('order') == null) {
				_errors.push({
					"type":"order",
					"tagColor":"#3f1ee9",
					"component":"Sections",
					"sectId": sect.id,
					"message":`${title} has no order value`
				})			
			}
			if (isEmpty(sect.get('fullTitle'))) {
				_errors.push({
					"type":"fullTitle",
					"tagColor":"#3f1ee9",
					"component":"Sections",
					"sectId":sect.id,
					"message":`No title translations for ${title}`
				})
			} else {
				languages.map(key => {
					if (isEmpty(sect.get('fullTitle')[key])) {
						_errors.push({
							"type":"fullTitle.translation.title",
							"tagColor":"#3f1ee9",
							"component":"Sections",
							"sectId":sect.id,
							"message":`There is no ${key} title translation for ${title}`
						})					
					}
				})
			}

			if (isEmpty(sect.get('fullDescription'))) {
				_errors.push({
					"type":"fullDescription",
					"tagColor":"#3f1ee9",
					"component":"Sections",
					"sectId":sect.id,
					"message":`No description translations for ${title}.`
				})
			} else {
				languages.map(key => {
					if (isEmpty(sect.get('fullDescription')[key])) {
						_errors.push({
							"type":"fullTitle.translation.title",
							"tagColor":"#3f1ee9",
							"component":"Sections",
							"sectId":sect.id,
							"message":`There is no ${key} description translation for ${title}.`
						})					
					}
				})
			}
			if (sect.get('live') == false) {
				_errors.push({
					"type":"status.live",
					"tagColor":"#3f1ee9",
					"component":"Sections",
					"sectId":sect.id,
					"message":`${title} needs to be set live or moved to another questionnaire.`
				})
			}			
		})

		handleUnits(_errors);
	}

	async function handleUnits(_errors) {
		setStatus('Checking Questionnaire Units...');

		await Promise.all(units.map(async unit => {
			let title = unit.get('title');
			let sect = unit.get('section');
			let sectTitle = sect.get('fullTitle') ? sect.get('fullTitle')['english'] : sect.get('title');

			if (unit.get('order') == null) {
				_errors.push({
					"type":"order",
					"tagColor":"#e93f1e",
					"component":"Units",
					"unitId": unit.id,
					"sectId":sect.id,
					"message":`${title} unit of section ${sectTitle} has no order value`
				})			
			}

			let _questions = questions.filter(e => e.get('unit').id == unit.id);
			if (_questions.length == 0) {
				_errors.push({
					"type":"order",
					"tagColor":"#e93f1e",
					"component":"Units",
					"unitId": unit.id,
					"sectId": sect.id,
					"message":`${title} unit of section ${sectTitle} has no questions`
				})				
			}
		}))

		handleQuestions(_errors);

	}

	function handleQuestions(_errors) {
		setStatus('Checking Questionnaire Questions...');

		questions.map(quest => {
			let sect = quest.get('section');
			let unit = quest.get('unit');
			let lesson = quest.get('lesson');
			let title = quest.get('title')['english']
			let sectTitle = sect.get('fullTitle') ? sect.get('fullTitle')['english'] : sect.get('title');
			
			if (quest.get('order') == null) {
				_errors.push({
					"type":"order",
					"tagColor":"#8b56fd",
					"component":"Questions",
					"sectId": sect.id,
					"unitId": unit.id,
					"lessonId": lesson.id,
					"message":`${title} of section ${sectTitle} has no order value`
				})			
			}
			
			languages.map(key => {
				if (isEmpty(quest.get('title')[key])) {
					_errors.push({
						"type":"fullTitle.translation.title",
						"tagColor":"#8b56fd",
						"component":"Questions",
						"sectId": sect.id,
						"unitId": unit.id,
						"lessonId": lesson.id,
						"message":`There is no ${key} title translation for "${title}"`
					})					
				}
			})

			let audio = quest.get('audio');
			languages.map(key => {
				if (isEmpty(audio['start'][key])) {
					_errors.push({
						"type":"audio.translation.title",
						"tagColor":"#8b56fd",
						"component":"Questions",
						"sectId": sect.id,
						"unitId": unit.id,
						"lessonId": lesson.id,
						"message":`There is no ${key} audio for "${title}"`
					})						
				} else {
					// check if audio file exists to play
				}
			})

			if (isEmpty(quest.get('answer'))) {
				_errors.push({
					"type":"answer",
					"tagColor":"#8b56fd",
					"component":"Questions",
					"sectId": sect.id,
					"unitId": unit.id,
					"lessonId": lesson.id,
					"message":`There is no answer set for "${title}"`
				})					
			}

			let options = quest.get('options');
			if (options.length == 0) {
				_errors.push({
					"type":"options",
					"tagColor":"#8b56fd",
					"component":"Questions",
					"sectId": sect.id,
					"unitId": unit.id,
					"lessonId": lesson.id,
					"message":`There is no answer options for "${title}"`
				})				
			} else {
				options.map(opt => {
					languages.map(key => {
						if (isEmpty(opt.title[key])) {
							_errors.push({
								"type":"option.translation",
								"tagColor":"#8b56fd",
								"component":"Questions",
								"sectId": sect.id,
								"unitId": unit.id,
								"lessonId": lesson.id,
								"message":`There is no ${key} translation for option ${opt.title['english']} in "${title}"`
							})					
						}
					})
				})
			}
		})

		setLoading(false);
		setErrors(_errors);
	}

	function handleSetLive(){
		onLiveModalStatus(true);
		onClose(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="md" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<Flex p="20px 15px 0" flex="1" direction="column">
					{
						loading ?
						<Flex w="100%" flex="1" minH="700px" align="center" justify="center" direction="column">
							<Spinner size="md" color="primary" thickness="3px" />
							<Text variant="subtitle" fontSize="15px" mt="10px">{status}</Text>
						</Flex>
						:
						<Flex w="100%" flex="1" align="flex-start" justify="flex-start" direction="column">
							<Text variant="title" fontSize="21px" mb="0px">{comma(errors.length)} errors found</Text>
							<Flex overflow="scroll" maxH="740px" w="100%" mt="10px" flex="1" h="100%">
								<Flex flex="1" direction="column">
									{
										errors.length == 0 ?
										<Flex h="300px" w="100%" align="center" justify="center">
											<Text textAlign="center" variant="subtitle" fontSize="18px">Once you go live, you cannot add or remove any sections, units or lessons. <br /><br />You will be able to update titles, descriptions, images and add new questions.</Text>
										</Flex>
										:
										errors.map((error, index) => {
											return (
												<ErrorBox key={index} questionnaireId={data.id} data={error} />
											)
										})
									}
								</Flex>
							</Flex>
							<Flex pt='15px' pb="15px" w="100%" borderTop="1px solid" borderTopColor="light">
								<Button isDisabled={errors.length > 0} onClick={errors.length > 0 ? null : handleSetLive} variant="success" w="100%" fontSize="16px" pt="20px" pb="20px">Go live</Button>
							</Flex>
						</Flex>
					}
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function ErrorBox({ data, questionnaireId }){
	let link = window.location.origin + "/";
	if (data.component == "Questionnaire") {
		link += `questionnaires/${questionnaireId}`
	}
	if (data.component == "Sections" || data.component == "Units") {
		link += `questionnaires/${questionnaireId}/sections/${data.sectId}`
	}
	if (data.component == "Questions") {
		link += `questionnaires/${questionnaireId}/sections/${data.sectId}/lessons/${data.lessonId}`
	}
	return (
		<Flex w="100%" p="13px 15px" border="1px solid" borderColor="light" mb="15px" borderRadius="10px" direction="column" align="flex-start">
			<Flex align="center" mb="15px" w="100%">
				<Flex bg={data.tagColor} borderRadius="30px" p="5px 10px">
					<Text fontSize="12px" fontWeight="600" color="white">{data.component}</Text>
				</Flex>
				<Text flex="1" textAlign="right" variant="subtitle" fontSize="10px" textTransform="uppercase">{data.type}</Text>
			</Flex>
			<Text variant="title" fontWeight="500" mb="10px" fontSize="16px">{data.message}</Text>
			<Flex mt="10px" w="100%" borderTop="1px solid" borderTopColor="light" pt="10px">
				<Button as="a" target="_blank" href={link} w="100%" variant="secondary" pt="10px" pb="10px">Change</Button>
			</Flex>
		</Flex>
	)
}










