import { useState } from "react";

import { Flex, Text, Select, Button, Input } from '@chakra-ui/react';
import { Header } from "../includes";
import Parse from "parse";
import { ExcelPopover } from "../includes/components/questionnaires/editor";

export default function RandomizeQuestions() {
	const [questions, setQuestions] = useState([]);
	const [total, setTotal] = useState("10");
	const [loading, setLoading] = useState(false);
	const [language, setLanguage] = useState('english');

	async function onRandomize(){
		setLoading(true);

		// get questionnaires that are live
		const query = new Parse.Query("Questionnaires");
		query.equalTo('live', true);
		query.select('id')
		let data = await query.find();
		let _questionnaireIds = data.map(a => a.id);

		// using their pointers, get all the questions
		const query2 = new Parse.Query("QuestionnaireQuestions");
		query2.containedIn('questionnaire', _questionnaireIds);
		query2.limit(100000);
		let questions = await query2.find();

		// create a ranomize array based on the length, remove any duplicate numbers
		let randomQuestions = [...Array(parseInt(total))].map(e=>~~(Math.random()*questions.length))

		let _questions = [];
		randomQuestions.map(index => {
			let chosen = questions[index];

			_questions.push({
				"id": chosen.id,
				"title": chosen.get('title'),
				"type": chosen.get('type'),
				"source": chosen.get('source'),
				"options": chosen.get('options'),
				"answer": chosen.get('answer'),
				"helpText": chosen.get('helpText'),
				"audio": chosen.get('audio')
			})
		})

		_questions = _questions.filter((obj1, i, arr) => 
		  arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
		)
		setQuestions(_questions);
		setLoading(false);
	}

	function onRemove(id) {
		let _questions = [...questions];
		let index = _questions.findIndex(e => e.id == id);
		if (index >= 0) {
			_questions.splice(index, 1);
			setQuestions(_questions)
		}
	}




	const languges = ['English', 'Arabic', 'Persian'];

	return (
		<Flex direction="column" h={window.innerHeight}>
			<Header />
			
			<Flex flex="1" direction="column"  w="100%" align="center" justify="center">
				{
					questions.length > 0 ?
					<Flex direction="column" align="center" justify="center" mb="0px" w="700px"  borderRadius="10px">
						<Flex flex="1" w="100%" pb="10px">
							<Text flex="1" variant="title" fontSize="20px">{questions.length} Questions</Text>
							<Flex>
								<Flex mr="10px">
									{
										languges.map(lang => (
											<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
												<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
											</Flex>
										))
									}
								</Flex>
								<ExcelPopover questions={questions} title="rand-que" />
								<Button isLoading={loading} onClick={() => onRandomize()} variant="primary">Regenerate</Button>
							</Flex>
						</Flex>
						<Flex direction="column" maxH="900px" overflow="scroll" w="100%">
							{
								questions.map(question => (
									<QuestionBox key={question.id} data={question} language={language} onRemove={onRemove} />
								))
							}
						</Flex>
					</Flex>
					:

					<Flex direction="column" align="center" justify="center" mb="100px" p="30px 40px" border="2px solid #f3f3f3" borderRadius="10px">
						<Flex color="lightText" bg="light" mb="10px" w="45px" h="45px" fontSize="20px" align="center" justify="center" borderRadius="30px">
							<div><i className="fa-solid fa-shuffle"></i></div>
						</Flex>

						<Text variant="title">Randomize Live Questions</Text>
						<Text variant="description">This tool is best used for tiktok videos.</Text>
					
						<Flex align="center" justify="center" mt="50px" w="500px">
							<Flex direction="column" flex="1" mr="10px">
								<Text variant="subtitle" mb="8px">Total Questions</Text>
								<Select flex="1" fontWeight="600" value={total} onChange={(e) => setTotal(e.target.value)}>
									<option value="7">7</option>
									<option value="9">9</option>
									<option value="10">10</option>
									<option value="15">15</option>
								</Select>
							</Flex>
							<Button onClick={onRandomize} isLoading={loading} flex="1" mt="27px" variant="primary" borderRadius="5px" fontSize="14px" p="12px 15px">Generate</Button>
						</Flex>
					</Flex>
				}
			</Flex>
		</Flex>
	)
}



function QuestionBox({ data, language, onRemove }){
	return (
		<Flex bg="white" border="1px solid #f3f3f3" direction="column" align="flex-start" mb="20px" borderRadius="10px" w="100%">
			<Flex flex="1" p="10px" w="100%" direction="column">
				<Flex w="100%" align="center" justify="center">
					<Input
						type="text"
						placeholder="Write a lesson title"
						flex="3"
						h="50px"
						value={data.title[language]}
						_focus={{
							borderColor:"primary",
							borderWidth:2
						}}
						fontWeight="500"
					/>
				</Flex>
				<Flex direction="column" mt="10px">
					{
						data.options.map((option, index) => (
							<RadioOption key={option.id} question={data} data={option} language={language} />
						))
					}
				</Flex>
			</Flex>
			<Flex align="center" justify="space-between" w="100%" p="10px" borderTop="1px solid #f3f3f3">
				<Flex></Flex>
				<Flex onClick={() => onRemove(data.id)} w="30px" h="30px" color="lightText" cursor="pointer" align="center" justify="center" borderRadius="30px" fontSize="14px">
					<i className="fa-solid fa-trash"></i>
				</Flex>
			</Flex>

		</Flex>

	)
}

function RadioOption({ question, data, language }){
	return (
		<Flex p="7px 0" align="center" justify="center" borderBottom="1px solid" borderBottomColor="light" bg="white">
			<Flex cursor="pointer" w="35px" h="50px" mr="5px" align="center" justify="center">
				<Flex w="20px" h="20px" borderRadius="30px" border="2px solid" borderColor={question.answer == data.id ? "primary" : "lightText"} align="center" justify="center">
					{
						question.answer == data.id && <Flex w="10px" h="10px" borderRadius="30px" bg="primary"></Flex>
					}
				</Flex>
			</Flex>

			<Flex flex="1">
				<Input
					type="text"
					variant="underline"
					placeholder="Option title"
					bg="white"
					value={data.title[language]}
					p="0"
					_placeholder={{
						opacity:0.5
					}}
					fontWeight="500"
				/>
			</Flex>
		</Flex>
	)
}




