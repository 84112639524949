import { useState, useRef } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Textarea,
	Image
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { isEmpty, toBase64 } from "../../utils";
import { useCreateQuestionnaire } from "../../hooks";

export function CreateQuestionnaireModal({ onClose }) {
	const toast = useToast();
	const navigate = useNavigate();
	const inputRef = useRef();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState();
	const { mutateAsync } = useCreateQuestionnaire();

	async function handleImage(e){
		setLoading(true);
		let { files } = e.target;

		let file = files[0];
		let base64 = await toBase64(file);

		setImage({
			"type":file.type,
			"name":file.name,
			"size":file.size,
			"base64":base64
		})
		setLoading(false);
	}

	async function handleSubmit(){
		setLoading(true);
		try {
			let data = await mutateAsync({
				title,
				description,
				image
			});
			onClose();
			toast({
				status:"success",
				title:"Questionnaire created successfully!",
				position: 'top',
			})
			navigate(`/questionnaires/${data.id}`);
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="20px">Create Questionnaire</Text>
					<Flex direction="column" mb="20px">
						<Flex>
							<Text flex="1" variant="subtitle">Image</Text>
							{ image && <Text onClick={() => setImage()} variant="link" color="error">Remove image</Text> }
						</Flex>
						<Flex onClick={() => inputRef.current.click()} cursor="pointer" direction="column" mt="10px" borderRadius="10px" h="220px" border="2px solid" borderColor="light" align="center" justify="center" overflow="hidden">
							{
								image ? 
								<Image src={image.base64} w="100%" h="100%" objectFit="cover" objectPosition="top" />
								:
								<>
									<Flex w="35px" h="35px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
										<i className="fa-solid fa-cloud-arrow-up"></i>
									</Flex>
									<Text variant="subtitle" fontSize="14px" mt="10px">Upload an image</Text>
								</>
							}
						</Flex>
					</Flex>
					<Flex direction="column" mb="20px">
						<Text variant="subtitle">Title</Text>
						<Input
							type="text"
							placeholder="Set a great title"
							mt="10px"
							fontWeight="500"
							h="50px"
							p="10px"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>
					</Flex>
					<Flex direction="column" mb="30px">
						<Text variant="subtitle">Description</Text>
						<Textarea
							type="text"
							placeholder="Set a compelling description"
							mt="10px"
							fontWeight="500"
							rows={3}
							resize="none"
							p="10px"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>
					</Flex>
					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isDisabled={validate({ title, description })} isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Create Questionnaire</Text>
						</Flex>
					</Flex>
				</Flex>
				<input type="file" style={{ display:"none", opacity: 0 }} accept="image/png, image/jpg, image/jpeg" ref={inputRef} onChange={handleImage} />
			</ModalContent>
		</Modal>
	)
}

function validate({ title, description }) {
	if (isEmpty(title)) {
		return true
	}
	if (isEmpty(description)) {
		return true;
	}

	return false;
}







