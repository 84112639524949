import { useState, useEffect } from "react";
import { Flex, Text, Button, Image, Input, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Parse from "parse";

import LogoImage from "../../assets/logo.png";
import { isEmail, isEmpty } from "../../utils";
import { useLoginUser } from "../../hooks";

export default function Login(){
	const navigate = useNavigate();
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	
	const { mutateAsync } = useLoginUser();

	useEffect(() => {
		if (Parse.User.current()) {
			navigate('/')
		}
	},[])

	async function handleLogin(){
		setLoading(true);
		try {
			let user = await mutateAsync({
				email,
				password
			});
			if (user.get('role') == "editor") {
				navigate(`/questionnaires`)
			} else {
				navigate(`/`)
			}
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Flex w="100%" h={window.innerHeight} align="center" justify="center" direction="column">
			<Flex direction="column" w="500px" border="2px solid" p="35px 25px" align="center" justify="center" borderColor="light" borderRadius="30px">
				<Image 
					src={LogoImage}
					w="40px"
					h="40px"
					borderRadius="35px"
					mb="10px"
				/>

				<Text variant="title" fontSize="24px">Login</Text>
				<Flex direction="column" w="100%" mt="20px">
					<Text fontWeight="500" fontSize="12px" variant="subtitle" mb="10px">Email Address</Text>
					<Input 
						type="email"
						placeholder="Email address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						h="50px"
						fontWeight="500"
						fontSize="15px"
						_focus={{
							borderColor:"primary",
							borderWidth:2
						}}
					/>
				</Flex>
				<Flex direction="column" w="100%" mt="20px">
					<Text fontWeight="500" fontSize="12px" variant="subtitle" mb="10px">Password</Text>
					<Input 
						type="password"
						placeholder="Password"
						fontWeight="500"
						fontSize="15px"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						h="50px"
						_focus={{
							borderColor:"primary",
							borderWidth:2
						}}
					/>
				</Flex>
				<Flex w="100%" mt="40px">
					<Button isDisabled={validate({ email, password })} isLoading={loading} onClick={handleLogin} variant="primary" fontSize="15px" p="15px 0" w="100%">Login</Button>
				</Flex>
			</Flex>
			<Flex w="100%" justify="flex-end" mt="15px" maxW="500px">
				<Text as={Link} to="/forgot-password" variant="link" fontSize="13px" p="0">Forgot password?</Text>
			</Flex>
		</Flex>
	)
} 

function validate({ email, password }) {
	if (isEmpty(email)) {
		return true
	}

	if (isEmail(email) == false) {
		return true
	}

	if (isEmpty(password)) {
		return true;
	}

	return false;
}

