import { useState, useEffect } from "react";


import { Flex, Text, Image, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import LogoImage from "../assets/logo.png";

export default function TikTokVideos() {
	const [seconds, setSeconds] = useState(0)

	useEffect(() => {
		const myInterval = setInterval(() => {
			setSeconds((prevTime) => prevTime == 7 ? 0 : prevTime + 1);
		}, 1100)

		return () => clearInterval(myInterval);
	},[])

	return (
		<Flex w="100%" bg="#141F25"  h={window.innerHeight} direction="column" align="flex-start">
			<Flex flex="1" direction="column" align="center" justify="center" w="100%">
				<CircularProgress isAnimated color="#1F2F37" size="400px" value={seconds} max={7} thickness='10px' trackColor="primary">
					<CircularProgressLabel color="white" fontSize="127px" fontWeight="600">{Math.abs(seconds - 7)}</CircularProgressLabel>
				</CircularProgress>
			</Flex>
		</Flex>
	)
}
