import { useState } from "react";
import { Flex, Text, Button, Image, Input } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import LogoImage from "../../assets/logo.png";

export default function ForgotPassword(){
	const [email, setEmail] = useState("");

	return (
		<Flex w="100%" h={window.innerHeight} align="center" justify="center" direction="column">
			<Flex direction="column" w="500px" border="1px solid" p="40px 25px" align="center" justify="center" borderColor="light" borderRadius="30px">
				<Image 
					src={LogoImage}
					w="40px"
					h="40px"
					borderRadius="5px"
					mb="10px"
				/>
				<Text variant="title" fontSize="24px">Forgot password?</Text>
				<Flex direction="column" w="100%" mt="20px">
					<Text fontWeight="500" fontSize="12px" variant="subtitle" mb="10px">Email Address</Text>
					<Input 
						type="email"
						placeholder="Email address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						h="50px"
						fontWeight="500"
						fontSize="15px"
						_focus={{
							borderColor:"primary",
							borderWidth:2
						}}
					/>
				</Flex>
				<Flex w="100%" mt="30px">
					<Button variant="primary" fontSize="15px" p="15px 0" w="100%">Send reset email</Button>
				</Flex>
			</Flex>
			<Flex w="100%" justify="flex-end" mt="15px" maxW="500px">
				<Text as={Link} to="/login" variant="link" fontSize="13px" p="0">Login</Text>
			</Flex>
		</Flex>
	)
} 



