import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useQuestionnaireQuestions = (lessonID, options) => {
	return useQuery(['questionnaire-lesson-questions', lessonID],
		async () => {
			const query = new Parse.Query("QuestionnaireQuestions");
			query.equalTo("lesson", {
				"__type":"Pointer",
				"objectId":lessonID,
				"className":"QuestionnaireLessons"
			});
			return await query.find();
		},
		{
			...options
		}
	)
}

export const useQuestionnaireAllQuestions = (id) => {
	return useQuery(['questionnaires-all-questions', id],
		async () => {
			const query = new Parse.Query("QuestionnaireQuestions");
			query.equalTo("questionnaire", {
				"__type":"Pointer",
				"objectId":id,
				"className":"Questionnaires"
			});
			query.limit(10000);
			let data = await query.find();
			data.sort((a, b) => a.get('order') - b.get('order'))

			return data;
		},
		{}
	)
}


export const useCreateQuestionnaireQuestions = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { lesson, questions, title, fullTitle } = data;

		if (lesson) {
			lesson.set('title', title);
			lesson.set('fullTitle', fullTitle);
			await lesson.save();
		}
			
		let new_question = await Promise.all(questions.map(async (question, index) => {
			if (question.parseID) {
				// update question
				const query = new Parse.Query("QuestionnaireQuestions");
				let old_question = await query.get(question.parseID);

				old_question.set("title", question.title);
				old_question.set('answer', question.answer);
				old_question.set('options', question.options);
				old_question.set('helpText', question.helpText);

				if (question.type == "video") {
					old_question.set('videos', question.videos);
				} else {
					old_question.set('videos', null);
				}
				
				old_question.set('audio', question.audio);
				old_question.set('source', question.source);
				old_question.set('type', question.type);
				old_question.set('order', index);
				await old_question.save();

			} else {
				// create new question
				const QuestionnaireQuestions = Parse.Object.extend("QuestionnaireQuestions");
				const new_question = new QuestionnaireQuestions();

				new_question.set("title", question.title);
				new_question.set('answer', question.answer);
				new_question.set('lesson', lesson);
				new_question.set('section', lesson.get('section'));
				new_question.set('unit', lesson.get('unit'));
				new_question.set('questionnaire', lesson.get('questionnaire'));
				

				if (question.type == "video") {
					new_question.set('videos', question.videos);
				} else {
					new_question.set('videos', null);
				}

				new_question.set('options', question.options);
				new_question.set('helpText', question.helpText);
				new_question.set('audio', question.audio);
				new_question.set('source', question.source);
				new_question.set('type', question.type);
				new_question.set('order', index);
				
				let new_question_id = await new_question.save();

				question.parseID = new_question_id.id;
			}
			return question;
		}))

		queryClient.refetchQueries(['questionnaire-lessons', lesson.get('unit').id]);


		return new_question;

	}, {
		...options,
	})
}
export const useDeleteQuestion = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (id) => {
		
		const query = new Parse.Query("QuestionnaireQuestions");
		let old_question = await query.get(id);

		if (old_question) {
			await old_question.destroy();
		}

	}, {
		...options,
	})
}

export const useGenerateAudioQuestion = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let result = await Parse.Cloud.run('generate-audio-question', {
			data
		});
		
		queryClient.refetchQueries(['questionnaire-lesson-questions', data.lessonID]);

		return result;	
	}, {
		...options,
	})
}


export const useGenerateAudioLesson = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let result = await Parse.Cloud.run('generate-audio-lesson', {
			data
		});
		queryClient.refetchQueries(['questionnaire-lesson-questions', data.lessonID]);

		return result;	
	}, {
		...options,
	})
}



